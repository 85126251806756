export default `一イー1
丁ディン1
丂カオ3
七チー1
丄シャン4
丅シァ4
丆ハン3
万ワン4
丈ヂャン4
三サン1
上シャン4
下シァ4
丌ジー1
不ブー4
与ユー3
丏ミィェン3
丐ガイ4
丑チョウ3
丒チョウ3
专ヂュァン1
且チィェ3
丕ピー1
世シー4
丗シー4
丘チィゥ1
丙ビン3
业イェ4
丛ツォン2
东ドン1
丝スー1
丞チォン2
丟ディゥ1
丠チィゥ1
両リィァン3
丢ディゥ1
丣ヨウ3
两リィァン3
严イェン2
並ビン4
丧サン4
丨ガン3
丩ジゥ1
个グェ゛ァ4
丫ヤー1
丬チィァン2
中ヂョン1
丮ジー3
丯ジェ4
丰フォン1
丱グァン4
串チュァン4
丳チャン3
临リン2
丵ヂュオ2
丶ヂュ3
丷バー1
丸ワン2
丹ダン1
为ウェイ4
主ヂュ3
丼ジン3
丽リー4
举ジュ3
丿ピェ3
乀フー2
乁イー2
乂イー4
乃ナイ3
乄ウー3
久ジゥ3
乆ジゥ3
乇トゥォ1
么ムェ゛ァ
义イー4
乊イー1
之ヂー1
乌ウー1
乍ヂャ4
乎フー1
乏ファ2
乐ラ4
乑イン2
乒ピン1
乓パン1
乔チィァォ2
乕フー3
乖グゥァイ1
乗チォン2
乘チォン2
乙イー3
乚イン3
乛ヤー
乜ミィェ1
九ジゥ3
乞チー3
也イェ3
习シー2
乡シィァン1
乢ガイ4
乣ジゥ3
乤シァ4
乥フー4
书シュ1
乧ドウ3
乨シー3
乩ジー1
乪ナン2
乫ジャ1
乬ジュ4
乭シー2
乮マオ3
乯フー1
买マイ3
乱ルゥァン4
乲ズー1
乳ルー3
乴シュェ2
乵イェン3
乶フー3
乷シャ1
乸ナー3
乹ガン1
乺スォ3
乻ユー2
乼ツェイ
乽ヂェ゛ァ3
乾チィェン2
乿ヂー4
亀グゥェイ1
亁ガン1
亂ルゥァン4
亃リン3
亄イー4
亅ジュェ2
了ラ
亇マー
予ユー3
争ヂォン1
亊シー4
事シー4
二ェ゛ァー4
亍チュ4
于ユー2
亏クゥェイ1
亐ユー2
云ユン2
互フー4
亓チー2
五ウー3
井ジン3
亖スー4
亗スェイ4
亘ゲン4
亙ゲン4
亚ヤー4
些シェ1
亜ヤー4
亝チー2
亞ヤー4
亟ジー2
亠トウ2
亡ワン2
亢カン4
亣ダー4
交ジャオ1
亥ファン4
亦イー4
产チャン3
亨ホン1
亩ムー3
亪イェ
享シィァン3
京ジン1
亭ティン2
亮リィァン4
亯シィァン3
亰ジン1
亱イェ4
亲チン1
亳ブォ2
亴ヨウ4
亵シェ4
亶ダン3
亷リィェン2
亸ドゥォ3
亹ウェイ3
人レン2
亻レン2
亼ジー2
亽ジー2
亾ワン2
亿イー4
什シェン2
仁レン2
仂ラ4
仃ディン1
仄ゼェ゛ァ4
仅ジン3
仆プー1
仇チョウ2
仈バー1
仉ヂャン3
今ジン1
介ジェ4
仌ビン1
仍ロン2
从ツォン2
仏fo2
仐サン3
仑ルゥン2
仒ビン1
仓ツァン1
仔ザイ3
仕シー4
他ター1
仗ヂャン4
付フー4
仙シィェン1
仚シィェン1
仛トゥォ1
仜ホン2
仝トン2
仞レン4
仟チィェン1
仠ガン3
仡グェ゛ァ1
仢ブォ2
代ダイ4
令リン4
以イー3
仦チャオ4
仧chang2
仨サー1
仩chang2
仪イー2
仫ムー4
们メン
仭レン4
仮ファン3
仯チャオ4
仰ヤン3
仱チィェン2
仲ヂョン4
仳ピー3
仴ウォ4
仵ウー3
件ジィェン4
价ジャ4
仸イャォ3
仹フォン1
仺ツァン1
任レン4
仼ワン2
份フェン4
仾デ1
仿ファン3
伀ヂョン1
企チー3
伂ペイ4
伃ユー2
伄ディァォ4
伅ドゥン4
伆ウー4
伇イー4
伈シン3
伉カン4
伊イー1
伋ジー2
伌ェ゛ァ4
伍ウー3
伎ジー4
伏フー2
伐ファ2
休シゥ1
伒ジン4
伓ピー1
伔ダン3
伕フー1
伖タン3
众ヂョン4
优ヨウ1
伙フォ3
会フゥェイ4
伛ユー3
伜ツェイ4
伝ユン2
伞サン3
伟ウェイ3
传チュァン2
伡チェ゛ァ1
伢ヤー2
伣チィェン4
伤シャン1
伥chang1
伦ルゥン2
伧ツァン1
伨シュン4
伩シン4
伪ウェイ3
伫ヂュ4
伬ゼェ゛ァ
伭シィェン2
伮ヌー3
伯ブォ2
估グー1
伱ニー3
伲ニー4
伳シェ4
伴バン4
伵シュ4
伶リン2
伷ヂョウ4
伸シェン1
伹チュ1
伺ツー4
伻ボン1
似シー4
伽チィェ1
伾ピー1
伿イー4
佀スー4
佁イー3
佂ヂォン1
佃ディェン4
佄ハン1
佅マイ4
但ダン4
佇ヂュ4
佈ブー4
佉チュ1
佊ビー3
佋ヂャオ1
佌ツー3
位ウェイ4
低デ1
住ヂュ4
佐ズゥォ3
佑ヨウ4
佒ヤン3
体ティ3
佔ヂャン4
何フェ゛ァ2
佖ビー4
佗トゥォ2
佘シェ゛ァ2
余ユー2
佚イー4
佛フー2
作ズゥォ4
佝ゴウ1
佞ニン4
佟トン2
你ニー3
佡シィェン1
佢チュ2
佣ヨン1
佤ウァ3
佥チィェン1
佦シー
佧カー3
佨バオ1
佩ペイ4
佪フゥェイ2
佫フェ゛ァ4
佬ラオ3
佭シィァン2
佮グェ゛ァ2
佯ヤン2
佰バイ3
佱ファ3
佲ミン3
佳ジャ1
佴ェ゛ァー4
併ビン4
佶ジー2
佷ヘン3
佸フォ2
佹グゥェイ3
佺チュェン2
佻ティァォ1
佼ジャオ3
佽ツー4
佾イー4
使シー3
侀シン2
侁シェン1
侂トゥォ1
侃カン3
侄ヂー2
侅ガイ1
來ライ2
侇イー2
侈チー3
侉クァ3
侊グゥァン1
例リー4
侌イン1
侍シー4
侎ミィ3
侏ヂュ1
侐シュ4
侑ヨウ4
侒アン1
侓ルー4
侔モウ2
侕ェ゛ァー2
侖ルゥン2
侗ドン4
侘チャ4
侙チー1
侚シュン4
供ゴン1
侜ヂョウ1
依イー1
侞ルー2
侟ツン2
侠シァ2
価スー4
侢ダイ4
侣リュ3
侤ター
侥ジャオ3
侦ヂェン1
侧ツェ゛ァ4
侨チィァォ2
侩クァィ4
侪チャイ2
侫ニン4
侬ノン2
侭ジン3
侮ウー3
侯ホウ2
侰ジィォン3
侱チォン3
侲ヂェン4
侳ズゥォ4
侴チョウ3
侵チン1
侶リュ3
侷ジュ2
侸シュ4
侹ティン3
侺シェン4
侻トゥェイ4
侼ブォ2
侽ナン2
侾シァォ1
便ビィェン4
俀トゥェイ3
俁ユー3
係シー4
促ツゥ4
俄ェ゛ァ2
俅チィゥ2
俆シュ2
俇グゥァン4
俈クー4
俉ウー3
俊ジュン4
俋イー4
俌フー3
俍リィァン2
俎ズー3
俏チィァォ4
俐リー4
俑ヨン3
俒フン4
俓ジン4
俔チィェン4
俕サン4
俖ペイ3
俗スー2
俘フー2
俙シー1
俚リー3
俛フー3
俜ピン1
保バオ3
俞ユー2
俟チー2
俠シァ2
信シン4
俢シゥ1
俣ユー3
俤デ4
俥チェ゛ァ1
俦チョウ2
俧ヂー4
俨イェン3
俩リィァン3
俪リー4
俫ライ2
俬スー1
俭ジィェン3
修シゥ1
俯フー3
俰フォ4
俱ジュ4
俲シァォ4
俳パイ2
俴ジィェン4
俵ビィャォ4
俶チュ4
俷フェイ4
俸フォン4
俹ヤー4
俺アン3
俻ベイ4
俼ユー4
俽シン1
俾ビー3
俿フー3
倀chang1
倁ヂー1
倂ビン4
倃ジゥ4
倄イャォ2
倅ツェイ4
倆リィァン3
倇ワン3
倈ライ2
倉ツァン1
倊ゾン4
個グェ゛ァ4
倌グァン1
倍ベイ4
倎ティェン3
倏シュ1
倐シュ1
們メン
倒ダオ4
倓タン2
倔ジュェ2
倕チュェイ2
倖シン4
倗ポン2
倘タン3
候ホウ4
倚イー3
倛チー1
倜ティ4
倝ガン4
倞ジン4
借ジェ4
倠スェイ1
倡chang4
倢ジェ2
倣ファン3
値ヂー2
倥コン1
倦ジュェン4
倧ゾン1
倨ジュ4
倩チィェン4
倪ニー2
倫ルゥン2
倬ヂュオ1
倭ウォ1
倮ルゥォ3
倯ソン1
倰ラン4
倱フン4
倲ドン1
倳ズー4
倴ベン4
倵ウー3
倶ジュ4
倷ナイ3
倸ツァィ3
倹ジィェン3
债ヂャイ4
倻イェ1
值ヂー2
倽シャ4
倾チン1
倿ニン4
偀イン1
偁チォン1
偂チィェン2
偃イェン3
偄ルァン3
偅ヂョン4
偆チュン3
假ジャ3
偈ジー4
偉ウェイ3
偊ユー3
偋ビン4
偌ルォ4
偍ティ2
偎ウェイ1
偏ピィェン1
偐イェン4
偑フォン1
偒タン3
偓ウォ4
偔ェ゛ァ4
偕シェ2
偖チェ゛ァ3
偗シォン3
偘カン3
偙デ4
做ズゥォ4
偛チャ1
停ティン2
偝ベイ4
偞シェ4
偟フゥァン2
偠イャォ3
偡ヂャン4
偢チョウ3
偣イェン1
偤ヨウ2
健ジィェン4
偦シュ3
偧ヂャ1
偨ツー1
偩フー4
偪ビー1
偫ヂー4
偬ゾン3
偭ミィェン3
偮ジー2
偯イー3
偰シェ4
偱シュン2
偲ツァィ1
偳ドゥァン1
側ツェ゛ァ4
偵ヂェン1
偶オウ3
偷トウ1
偸トウ1
偹ベイ4
偺ザー2
偻ロウ2
偼ジェ2
偽ウェイ3
偾フェン4
偿chang2
傀グゥェイ1
傁ソウ3
傂ヂー4
傃スー4
傄シァ1
傅フー4
傆ユェン4
傇ロン3
傈リー4
傉ヌー4
傊ユン4
傋ジィァン3
傌マー4
傍バン4
傎ディェン1
傏タン2
傐ハオ4
傑ジェ2
傒シー1
傓シャン4
傔チィェン4
傕ジュェ2
傖ツァン1
傗チュ4
傘サン3
備ベイ4
傚シァォ4
傛ヨン3
傜イャォ2
傝タン4
傞スォ1
傟ヤン3
傠ファ2
傡ビン4
傢ジャ1
傣ダイ3
傤ザイ4
傥タン3
傦グー3
傧ビン1
储チュ3
傩ヌオ2
傪ツァン1
傫レイ3
催ツェイ1
傭ヨン1
傮ザオ1
傯ゾン3
傰ボン1
傱ソン3
傲アオ4
傳チュァン2
傴ユー3
債ヂャイ4
傶ズー2
傷シャン1
傸チュゥァン3
傹ジン4
傺チー4
傻シャ3
傼ハン4
傽ヂャン1
傾チン1
傿イェン4
僀デ4
僁シェ4
僂ロウ2
僃ベイ4
僄ピィァオ4
僅ジン3
僆リィェン4
僇ルー4
僈マン2
僉チィェン1
僊シィェン1
僋タン4
僌イン2
働ドン4
僎ヂュァン4
像シィァン4
僐シャン4
僑チィァォ2
僒ジィォン3
僓トゥェイ3
僔ズン3
僕プー2
僖シー1
僗ラオ2
僘chang3
僙グゥァン1
僚リィァォ2
僛チー1
僜チォン1
僝チャン2
僞ウェイ3
僟ジー1
僠ブォ1
僡フゥェイ4
僢チュァン3
僣ティェ3
僤ダン4
僥ジャオ3
僦ジゥ4
僧ソン1
僨フェン4
僩シィェン4
僪ジュ2
僫ェ゛ァ4
僬ジャオ1
僭ジィェン4
僮トン2
僯リン4
僰ブォ2
僱グー4
僲シィェン1
僳スー4
僴シィェン4
僵ジィァン1
僶ミン3
僷イェ4
僸ジン4
價ジャ4
僺チィァォ4
僻ピー4
僼フォン1
僽ヂョウ4
僾ェ゛ァ4
僿サイ4
儀イー2
儁ジュン4
儂ノン2
儃チャン2
億イー4
儅ダン4
儆ジン3
儇シュェン1
儈クァィ4
儉ジィェン3
儊チュ4
儋ダン1
儌ジャオ3
儍シャ3
儎ザイ4
儏ツァン4
儐ビン1
儑アン2
儒ルー2
儓タイ2
儔チョウ2
儕チャイ2
儖ラン2
儗ニー3
儘ジン3
儙チィェン4
儚モン2
儛ウー3
儜ニン2
儝チォン2
儞ニー3
償chang2
儠リィェ4
儡レイ3
儢リュ3
儣クゥァン3
儤バオ4
儥ユー4
儦ビィャォ1
儧ザン3
儨ヂー4
儩スー4
優ヨウ1
儫ハオ2
儬チン4
儭チェン4
儮リー4
儯トン2
儰ウェイ3
儱ロン3
儲チュ3
儳チャン2
儴ラン2
儵シュ1
儶フゥェイ4
儷リー4
儸ルゥォ2
儹ザン3
儺ヌオ2
儻タン3
儼イェン3
儽レイ2
儾ナン4
儿ェ゛ァー2
兀ウー4
允ユン3
兂ザン1
元ユェン2
兄シィォン1
充チョン1
兆ヂャオ4
兇シィォン1
先シィェン1
光グゥァン1
兊ドゥェイ4
克クェ゛ァ4
兌ドゥェイ4
免ミィェン3
兎トゥ4
兏chang2
児ェ゛ァー2
兑ドゥェイ4
兒ェ゛ァー2
兓ジン1
兔トゥ4
兕スー4
兖イェン3
兗イェン3
兘シー3
党ダン3
兛チィェン1
兜ドウ1
兝フェン1
兞マオ2
兟シェン1
兠ドウ1
兢ジン1
兣リー3
兤フゥァン3
入ルー4
兦ワン2
內ネイ4
全チュェン2
兩リィァン3
兪ユー2
八バー1
公ゴン1
六リィゥ4
兮シー1
兯ハン
兰ラン2
共ゴン4
兲ティェン1
关グァン1
兴シン1
兵ビン1
其チー2
具ジュ4
典ディェン3
兹ズー1
兺フェン1
养ヤン3
兼ジィェン1
兽ショウ4
兾ジー4
兿イー4
冀ジー4
冁チャン3
冂ジィォン1
冃マオ4
冄ラン3
内ネイ4
円ユェン2
冇マオ3
冈ガン1
冉ラン3
冊ツェ゛ァ4
冋ジィォン1
册ツェ゛ァ4
再ザイ4
冎グァ3
冏ジィォン3
冐マオ4
冑ヂョウ4
冒マオ4
冓ゴウ4
冔シュ3
冕ミィェン3
冖ミィ4
冗ロン3
冘イン2
写シェ3
冚カン3
军ジュン1
农ノン2
冝イー2
冞ミィ2
冟シー4
冠グァン1
冡モン2
冢ヂョン3
冣ジュ4
冤ユェン1
冥ミン2
冦コウ4
冧リン2
冨フー4
冩シェ3
冪ミィ4
冫ビン1
冬ドン1
冭タイ4
冮ガン1
冯フォン2
冰ビン1
冱フー4
冲チョン1
决ジュェ2
冴フー4
况クゥァン4
冶イェ3
冷ラン3
冸パン4
冹フー2
冺ミン3
冻ドン4
冼シィェン3
冽リィェ4
冾チィァ4
冿ジィェン1
净ジン4
凁ソウ1
凂メイ3
凃トゥ2
凄チー1
凅グー4
准ヂュン3
凇ソン1
凈ジン4
凉リィァン2
凊チン4
凋ディァォ1
凌リン2
凍ドン4
凎ガン4
减ジィェン3
凐イン1
凑コゥ4
凒ェ゛ァ2
凓リー4
凔チュゥァン4
凕ミン3
凖ヂュン3
凗ツェイ1
凘スー1
凙ドゥォ2
凚ジン4
凛リン3
凜リン3
凝ニン2
凞シー1
凟ドゥ2
几ジー3
凡ファン2
凢ファン2
凣ファン2
凤フォン4
凥ジュ1
処チュ3
凧ヂォン1
凨フォン1
凩ムー4
凪ヂー3
凫フー2
凬フォン1
凭ピン2
凮フォン1
凯カイ3
凰フゥァン2
凱カイ3
凲ガン1
凳ドン4
凴ピン2
凵チィェン3
凶シィォン1
凷クァィ4
凸トゥ1
凹アオ1
出チュ1
击ジー1
凼ダン4
函ハン2
凾ハン2
凿ザオ2
刀ダオ1
刁ディァォ1
刂ダオ1
刃レン4
刄レン4
刅チュゥァン1
分フェン1
切チィェ4
刈イー4
刉ジー1
刊カン1
刋チィェン4
刌ツン3
刍チュ2
刎ウェン3
刏ジー1
刐ダン3
刑シン2
划ファ4
刓ワン2
刔ジュェ2
刕リー2
刖ユェ4
列リィェ4
刘リィゥ2
则ゼェ゛ァ2
刚ガン1
创チュゥァン4
刜フー2
初チュ1
刞チュ4
刟ディァォ1
删シャン1
刡ミン3
刢リン2
刣ヂョン1
判パン4
別ビィェ2
刦ジェ2
刧ジェ2
刨パオ2
利リー4
刪シャン1
别ビィェ2
刬チャン3
刭ジン3
刮グァ1
刯ゴン1
到ダオ4
刱チュゥァン4
刲クゥェイ1
刳クー1
刴ドゥォ4
刵ェ゛ァー4
制ヂー4
刷シュァ1
券チュェン4
刹シャ1
刺ツー4
刻クェ゛ァ4
刼ジェ2
刽グゥェイ4
刾ツー4
刿グゥェイ4
剀カイ3
剁ドゥォ4
剂ジー4
剃ティ4
剄ジン3
剅ロウ2
剆ルゥォ3
則ゼェ゛ァ2
剈ユェン1
剉ツゥォ4
削シュェ1
剋クェ゛ァ4
剌ラー2
前チィェン2
剎シャ1
剏チュゥァン4
剐グァ3
剑ジィェン4
剒ツゥォ4
剓リー2
剔ティ1
剕フェイ4
剖ポウ1
剗チャン3
剘チー2
剙チュゥァン4
剚ズー4
剛ガン1
剜ワン1
剝ブォ1
剞ジー1
剟ドゥォ1
剠チン2
剡シャン4
剢ドゥ1
剣ジィェン4
剤ジー4
剥ブォ1
剦イェン1
剧ジュ4
剨フォ1
剩シォン4
剪ジィェン3
剫ドゥォ2
剬ドゥァン1
剭ウー1
剮グァ3
副フー4
剰シォン4
剱ジィェン4
割グェ゛ァ1
剳ダー2
剴カイ3
創チュゥァン4
剶チュァン1
剷チャン3
剸トゥァン2
剹ルー4
剺リー2
剻ポン3
剼シャン1
剽ピィァオ1
剾コウ1
剿ジャオ3
劀グァ1
劁チィァォ1
劂ジュェ2
劃ファ4
劄ヂャ1
劅ヂュオ2
劆リィェン2
劇ジュ4
劈ピー1
劉リィゥ2
劊グゥェイ4
劋ジャオ3
劌グゥェイ4
劍ジィェン4
劎ジィェン4
劏タン1
劐フォ1
劑ジー4
劒ジィェン4
劓イー4
劔ジィェン4
劕ヂー4
劖チャン2
劗ジィェン3
劘ムー2
劙リー2
劚ヂュ2
力リー4
劜ヤー4
劝チュェン4
办バン4
功ゴン1
加ジャ1
务ウー4
劢マイ4
劣リィェ4
劤ジン4
劥コン1
劦シェ2
劧ヂー3
动ドン4
助ヂュ4
努ヌー3
劫ジェ2
劬チュ2
劭シャオ4
劮イー4
劯ヂュ1
劰ムー4
励リー4
劲ジン4
劳ラオ2
労ラオ2
劵ジュェン4
劶コウ3
劷ヤン2
劸ウァ1
効シァォ4
劺モウ2
劻クゥァン1
劼ジェ2
劽リィェ4
劾フェ゛ァ2
势シー4
勀クェ゛ァ4
勁ジン4
勂ガオ4
勃ブォ2
勄ミン3
勅チー4
勆ラン2
勇ヨン3
勈ヨン3
勉ミィェン3
勊クェ゛ァ4
勋シュン1
勌ジュェン4
勍チン2
勎ルー4
勏ブー4
勐モン3
勑チー4
勒レイ1
勓カイ4
勔ミィェン3
動ドン4
勖シュ4
勗シュ4
勘カン1
務ウー4
勚イー4
勛シュン1
勜ウォン3
勝シォン4
勞ラオ2
募ムー4
勠ルー4
勡ピィァオ4
勢シー4
勣ジー1
勤チン2
勥ジィァン4
勦チャオ1
勧チュェン4
勨シィァン4
勩イー4
勪ジュェ2
勫ファン1
勬ジュェン1
勭トン2
勮ジュ4
勯ダン1
勰シェ2
勱マイ4
勲シュン1
勳シュン1
勴リュ4
勵リー4
勶チェ゛ァ4
勷ラン2
勸チュェン4
勹バオ1
勺シャオ2
勻ユン2
勼ジゥ1
勽バオ4
勾ゴウ1
勿ウー4
匀ユン2
匁ウェン2
匂シィォン1
匃ガイ4
匄ガイ4
包バオ1
匆ツォン1
匇イー4
匈シィォン1
匉ポン1
匊ジュ1
匋タオ2
匌グェ゛ァ2
匍プー2
匎ェ゛ァ4
匏パオ2
匐フー2
匑ゴン1
匒ダー2
匓ジゥ4
匔ゴン1
匕ビー3
化ファ4
北ベイ3
匘ナオ3
匙シー
匚ファン1
匛ジゥ4
匜イー2
匝ザー1
匞ジィァン4
匟カン4
匠ジィァン4
匡クゥァン1
匢フー1
匣シァ2
匤チュ1
匥ファン2
匦グゥェイ3
匧チィェ4
匨ザン1
匩クゥァン1
匪フェイ3
匫フー1
匬ユー3
匭グゥェイ3
匮クゥェイ4
匯フゥェイ4
匰ダン1
匱グゥェイ4
匲リィェン2
匳リィェン2
匴スゥァン3
匵ドゥ2
匶ジゥ4
匷ジュェ2
匸シー4
匹ピー3
区チュ1
医イー1
匼クェ゛ァ1
匽イェン3
匾ビィェン3
匿ニー4
區チュ1
十シー2
卂シュン4
千チィェン1
卄ニィェン4
卅サー4
卆ズー2
升シォン1
午ウー3
卉フゥェイ4
半バン4
卋シー4
卌シー4
卍ワン4
华ファ2
协シェ2
卐ワン4
卑ベイ1
卒ズー2
卓ヂュオ2
協シェ2
单ダン1
卖マイ4
南ナン2
単ダン1
卙ジー2
博ブォ2
卛シュァイ4
卜ブォ
卝クゥァン4
卞ビィェン4
卟ブー3
占ヂャン4
卡カー3
卢ルー2
卣ヨウ3
卤ルー3
卥シー1
卦グァ4
卧ウォ4
卨シェ4
卩ジェ2
卪ジェ2
卫ウェイ4
卬アン2
卭チォン2
卮ヂー1
卯マオ3
印イン4
危ウェイ1
卲シャオ4
即ジー2
却チュェ4
卵ルゥァン3
卶チー3
卷ジュェン3
卸シェ4
卹シュ4
卺ジン3
卻チュェ4
卼ウー4
卽ジー2
卾ェ゛ァ4
卿チン1
厀シー1
厁サン1
厂chang3
厃ウェイ3
厄ェ゛ァ4
厅ティン1
历リー4
厇ヂェ゛ァ2
厈ハン3
厉リー4
厊ヤー3
压ヤー1
厌イェン4
厍シェ゛ァ4
厎デ3
厏ヂャ3
厐パン2
厑ヤー2
厒チィェ4
厓ヤー2
厔ヂー4
厕ツェ゛ァ4
厖パン2
厗ティ2
厘リー2
厙シェ゛ァ4
厚ホウ4
厛ティン1
厜ズェイ1
厝ツゥォ4
厞フェイ4
原ユェン2
厠ツェ゛ァ4
厡ユェン2
厢シィァン1
厣イェン3
厤リー4
厥ジュェ2
厦シャ4
厧ディェン1
厨チュ2
厩ジゥ4
厪ジン3
厫アオ2
厬グゥェイ3
厭イェン4
厮スー1
厯リー4
厰chang3
厱ラン2
厲リー4
厳イェン2
厴イェン3
厵ユェン2
厶スー1
厷ゴン1
厸リン2
厹ロウ2
厺チュ4
去チュ4
厼ェ゛ァー3
厽レイ3
厾ドゥ1
县シィェン4
叀ヂュァン1
叁サン1
参ツァン1
參ツァン1
叄ツァン1
叅ツァン1
叆ェ゛ァ4
叇ダイ4
又ヨウ4
叉チャ1
及ジー2
友ヨウ3
双シュゥァン1
反ファン3
収ショウ1
叏グゥァイ4
叐バー2
发ファ1
叒ルォ4
叓シー4
叔シュ1
叕ヂュオ2
取チュ3
受ショウ4
变ビィェン4
叙シュ4
叚ジャ3
叛パン4
叜ソウ3
叝ジー2
叞ウェイ4
叟ソウ3
叠ディェ2
叡ルェイ4
叢ツォン2
口コウ3
古グー3
句ジュ4
另リン4
叧グァ3
叨ダオ1
叩コウ4
只ヂー3
叫ジャオ4
召ヂャオ4
叭バー1
叮ディン1
可クェ゛ァ3
台タイ2
叱チー4
史シー3
右ヨウ4
叴チィゥ2
叵ポォ3
叶イェ4
号ハオ4
司スー1
叹タン4
叺チー3
叻ラ4
叼ディァォ1
叽ジー1
叾リィァォ3
叿ホン1
吀ミィェ1
吁シュ1
吂マン2
吃チー1
各グェ゛ァ4
吅シュェン1
吆イャォ1
吇ズー3
合フェ゛ァ2
吉ジー2
吊ディァォ4
吋ツン4
同トン2
名ミン2
后ホウ4
吏リー4
吐トゥ3
向シィァン4
吒ヂャ1
吓シァ4
吔イェ3
吕リュ3
吖ヤー1
吗マー
吘オウ3
吙フォ1
吚イー1
君ジュン1
吜チョウ3
吝リン4
吞トゥン1
吟イン2
吠フェイ4
吡ビー3
吢チン4
吣チン4
吤ジェ4
吥ブー4
否フォウ3
吧バー
吨ドゥン1
吩フェン1
吪ェ゛ァ2
含ハン2
听ティン1
吭コン1
吮シュン3
启チー3
吰ホン2
吱ヂー1
吲イン3
吳ウー2
吴ウー2
吵チャオ3
吶ナー4
吷シュェ4
吸シー1
吹チュェイ1
吺ドウ1
吻ウェン3
吼ホウ3
吽ホン1
吾ウー2
吿ガオ4
呀ヤー
呁ジュン4
呂リュ3
呃ェ゛ァ4
呄グェ゛ァ2
呅メイ2
呆ダイ1
呇チー3
呈チォン2
呉ウー2
告ガオ4
呋フー1
呌ジャオ4
呍ホン1
呎チー3
呏シォン1
呐ナー4
呑トゥン1
呒ウー3
呓イー4
呔ダイ1
呕オウ3
呖リー4
呗ベイ
员ユェン2
呙グゥォ1
呚ウェン
呛チィァン1
呜ウー1
呝ェ゛ァ4
呞シー1
呟ジュェン3
呠ペン3
呡ウェン3
呢ヌェ゛ァ
呣m2
呤リン4
呥ラン2
呦ヨウ1
呧デ3
周ヂョウ1
呩シー4
呪ヂョウ4
呫ティェ4
呬シー4
呭イー4
呮チー4
呯ピン2
呰ズー3
呱グー1
呲ツー1
味ウェイ4
呴シュ3
呵フェ゛ァ1
呶ナオ2
呷チィェ1
呸ペイ1
呹イー4
呺シァォ1
呻シェン1
呼フー1
命ミン4
呾ダー2
呿チュ4
咀ジュ3
咁ハン2
咂ザー1
咃トゥォ1
咄ドゥォ1
咅ポウ3
咆パオ2
咇ビィェ2
咈フー2
咉ヤン1
咊フェ゛ァ2
咋ザー3
和フェ゛ァ2
咍ファン1
咎ジゥ4
咏ヨン3
咐フー4
咑ダー1
咒ヂョウ4
咓ウァ3
咔カー1
咕グー1
咖カー1
咗ズゥォ
咘ブー4
咙ロン2
咚ドン1
咛ニン2
咜ター
咝スー1
咞シィェン4
咟フォ4
咠チー4
咡ェ゛ァー4
咢ェ゛ァ4
咣グゥァン1
咤ヂャ4
咥シー4
咦イー2
咧リィェ3
咨ズー1
咩ミィェ1
咪ミィ1
咫ヂー3
咬イャォ3
咭ジー1
咮ヂョウ4
咯グェ゛ァ1
咰シュ4
咱ザン2
咲シァォ4
咳クェ゛ァ2
咴フゥェイ1
咵クァ3
咶フゥァイ4
咷タオ2
咸シィェン2
咹ェ゛ァ4
咺シュェン3
咻シゥ1
咼グゥォ1
咽イェン4
咾ラオ3
咿イー1
哀ェ゛ァ1
品ピン3
哂シェン3
哃トン2
哄ホン3
哅シィォン1
哆ドゥォ1
哇ウァ
哈ハー1
哉ザイ1
哊ヨウ4
哋ディェ4
哌パイ4
响シィァン3
哎ェ゛ァ1
哏ゲン2
哐クゥァン1
哑ヤー3
哒ダー2
哓シァォ1
哔ビー4
哕フゥェイ4
哖ニィェン2
哗ファ1
哘シン
哙クァィ4
哚ドゥォ3
哛フェン1
哜ジー4
哝ノン2
哞モウ1
哟yo1
哠ハオ4
員ユェン2
哢ロン4
哣ポウ3
哤マン2
哥グェ゛ァ1
哦ゥオ2
哧チー1
哨シャオ4
哩リー1
哪ナー3
哫ズー2
哬フェ゛ァ2
哭クー1
哮シァォ1
哯シィェン4
哰ラオ2
哱ブォ1
哲ヂェ゛ァ2
哳ヂャ1
哴リィァン4
哵バー1
哶ミィェ1
哷リィェ4
哸スェイ1
哹フー2
哺ブー3
哻ハン1
哼ホン1
哽ゴン3
哾シュォ1
哿グェ゛ァ3
唀ヨウ4
唁イェン4
唂グー1
唃グー3
唄ベイ
唅ハン2
唆スォ1
唇チュン2
唈イー4
唉ェ゛ァ1
唊ジャ2
唋トゥ1
唌シィェン2
唍ワン3
唎リー4
唏シー1
唐タン2
唑ズゥォ4
唒チィゥ2
唓チェ゛ァ1
唔ウー2
唕ザオ4
唖ヤー3
唗ドウ1
唘チー3
唙デ2
唚チン4
唛マー4
唜ムー4
唝ゴン4
唞ドウ3
唟チュ4
唠ラオ2
唡リィァン3
唢スォ3
唣ザオ4
唤ファン4
唥ラン
唦シャ1
唧ジー1
唨ズー3
唩ウォ1
唪フォン3
唫ジン4
唬フー3
唭チー4
售ショウ4
唯ウェイ2
唰シュァ1
唱chang4
唲ェ゛ァー2
唳リー4
唴チィァン4
唵アン3
唶ゼェ゛ァ2
唷yo1
唸ニィェン4
唹ユー1
唺ティェン3
唻ライ4
唼シャ4
唽シー1
唾トゥォ4
唿フー1
啀ェ゛ァ2
啁ヂャオ1
啂ノウ3
啃ケン3
啄ヂュオ2
啅ヂュオ2
商シャン1
啇デ4
啈ホン1
啉リン2
啊アー
啋ツァィ3
啌シィァン1
啍トゥン1
啎ウー3
問ウェン4
啐ツェイ4
啑シャ4
啒グー3
啓チー3
啔チー3
啕タオ2
啖ダン4
啗ダン4
啘イェ4
啙ズー3
啚ビー3
啛ツェイ4
啜チュァイ4
啝フェ゛ァ2
啞ヤー3
啟チー3
啠ヂェ゛ァ2
啡フェイ1
啢リィァン3
啣シィェン2
啤ピー2
啥シャ2
啦ラー
啧ゼェ゛ァ2
啨イン1
啩グァ4
啪パー1
啫ヂェ゛ァ3
啬スェ゛ァ4
啭ヂュァン4
啮ニィェ4
啯グゥォ1
啰ルゥォ1
啱イェン2
啲デ1
啳チュェン2
啴チャン3
啵ブォ1
啶ディン4
啷ラン1
啸シァォ4
啹ジュ2
啺タン2
啻チー4
啼ティ2
啽アン2
啾ジゥ1
啿ダン4
喀カー1
喁ヨン2
喂ウェイ4
喃ナン2
善シャン4
喅ユー4
喆ヂェ゛ァ2
喇ラー3
喈ジェ1
喉ホウ2
喊ハン3
喋ディェ2
喌ヂョウ1
喍チャイ2
喎ワィ1
喏ヌオ4
喐ユー4
喑イン1
喒ザー2
喓イャォ1
喔ゥオ1
喕ミィェン3
喖フー2
喗ユン3
喘チュァン3
喙フゥェイ4
喚ファン4
喛ファン4
喜シー3
喝フェ゛ァ1
喞ジー1
喟クゥェイ4
喠ヂョン3
喡ウェイ2
喢シャ4
喣シュ3
喤フゥァン2
喥ドゥォ2
喦ニィェ4
喧シュェン1
喨リィァン4
喩ユー4
喪サン4
喫チー1
喬チィァォ2
喭イェン4
單ダン1
喯ペン4
喰ツァン1
喱リー2
喲yo1
喳ヂャ1
喴ウェイ1
喵ミィァォ1
営イン2
喷ペン1
喸ブー3
喹クゥェイ2
喺シー2
喻ユー4
喼ジェ1
喽ロウ2
喾クー4
喿ザオ4
嗀フー4
嗁ティ2
嗂イャォ2
嗃フェ゛ァ4
嗄アー2
嗅シゥ4
嗆チィァン1
嗇スェ゛ァ4
嗈ヨン1
嗉スー4
嗊ホン3
嗋シェ2
嗌ェ゛ァ4
嗍スォ1
嗎マー
嗏チャ1
嗐ファン4
嗑クェ゛ァ1
嗒ダー1
嗓サン3
嗔チェン1
嗕ルー4
嗖ソウ1
嗗ウァ1
嗘ジー1
嗙パン3
嗚ウー1
嗛チィェン3
嗜シー4
嗝グェ゛ァ2
嗞ズー1
嗟ジェ1
嗠ラオ4
嗡ウォン1
嗢ウァ4
嗣スー4
嗤チー1
嗥ハオ2
嗦スォ
嗨ファン1
嗩スォ3
嗪チン2
嗫ニィェ4
嗬フェ゛ァ1
嗭ヂー2
嗮サイ4
嗯n2
嗰グェ゛ァ3
嗱ナー2
嗲ディェ1
嗳ェ゛ァ1
嗴チィァン1
嗵トン1
嗶ビー4
嗷アオ2
嗸アオ2
嗹リィェン2
嗺ズェイ1
嗻ヂェ゛ァ1
嗼ムー4
嗽ソウ4
嗾ソウ3
嗿タン3
嘀デ2
嘁チー1
嘂ジャオ4
嘃チョン1
嘄ジャオ1
嘅カイ3
嘆タン4
嘇シャン1
嘈ツァォ2
嘉ジャ1
嘊ェ゛ァ2
嘋シァォ4
嘌ピィァオ4
嘍ロウ2
嘎チィェ1
嘏グー3
嘐シァォ1
嘑フー1
嘒フゥェイ4
嘓グゥォ1
嘔オウ3
嘕シィェン1
嘖ゼェ゛ァ2
嘗chang2
嘘シュ1
嘙ポォ2
嘚ディ1
嘛マー
嘜マー4
嘝フー2
嘞レイ
嘟ドゥ1
嘠チィェ1
嘡タン1
嘢イェ3
嘣ボン1
嘤イン1
嘥サイ1
嘦ジャオ4
嘧ミィ4
嘨シァォ4
嘩ファ1
嘪マイ3
嘫ラン2
嘬チュァイ4
嘭ポン1
嘮ラオ2
嘯シァォ4
嘰ジー1
嘱ヂュ3
嘲チャオ2
嘳クゥェイ4
嘴ズェイ3
嘵シァォ1
嘶スー1
嘷ハオ2
嘸フー3
嘹リィァォ2
嘺チィァォ2
嘻シー1
嘼チュ4
嘽チャン3
嘾ダン4
嘿ヘイ1
噀シュン4
噁ェ゛ァ3
噂ズン3
噃ファン1
噄チー1
噅フゥェイ1
噆ザン3
噇チュゥァン2
噈ツゥ4
噉ダン4
噊ユー4
噋トゥン1
噌ツォン1
噍ジャオ4
噎イェ1
噏シー1
噐チー4
噑ハオ2
噒リィェン2
噓シュ1
噔ドン1
噕フゥェイ1
噖イン2
噗プー1
噘ジュェ1
噙チン2
噚シュン2
噛ニィェ4
噜ルー1
噝スー1
噞イェン3
噟イン4
噠ダー1
噡ヂャン1
噢ゥオ1
噣ヂョウ4
噤ジン4
噥ノン2
噦ユェ3
噧シェ4
器チー4
噩ェ゛ァ4
噪ザオ4
噫イー1
噬シー4
噭ジャオ4
噮ユェン4
噯ェ゛ァ1
噰ヨン1
噱ジュェ2
噲クァィ4
噳ユー3
噴ペン1
噵ダオ4
噶チィェ2
噷hm
噸ドゥン1
噹ダン1
噺シン1
噻サイ1
噼ピー1
噽ピー3
噾イン1
噿ズェイ3
嚀ニン2
嚁デ2
嚂ラン4
嚃ター1
嚄フォ1
嚅ルー2
嚆ハオ1
嚇シァ4
嚈イェ4
嚉ドゥォ1
嚊ピー4
嚋チョウ2
嚌ジー4
嚍ジン4
嚎ハオ2
嚏ティ4
嚐chang2
嚑シュン1
嚒ムェ゛ァ1
嚓ツァ1
嚔ティ4
嚕ルー3
嚖フゥェイ4
嚗ブォ2
嚘ヨウ1
嚙ニィェ4
嚚イン2
嚛フー4
嚜ムェ゛ァ
嚝ホン1
嚞ヂェ゛ァ2
嚟リー2
嚠リィゥ2
嚡ファン
嚢ナン2
嚣シァォ1
嚤ムー2
嚥イェン4
嚦リー4
嚧ルー2
嚨ロン2
嚩ムー2
嚪ダン4
嚫チェン4
嚬ピン2
嚭ピー3
嚮シィァン4
嚯フォ4
嚰ムー2
嚱シー4
嚲ドゥォ3
嚳クー4
嚴イェン2
嚵チャン2
嚶イン1
嚷ラン3
嚸ディェン3
嚹ラー2
嚺ター4
嚻シァォ1
嚼ジュェ2
嚽チュォ4
嚾ファン1
嚿フォ4
囀ヂュァン4
囁ニィェ4
囂シァォ1
囃ツァ4
囄リー2
囅チャン3
囆チャイ4
囇リー4
囈イー4
囉ルゥォ1
囊ナン2
囋ザー2
囌スー1
囍シー3
囎ゼン
囏ジィェン1
囐ザー2
囑ヂュ3
囒ラン2
囓ニィェ4
囔ナン1
囕ラン3
囖lo
囗ウェイ2
囘フゥェイ2
囙イン1
囚チィゥ2
四スー4
囜ニン2
囝ジィェン3
回フゥェイ2
囟シン4
因イン1
囡ナン1
团トゥァン2
団トゥァン2
囤ドゥン4
囥カン4
囦ユェン1
囧ジィォン3
囨ピィェン1
囩ユン2
囪ツォン1
囫フー2
囬フゥェイ2
园ユェン2
囮ェ゛ァ2
囯グゥォ2
困クン4
囱ツォン1
囲トン1
図トゥ2
围ウェイ2
囵ルゥン2
囶グゥォ2
囷チュン1
囸リ゛ー4
囹リン2
固グー4
囻グゥォ2
囼タイ1
国グゥォ2
图トゥ2
囿ヨウ4
圀グゥォ2
圁イン2
圂フン4
圃プー3
圄ユー3
圅ハン2
圆ユェン2
圇ルゥン2
圈チュェン1
圉ユー3
圊チン1
國グゥォ2
圌チュァン2
圍ウェイ2
圎ユェン2
圏チュェン1
圐クー1
圑プー3
園ユェン2
圓ユェン2
圔ヤー4
圕トゥ2
圖トゥ2
圗トゥ2
團トゥァン2
圙リュェ4
圚フゥェイ4
圛イー4
圜ファン2
圝ルゥァン2
圞ルゥァン2
土トゥ3
圠ヤー4
圡トゥ3
圢ティン3
圣シォン4
圤プー2
圥ルー4
圦クァィ4
圧ヤー1
在ザイ4
圩ウェイ2
圪グェ゛ァ1
圫ユー4
圬ウー1
圭グゥェイ1
圮ピー3
圯イー2
地デ4
圱チィェン1
圲チィェン1
圳ヂェン4
圴ヂュオ2
圵ダン4
圶チィァ4
圷シァ4
圸シャン1
圹クゥァン4
场chang3
圻チー2
圼ニィェ4
圽ムー4
圾ジー1
圿ジャ2
址ヂー3
坁ヂー3
坂バン3
坃シュン1
坄イー4
坅チン3
坆メイ2
均ジュン1
坈ロン3
坉トゥン2
坊ファン1
坋ベン4
坌ベン4
坍タン1
坎カン3
坏フゥァイ4
坐ズゥォ4
坑コン1
坒ビー4
坓ジン3
坔デ4
坕ジン1
坖ジー4
块クァィ4
坘デ3
坙ジン1
坚ジィェン1
坛タン2
坜リー4
坝バー4
坞ウー4
坟フェン2
坠ヂュェイ4
坡ポォ1
坢バン4
坣タン2
坤クン1
坥チュ1
坦タン3
坧ヂー1
坨トゥォ2
坩ガン1
坪ピン2
坫ディェン4
坬グァ4
坭ニー2
坮タイ2
坯ピー1
坰ジィォン1
坱ヤン3
坲fo2
坳アオ4
坴ルー4
坵チィゥ1
坶ムー3
坷クェ゛ァ3
坸ゴウ4
坹シュェ4
坺バー2
坻チー2
坼チェ゛ァ4
坽リン2
坾ヂュ4
坿フー4
垀フー1
垁ヂー4
垂チュェイ2
垃ラー1
垄ロン3
垅ロン3
垆ルー2
垇アオ4
垈ダイ4
垉パオ2
垊ミン
型シン2
垌ドン4
垍ジー4
垎フェ゛ァ4
垏リュ4
垐ツー2
垑チー3
垒レイ3
垓ガイ1
垔イン1
垕ホウ4
垖ドゥェイ1
垗ヂャオ4
垘フー2
垙グゥァン1
垚イャォ2
垛ドゥォ3
垜ドゥォ3
垝グゥェイ3
垞チャ2
垟ヤン2
垠イン2
垡ファ2
垢ゴウ4
垣ユェン2
垤ディェ2
垥シェ2
垦ケン3
垧シャン3
垨ショウ3
垩ェ゛ァ4
垪ビン4
垫ディェン4
垬ホン2
垭ヤー1
垮クァ3
垯ダー
垰カー3
垱ダン4
垲カイ3
垳ハン2
垴ナオ3
垵アン3
垶シン1
垷シィェン4
垸ユェン4
垹バン1
垺フー1
垻バー4
垼イー4
垽イン4
垾ハン4
垿シュ4
埀チュェイ2
埁チン2
埂ゴン3
埃ェ゛ァ1
埄ボン3
埅ファン2
埆チュェ4
埇ヨン3
埈ジュン4
埉ジャ1
埊デ4
埋マイ2
埌ラン4
埍ジュェン3
城チォン2
埏シャン1
埐ジン1
埑ヂェ゛ァ2
埒リィェ4
埓リィェ4
埔プー3
埕チォン2
埖ファ1
埗ブー4
埘シー2
埙シュン1
埚グゥォ1
埛ジィォン1
埜イェ3
埝ニィェン4
埞デ1
域ユー4
埠ブー4
埡ヤー1
埢チュェン2
埣スェイ4
埤ピー2
埥チン1
埦ワン3
埧ジュ4
埨ルゥン3
埩ヂォン1
埪コン1
埫チョン3
埬ドン1
埭ダイ4
埮タン4
埯アン3
埰ツァィ4
埱チュ4
埲ボン3
埳カン3
埴ヂー2
埵ドゥォ3
埶イー4
執ヂー2
埸イー4
培ペイ2
基ジー1
埻ヂュン3
埼チー2
埽サオ4
埾ジュ4
埿ニー2
堀クー1
堁クェ゛ァ4
堂タン2
堃クン1
堄ニー4
堅ジィェン1
堆ドゥェイ1
堇ジン3
堈ガン1
堉ユー4
堊ェ゛ァ4
堋ポン2
堌グー4
堍トゥ4
堎ラン4
堏ファン
堐ヤー2
堑チィェン4
堒クン1
堓アン4
堔シェン1
堕ドゥォ4
堖ナオ3
堗トゥ1
堘チォン2
堙イン1
堚フン2
堛ビー4
堜リィェン4
堝グゥォ1
堞ディェ2
堟ヂュァン4
堠ホウ4
堡バオ3
堢バオ3
堣ユー2
堤デ1
堥マオ2
堦ジェ1
堧ルァン2
堨イェ4
堩ゴン4
堪カン1
堫ゾン1
堬ユー2
堭フゥァン2
堮ェ゛ァ4
堯イャォ2
堰イェン4
報バオ4
堲ツー2
堳メイ2
場chang3
堵ドゥ3
堶トゥォ2
堷イン4
堸フォン2
堹ヂョン4
堺ジェ4
堻ジン1
堼ホン4
堽ガン1
堾チュン1
堿ジィェン3
塀ピン2
塁レイ3
塂シィァン4
塃フゥァン1
塄ラン2
塅ドゥァン4
塆ワン1
塇シュェン1
塈ジー4
塉ジー2
塊クァィ4
塋イン2
塌ター1
塍チォン2
塎ヨン3
塏カイ3
塐スー4
塑スー4
塒シー2
塓ミィ4
塔ター3
塕ウォン3
塖チォン2
塗トゥ2
塘タン2
塙チュェ4
塚ヂョン3
塛リー4
塜ヂョン3
塝バン4
塞サイ1
塟ザン4
塠ドゥェイ1
塡ティェン2
塢ウー4
塣ヂォン4
塤シュン1
塥グェ゛ァ2
塦ヂェン4
塧ェ゛ァ4
塨ゴン1
塩イェン2
塪カン3
填ティェン2
塬ユェン2
塭ウェン1
塮シェ4
塯リィゥ4
塰ファン3
塱ラン3
塲chang2
塳ポン2
塴ボン4
塵チェン2
塶ルー4
塷ルー3
塸オウ1
塹チィェン4
塺メイ2
塻ムー4
塼ヂュァン1
塽シュゥァン3
塾シュ2
塿ロウ3
墀チー2
墁マン4
墂ビィャォ1
境ジン4
墄ツェ゛ァ4
墅シュ4
墆ヂー4
墇ヂャン4
墈カン4
墉ヨン1
墊ディェン4
墋チェン3
墌ヂー2
墍シー4
墎グゥォ1
墏チィァン3
墐ジン4
墑デ4
墒シャン1
墓ムー4
墔ツェイ1
墕イェン4
墖ター3
増ゾン1
墘チィェン2
墙チィァン2
墚リィァン2
墛ウェイ4
墜ヂュェイ4
墝チィァォ1
增ゾン1
墟シュ1
墠シャン4
墡シャン4
墢バー2
墣プー2
墤クァィ4
墥ドン3
墦ファン2
墧チュェ4
墨ムー4
墩ドゥン1
墪ドゥン1
墫ズン1
墬デ4
墭シォン4
墮ドゥォ4
墯ドゥォ4
墰タン2
墱ドン4
墲ムー2
墳フェン2
墴フゥァン2
墵タン2
墶ダー
墷イェ4
墸ヂュ4
墹ジィェン4
墺アオ4
墻チィァン2
墼ジー1
墽チィァォ1
墾ケン3
墿イー4
壀ピー2
壁ビー4
壂ディェン4
壃ジィァン1
壄イェ3
壅ヨン1
壆シュェ2
壇タン2
壈ラン3
壉ジュ4
壊フゥァイ4
壋ダン4
壌ラン3
壍チィェン4
壎シュン1
壏シィェン4
壐シー3
壑フェ゛ァ4
壒ェ゛ァ4
壓ヤー1
壔ダオ3
壕ハオ2
壖ルァン2
壗ジン4
壘レイ3
壙クゥァン4
壚ルー2
壛イェン2
壜タン2
壝ウェイ3
壞フゥァイ4
壟ロン3
壠ロン3
壡ルェイ4
壢リー4
壣リン2
壤ラン3
壥チャン2
壦シュン1
壧イェン2
壨レイ2
壩バー4
壪ワン1
士シー4
壬レン2
壭サン
壮ヂュゥァン4
壯ヂュゥァン4
声シォン1
壱イー1
売マイ4
壳クェ゛ァ2
壴ヂュ4
壵ヂュゥァン4
壶フー2
壷フー2
壸クン3
壹イー1
壺フー2
壻シュ4
壼クン3
壽ショウ4
壾マン3
壿ズン1
夀ショウ4
夁イー1
夂ヂー3
夃グー3
处チュ4
夅ジィァン4
夆フォン2
备ベイ4
夈ヂャイ1
変ビィェン4
夊スェイ1
夋チュン1
夌リン2
复フー4
夎ツゥォ4
夏シァ4
夐シィォン4
夑シェ4
夒ナオ2
夓シァ4
夔クゥェイ2
夕シー1
外ワィ4
夗ユェン4
夘マオ3
夙スー4
多ドゥォ1
夛ドゥォ1
夜イェ4
夝チン2
夞ワィ4
够ゴウ4
夠ゴウ4
夡チー4
夢モン4
夣モン4
夤イン2
夥フォ3
夦チェン3
大ダー4
夨ゼェ゛ァ4
天ティェン1
太タイ4
夫フー1
夬グゥァイ4
夭イャォ1
央ヤン1
夯ハン1
夰ガオ3
失シー1
夲タオ1
夳タイ4
头トウ2
夵イェン3
夶ビー3
夷イー2
夸クァ1
夹ジャ1
夺ドゥォ2
夻ファ4
夼クゥァン3
夽ユン3
夾ジャ1
夿バー1
奀エン1
奁リィェン2
奂ファン4
奃デ1
奄イェン3
奅パオ4
奆ジュェン4
奇チー2
奈ナイ4
奉フォン4
奊シェ2
奋フェン4
奌ディェン3
奍チュェン1
奎クゥェイ2
奏ゾウ4
奐ファン4
契チー4
奒カイ1
奓ヂャ1
奔ベン1
奕イー4
奖ジィァン3
套タオ4
奘ザン4
奙ベン3
奚シー1
奛フゥァン3
奜フェイ3
奝ディァォ1
奞シュン4
奟ボン1
奠ディェン4
奡アオ4
奢シェ゛ァ1
奣ウォン3
奤ハー3
奥アオ4
奦ウー4
奧アオ4
奨ジィァン3
奩リィェン2
奪ドゥォ2
奫ユン1
奬ジィァン3
奭シー4
奮フェン4
奯フォ4
奰ビー4
奱ルゥァン2
奲ドゥォ3
女ニュ3
奴ヌー2
奵ディン3
奶ナイ3
奷チィェン1
奸ジィェン1
她ター1
奺ジゥ3
奻ヌァン2
奼チャ4
好ハオ3
奾シィェン1
奿ファン4
妀ジー3
妁シュォ4
如ルー2
妃フェイ1
妄ワン4
妅ホン2
妆ヂュゥァン1
妇フー4
妈マー1
妉ダン1
妊レン4
妋フー1
妌ジン4
妍イェン2
妎ファン4
妏ウェン4
妐ヂョン1
妑パー1
妒ドゥ4
妓ジー4
妔コン1
妕ヂョン4
妖イャォ1
妗ジン4
妘ユン2
妙ミィァォ4
妚フォウ3
妛チー1
妜ユェ4
妝ヂュゥァン1
妞ニィゥ1
妟イェン4
妠ナー4
妡シン1
妢フェン2
妣ビー3
妤ユー2
妥トゥォ3
妦フォン1
妧ワン4
妨ファン2
妩ウー3
妪ユー4
妫グゥェイ1
妬ドゥ4
妭バー2
妮ニー1
妯ヂョウ2
妰ヂュオ2
妱ヂャオ1
妲ダー2
妳ニー3
妴ユェン4
妵トウ3
妶シィェン2
妷ヂー2
妸ェ゛ァ1
妹メイ4
妺ムー4
妻チー1
妼ビー4
妽シェン1
妾チィェ4
妿ェ゛ァ1
姀フェ゛ァ2
姁シュ3
姂ファ2
姃ヂォン1
姄ミン2
姅バン4
姆ムー3
姇フー1
姈リン2
姉ズー3
姊ズー3
始シー3
姌ラン3
姍シャン1
姎ヤン1
姏マン2
姐ジェ3
姑グー1
姒スー4
姓シン4
委ウェイ3
姕ズー1
姖ジュ4
姗シャン1
姘ピン1
姙レン4
姚イャォ2
姛ドン4
姜ジィァン1
姝シュ1
姞ジー2
姟ガイ1
姠シィァン4
姡ファ2
姢ジュェン1
姣ジャオ1
姤ゴウ4
姥ラオ3
姦ジィェン1
姧ジィェン1
姨イー2
姩ニィェン4
姪ヂー2
姫ジー1
姬ジー1
姭シィェン4
姮ホン2
姯グゥァン1
姰ジュン1
姱クァ1
姲イェン4
姳ミン3
姴リィェ4
姵ペイ4
姶ェ゛ァ4
姷ヨウ4
姸イェン2
姹チャ4
姺シェン1
姻イン1
姼シー2
姽グゥェイ3
姾チュェン2
姿ズー1
娀ソン1
威ウェイ1
娂ホン2
娃ウァ2
娄ロウ2
娅ヤー4
娆ラオ2
娇ジャオ1
娈ルゥァン2
娉ピン1
娊シィェン4
娋シャオ4
娌リー3
娍チォン2
娎シェ4
娏マン2
娐フー1
娑スォ1
娒メイ2
娓ウェイ3
娔クェ゛ァ4
娕チュォ4
娖チュォ4
娗ティン3
娘ニィァン2
娙シン2
娚ナン2
娛ユー2
娜ナー4
娝ポウ1
娞ネイ3
娟ジュェン1
娠シェン1
娡ヂー4
娢ハン2
娣デ4
娤ヂュゥァン1
娥ェ゛ァ2
娦ピン2
娧トゥェイ4
娨シィェン4
娩ミィェン3
娪ウー2
娫イェン2
娬ウー3
娭ェ゛ァ1
娮イェン2
娯ユー2
娰スー4
娱ユー2
娲ウァ1
娳リー4
娴シィェン2
娵ジュ1
娶チュ3
娷ヂュェイ4
娸チー1
娹シィェン2
娺ヂュオ2
娻ドン1
娼chang1
娽ルー4
娾ェ゛ァ3
娿ェ゛ァ1
婀ェ゛ァ1
婁ロウ2
婂ミィェン2
婃ツォン2
婄ポウ3
婅ジュ2
婆ポォ2
婇ツァィ3
婈リン2
婉ワン3
婊ビィャォ3
婋シァォ1
婌シュ2
婍チー3
婎フゥェイ1
婏ファン4
婐ウォ3
婑ルェイ2
婒タン2
婓フェイ1
婔フェイ1
婕ジェ2
婖ティェン1
婗ニー2
婘チュェン2
婙ジン4
婚フン1
婛ジン1
婜チィェン1
婝ディェン4
婞シン4
婟フー4
婠ワン1
婡ライ2
婢ビー4
婣イン1
婤チョウ1
婥ナオ4
婦フー4
婧ジン4
婨ルゥン2
婩アン4
婪ラン2
婫クン1
婬イン2
婭ヤー4
婮ジュ1
婯リー4
婰ディェン3
婱シィェン2
婲ファ1
婳ファ4
婴イン1
婵チャン2
婶シェン3
婷ティン2
婸ダン4
婹イャォ3
婺ウー4
婻ナン4
婼チュォ4
婽ジャ3
婾トウ1
婿シュ4
媀ユー4
媁ウェイ2
媂デ4
媃ロウ2
媄メイ3
媅ダン1
媆ルァン3
媇チン1
媈フゥェイ1
媉ウォ4
媊チィェン2
媋チュン1
媌ミィァォ2
媍フー4
媎ジェ3
媏ドゥァン1
媐イー2
媑ヂョン4
媒メイ2
媓フゥァン2
媔ミィェン2
媕アン1
媖イン1
媗シュェン1
媘ジェ1
媙ウェイ1
媚メイ4
媛ユェン4
媜ヂォン1
媝チィゥ1
媞シー4
媟シェ4
媠トゥォ3
媡リィェン4
媢マオ4
媣ラン3
媤スー1
媥ピィェン1
媦ウェイ4
媧ウァ1
媨ツゥ4
媩フー2
媪アオ3
媫ジェ2
媬バオ3
媭シュ1
媮トウ1
媯グゥェイ1
媰チュ2
媱イャォ2
媲ピー4
媳シー2
媴ユェン2
媵イン4
媶ロン2
媷ルー4
媸チー1
媹リィゥ2
媺メイ3
媻パン2
媼アオ3
媽マー1
媾ゴウ4
媿クゥェイ4
嫀チン2
嫁ジャ4
嫂サオ3
嫃ヂェン1
嫄ユェン2
嫅ジェ1
嫆ロン2
嫇ミン2
嫈イン1
嫉ジー2
嫊スー4
嫋ニィァォ3
嫌シィェン2
嫍タオ1
嫎パン2
嫏ラン2
嫐ナオ3
嫑バオ2
嫒ェ゛ァ4
嫓ピー4
嫔ピン2
嫕イー4
嫖ピィァオ2
嫗ユー4
嫘レイ2
嫙シュェン2
嫚マン1
嫛イー1
嫜ヂャン1
嫝カン1
嫞ヨン1
嫟ニー4
嫠リー2
嫡デ2
嫢グゥェイ1
嫣イェン1
嫤ジン3
嫥ヂュァン1
嫦chang2
嫧ゼェ゛ァ2
嫨ハン1
嫩ネン4
嫪ラオ4
嫫ムー2
嫬ヂェ゛ァ1
嫭フー4
嫮フー4
嫯アオ4
嫰ネン4
嫱チィァン2
嫲マー
嫳ピェ4
嫴グー1
嫵ウー3
嫶チィァォ2
嫷トゥォ3
嫸ヂャン3
嫹ミィァォ2
嫺シィェン2
嫻シィェン2
嫼ムー4
嫽リィァォ2
嫾リィェン2
嫿ファ4
嬀グゥェイ1
嬁ドン1
嬂ヂー2
嬃シュ1
嬄イー1
嬅ファ4
嬆シー1
嬇クゥェイ4
嬈ラオ2
嬉シー1
嬊イェン4
嬋チャン2
嬌ジャオ1
嬍メイ3
嬎ファン4
嬏ファン1
嬐シィェン1
嬑イー4
嬒フゥェイ4
嬓ジャオ4
嬔フー4
嬕シー4
嬖ビー4
嬗シャン4
嬘スェイ4
嬙チィァン2
嬚リィェン3
嬛ファン2
嬜シン1
嬝ニィァォ3
嬞ドン3
嬟イー4
嬠ツァン1
嬡ェ゛ァ4
嬢ニィァン2
嬣ニン2
嬤マー1
嬥ティァォ3
嬦チョウ2
嬧ジン4
嬨ツー2
嬩ユー2
嬪ピン2
嬫ロン2
嬬ルー2
嬭ナイ3
嬮イェン1
嬯タイ2
嬰イン1
嬱チィェン4
嬲ニィァォ3
嬳ユェ4
嬴イン2
嬵ミィェン2
嬶ビー2
嬷マー1
嬸シェン3
嬹シン4
嬺ニー4
嬻ドゥ2
嬼リィゥ3
嬽ユェン1
嬾ラン3
嬿イェン4
孀シュゥァン1
孁リン2
孂ジャオ3
孃ニィァン2
孄ラン3
孅チィェン1
孆イン1
孇シュゥァン1
孈フゥェイ4
孉チュェン2
孊ミィ3
孋リー2
孌ルゥァン2
孍イェン2
孎ヂュ2
孏ラン3
子ズー
孑ジェ2
孒ジュェ2
孓ジュェ2
孔コン3
孕ユン4
孖マー1
字ズー4
存ツン2
孙スン1
孚フー2
孛ベイ4
孜ズー1
孝シァォ4
孞シン4
孟モン4
孠スー4
孡タイ1
孢バオ1
季ジー4
孤グー1
孥ヌー2
学シュェ2
孧ヨウ4
孨ヂュァン3
孩ファン2
孪ルゥァン2
孫スン1
孬ナオ1
孭ミィェ1
孮ツォン2
孯チィェン1
孰シュ2
孱ツァン4
孲ヤー1
孳ズー1
孴ニー3
孵フー1
孶ズー1
孷リー2
學シュェ2
孹ブォ4
孺ルー2
孻ナイ2
孼ニィェ4
孽ニィェ4
孾イン1
孿ルゥァン2
宀ミィェン2
宁ニン2
宂ロン3
它ター1
宄グゥェイ3
宅ヂャイ2
宆チォン2
宇ユー3
守ショウ3
安アン1
宊トゥ1
宋ソン4
完ワン2
宍ロウ4
宎イャォ3
宏ホン2
宐イー2
宑ジン3
宒ヂュン1
宓ミィ4
宔ヂュ3
宕ダン4
宖ホン2
宗ゾン1
官グァン1
宙ヂョウ4
定ディン4
宛ワン3
宜イー2
宝バオ3
实シー2
実シー2
宠チョン3
审シェン3
客クェ゛ァ4
宣シュェン1
室シー4
宥ヨウ4
宦ファン4
宧イー2
宨ティァォ3
宩シー3
宪シィェン4
宫ゴン1
宬チォン2
宭チュン2
宮ゴン1
宯シァォ1
宰ザイ3
宱ヂャ4
宲バオ3
害ファン4
宴イェン4
宵シァォ1
家ジャ1
宷シェン3
宸チェン2
容ロン2
宺フゥァン3
宻ミィ4
宼コウ4
宽クァン1
宾ビン1
宿スー4
寀ツァィ3
寁ザン3
寂ジー4
寃ユェン1
寄ジー4
寅イン2
密ミィ4
寇コウ4
寈チン1
寉フェ゛ァ4
寊ヂェン1
寋ジィェン4
富フー4
寍ニン2
寎ビン4
寏ファン2
寐メイ4
寑チン3
寒ハン2
寓ユー4
寔シー2
寕ニン2
寖ジン4
寗ニン2
寘ヂー4
寙ユー3
寚バオ3
寛クァン1
寜ニン2
寝チン3
寞ムー4
察チャ2
寠ジュ4
寡グァ3
寢チン3
寣フー1
寤ウー4
寥リィァォ2
實シー2
寧ニン2
寨ヂャイ4
審シェン3
寪ウェイ3
寫シェ3
寬クァン1
寭フゥェイ4
寮リィァォ2
寯ジュン4
寰ファン2
寱イー4
寲イー2
寳バオ3
寴チン1
寵チョン3
寶バオ3
寷フォン1
寸ツン4
对ドゥェイ4
寺スー4
寻シュン2
导ダオ3
寽リュ4
対ドゥェイ4
寿ショウ4
尀ポォ3
封フォン1
専ヂュァン1
尃フー1
射シェ゛ァ4
尅クェ゛ァ4
将ジィァン1
將ジィァン1
專ヂュァン1
尉ウェイ4
尊ズン1
尋シュン2
尌シュ4
對ドゥェイ4
導ダオ3
小シァォ3
尐ジェ2
少シャオ3
尒ェ゛ァー3
尓ェ゛ァー3
尔ェ゛ァー3
尕チィェ3
尖ジィェン1
尗シュ1
尘チェン2
尙シャン4
尚シャン4
尛ムー2
尜チィェ2
尝chang2
尞リィァォ4
尟シィェン3
尠シィェン3
尡クン
尢ヨウ2
尣ワン1
尤ヨウ2
尥リィァォ4
尦リィァォ4
尧イャォ2
尨マン2
尩ワン1
尪ワン1
尫ワン1
尬チィェ4
尭イャォ2
尮ドゥォ4
尯クゥェイ4
尰ヂョン3
就ジゥ4
尲ガン1
尳グー3
尴ガン1
尵トゥェイ2
尶ガン1
尷ガン1
尸シー1
尹イン3
尺チー3
尻カオ1
尼ニー2
尽ジン3
尾ウェイ3
尿ニィァォ4
局ジュ2
屁ピー4
层ツォン2
屃シー4
屄ビー1
居ジュ1
屆ジェ4
屇ティェン2
屈チュ1
屉ティ4
届ジェ4
屋ウー1
屌ディァォ3
屍シー1
屎シー3
屏ピン2
屐ジー1
屑シェ4
屒ヂェン3
屓シェ4
屔ニー2
展ヂャン3
屖シー1
屗ウェイ3
屘マン3
屙ェ゛ァ1
屚ロウ4
屛ピン2
屜ティ4
屝フェイ4
属シュ3
屟シェ4
屠トゥ2
屡リュ3
屢リュ3
屣シー3
層ツォン2
履リュ3
屦ジュ4
屧シェ4
屨ジュ4
屩ジュェ1
屪リィァォ2
屫ジュェ2
屬シュ3
屭シー4
屮チェ゛ァ4
屯トゥン2
屰ニー4
山シャン1
屲ウァ1
屳シィェン1
屴リー4
屵ェ゛ァ4
屶フゥェイ4
屷フゥェイ4
屸ロン2
屹イー4
屺チー3
屻レン4
屼ウー4
屽ハン4
屾シェン1
屿ユー3
岀チュ1
岁スェイ4
岂チー3
岃レン4
岄ユェ4
岅バン3
岆イャォ3
岇アン2
岈ヤー2
岉ウー4
岊ジェ2
岋ェ゛ァ4
岌ジー2
岍チィェン1
岎フェン2
岏ワン2
岐チー2
岑cen2
岒チィェン2
岓チー2
岔チャ4
岕ジェ4
岖チュ1
岗ガン3
岘シィェン4
岙アオ4
岚ラン2
岛ダオ3
岜バー1
岝ズゥォ4
岞ズゥォ4
岟ヤン3
岠ジュ4
岡ガン1
岢クェ゛ァ3
岣ゴウ3
岤シュェ2
岥ポォ1
岦リー4
岧ティァォ2
岨チュ1
岩イェン2
岪フー2
岫シゥ4
岬ジャ3
岭リン3
岮トゥォ2
岯ピー2
岰アオ4
岱ダイ4
岲クゥァン4
岳ユェ4
岴チュ1
岵フー4
岶ポォ4
岷ミン2
岸アン4
岹ティァォ2
岺リン2
岻チー2
岼ピン2
岽ドン1
岾ハン4
岿クゥェイ1
峀シゥ4
峁マオ3
峂トン2
峃シュェ2
峄イー4
峅ビィェン4
峆フェ゛ァ2
峇バー1
峈ルゥォ4
峉ェ゛ァ4
峊フー4
峋シュン2
峌ディェ2
峍ルー4
峎エン3
峏ェ゛ァー2
峐ガイ1
峑チュェン1
峒ドン4
峓イー2
峔ムー3
峕シー2
峖アン1
峗ウェイ2
峘ファン2
峙ヂー4
峚ミィ4
峛リー3
峜ジー4
峝トン2
峞ウェイ2
峟ヨウ4
峠チィァ3
峡シァ2
峢リー3
峣イャォ2
峤ジャオ4
峥ヂォン1
峦ルゥァン2
峧ジャオ1
峨ェ゛ァ2
峩ェ゛ァ2
峪ユー4
峫シェ2
峬ブー1
峭チィァォ4
峮チュン1
峯フォン1
峰フォン1
峱ナオ2
峲リー3
峳ヨウ2
峴シィェン4
峵ロン2
島ダオ3
峷シェン1
峸チォン2
峹トゥ2
峺ゴン3
峻ジュン4
峼ガオ4
峽シァ2
峾イン2
峿ユー3
崀ラン4
崁カン4
崂ラオ2
崃ライ2
崄シィェン3
崅チュェ4
崆コン1
崇チョン2
崈チョン2
崉ター4
崊リン2
崋ファ4
崌ジュ1
崍ライ2
崎チー2
崏ミン2
崐クン1
崑クン1
崒ズー2
崓グー4
崔ツェイ1
崕ヤー2
崖ヤー2
崗ガン3
崘ルゥン2
崙ルゥン2
崚ラン2
崛ジュェ2
崜ドゥォ1
崝ヂォン1
崞グゥォ1
崟イン2
崠ドン1
崡ハン2
崢ヂォン1
崣ウェイ3
崤シァォ2
崥ピー2
崦イェン1
崧ソン1
崨ジェ2
崩ボン1
崪ズー2
崫クー1
崬ドン1
崭ヂャン3
崮グー4
崯イン2
崰ズー1
崱ゼェ゛ァ4
崲フゥァン2
崳ユー2
崴ワィ3
崵ヤン2
崶フォン1
崷チィゥ2
崸ヤン2
崹ティ2
崺イー3
崻ヂー4
崼シー4
崽ザイ3
崾イャォ3
崿ェ゛ァ4
嵀ヂュ4
嵁カン1
嵂リュ4
嵃イェン3
嵄メイ3
嵅ハン2
嵆ジー1
嵇ジー1
嵈ファン4
嵉ティン2
嵊シォン4
嵋メイ2
嵌チィェン4
嵍ウー4
嵎ユー2
嵏ゾン1
嵐ラン2
嵑クェ゛ァ3
嵒イェン2
嵓イェン2
嵔ウェイ3
嵕ゾン1
嵖チャ2
嵗スェイ4
嵘ロン2
嵙クェ゛ァ1
嵚チン1
嵛ユー2
嵜チー2
嵝ロウ3
嵞トゥ2
嵟ドゥェイ1
嵠シー1
嵡ウォン3
嵢ツァン1
嵣ダン4
嵤ロン2
嵥ジェ2
嵦カイ3
嵧リィゥ2
嵨ウー4
嵩ソン1
嵪チィァォ1
嵫ズー1
嵬ウェイ2
嵭ボン1
嵮ディェン1
嵯ツゥォ2
嵰チィェン3
嵱ヨン3
嵲ニィェ4
嵳ツゥォ2
嵴ジー3
嵵シー2
嵶ルォ4
嵷ソン3
嵸ゾン1
嵹ジィァン4
嵺リィァォ2
嵻カン1
嵼チャン3
嵽ディェ2
嵾cen1
嵿ディン3
嶀トゥ1
嶁ロウ3
嶂ヂャン4
嶃ヂャン3
嶄ヂャン3
嶅アオ2
嶆ツァォ2
嶇チュ1
嶈チィァン1
嶉ツェイ1
嶊ズェイ3
嶋ダオ3
嶌ダオ3
嶍シー2
嶎ユー4
嶏ペイ4
嶐ロン2
嶑シィァン4
嶒ツォン2
嶓ブォ1
嶔チン1
嶕ジャオ1
嶖イェン1
嶗ラオ2
嶘ヂャン4
嶙リン2
嶚リィァォ2
嶛リィァォ2
嶜ジン1
嶝ドン4
嶞ドゥォ4
嶟ズン1
嶠ジャオ4
嶡グゥェイ4
嶢イャォ2
嶣ジャオ1
嶤イャォ2
嶥ジュェ2
嶦ヂャン1
嶧イー4
嶨シュェ2
嶩ナオ2
嶪イェ4
嶫イェ4
嶬イー2
嶭ニィェ4
嶮シィェン3
嶯ジー2
嶰シェ4
嶱クェ゛ァ3
嶲シー1
嶳デ4
嶴アオ4
嶵ズェイ3
嶶ウェイ1
嶷イー2
嶸ロン2
嶹ダオ3
嶺リン3
嶻ジェ2
嶼ユー3
嶽ユェ4
嶾イン3
嶿ルー
巀ジェ2
巁リー4
巂グゥェイ1
巃ロン2
巄ロン2
巅ディェン1
巆ロン2
巇シー1
巈ジュ2
巉チャン2
巊イン3
巋クゥェイ1
巌イェン2
巍ウェイ1
巎ナオ2
巏チュェン2
巐チャオ3
巑ツァン2
巒ルゥァン2
巓ディェン1
巔ディェン1
巕ニィェ4
巖イェン2
巗イェン2
巘イェン3
巙クゥェイ2
巚イェン3
巛チュァン1
巜クァィ4
川チュァン1
州ヂョウ1
巟フゥァン1
巠ジン1
巡シュン2
巢チャオ2
巣チャオ2
巤リィェ4
工ゴン1
左ズゥォ3
巧チィァォ3
巨ジュ4
巩ゴン3
巪ジュ4
巫ウー1
巬プー
巭プー
差チャ4
巯チィゥ2
巰チィゥ2
己ジー3
已イー3
巳スー4
巴バー1
巵ヂー1
巶ヂャオ1
巷シィァン4
巸イー2
巹ジン3
巺シュン4
巻ジュェン4
巼バー1
巽シュン4
巾ジン1
巿フー2
帀ザー1
币ビー4
市シー4
布ブー4
帄ディン1
帅シュァイ4
帆ファン1
帇ニィェ4
师シー1
帉フェン1
帊パー4
帋ヂー3
希シー1
帍フー4
帎ダン4
帏ウェイ2
帐ヂャン4
帑タン3
帒ダイ4
帓ムー4
帔ペイ4
帕パー4
帖ティェ1
帗ブォ1
帘リィェン2
帙ヂー4
帚ヂョウ3
帛ブォ2
帜ヂー4
帝デ4
帞ムー4
帟イー4
帠イー4
帡ピン2
帢チィァ4
帣ジュェン3
帤ルー2
帥シュァイ4
带ダイ4
帧ヂォン4
帨シュェイ4
帩チィァォ4
帪ヂェン1
師シー1
帬チュン2
席シー2
帮バン1
帯ダイ4
帰グゥェイ1
帱チョウ2
帲ピン2
帳ヂャン4
帴サン4
帵ワン1
帶ダイ4
帷ウェイ2
常chang2
帹シャ4
帺チー2
帻ゼェ゛ァ2
帼グゥォ2
帽マオ4
帾ドゥ3
帿ホウ2
幀ヂォン4
幁シュ1
幂ミィ4
幃ウェイ2
幄ウォ4
幅フー2
幆イー4
幇バン1
幈ピン2
幉ディェ2
幊ゴン1
幋パン2
幌フゥァン3
幍タオ1
幎ミィ4
幏ジャ4
幐トン2
幑フゥェイ1
幒ヂョン1
幓シャン1
幔マン4
幕ムー4
幖ビィャォ1
幗グゥォ2
幘ゼェ゛ァ2
幙ムー4
幚バン1
幛ヂャン4
幜ジン3
幝チャン3
幞フー2
幟ヂー4
幠フー1
幡ファン1
幢チュゥァン2
幣ビー4
幤ビー4
幥ヂャン3
幦ミィ4
幧チィァォ1
幨チャン1
幩フェン2
幪モン2
幫バン1
幬チョウ2
幭ミィェ4
幮チュ2
幯ジェ2
幰シィェン3
幱ラン2
干ガン4
平ピン2
年ニィェン2
幵ジィェン1
并ビン4
幷ビン4
幸シン4
幹ガン4
幺イャォ1
幻ファン4
幼ヨウ4
幽ヨウ1
幾ジー3
广グゥァン3
庀ピー3
庁ティン1
庂ゼェ゛ァ4
広グゥァン3
庄ヂュゥァン1
庅ムー2
庆チン4
庇ビー4
庈チン2
庉ドゥン4
床チュゥァン2
庋グゥェイ3
庌ヤー3
庍バイ4
庎ジェ4
序シュ4
庐ルー2
庑ウー3
庒ヂュゥァン1
库クー4
应イン1
底デ3
庖パオ2
店ディェン4
庘ヤー1
庙ミィァォ4
庚ゴン1
庛ツー4
府フー3
庝トン2
庞パン2
废フェイ4
庠シィァン2
庡イー3
庢ヂー4
庣ティァォ1
庤ヂー4
庥シゥ1
度ドゥ4
座ズゥォ4
庨シァォ1
庩トゥ2
庪グゥェイ3
庫クー4
庬マン2
庭ティン2
庮ヨウ3
庯ブー1
庰ビン4
庱チォン3
庲ライ2
庳ビー4
庴ジー2
庵アン1
庶シュ4
康カン1
庸ヨン1
庹トゥォ3
庺ソン1
庻シュ4
庼チン3
庽ユー4
庾ユー3
庿ミィァォ4
廀ソウ1
廁ツェ゛ァ4
廂シィァン1
廃フェイ4
廄ジゥ4
廅ェ゛ァ4
廆グゥェイ1
廇リィゥ4
廈シャ4
廉リィェン2
廊ラン2
廋ソウ1
廌ヂー4
廍ブー4
廎チン3
廏ジゥ4
廐ジゥ4
廑ジン3
廒アオ2
廓クォ4
廔ロウ2
廕イン4
廖リィァォ4
廗ダイ4
廘ルー4
廙イー4
廚チュ2
廛チャン2
廜トゥ2
廝スー1
廞シン1
廟ミィァォ4
廠chang3
廡ウー3
廢フェイ4
廣グゥァン3
廤クー4
廥クァィ4
廦ビー4
廧チィァン2
廨シェ4
廩リン3
廪リン3
廫リィァォ2
廬ルー2
廭ジー4
廮イン3
廯シィェン1
廰ティン1
廱ヨン1
廲リー2
廳ティン1
廴イン3
廵シュン2
延イェン2
廷ティン2
廸デ2
廹パイ3
建ジィェン4
廻フゥェイ2
廼ナイ3
廽フゥェイ2
廾ゴン3
廿ニィェン4
开カイ1
弁ビィェン4
异イー4
弃チー4
弄ノン4
弅フェン4
弆ジュ3
弇イェン3
弈イー4
弉ザン4
弊ビー4
弋イー4
弌イー1
弍ェ゛ァー4
弎サン1
式シー4
弐ェ゛ァー4
弑シー4
弒シー4
弓ゴン1
弔ディァォ4
引イン3
弖フー4
弗フー2
弘ホン2
弙ウー1
弚トゥェイ2
弛チー2
弜ジィァン4
弝バー4
弞シェン3
弟デ4
张ヂャン1
弡ジュェ2
弢タオ1
弣フー3
弤デ3
弥ミィ2
弦シィェン2
弧フー2
弨チャオ1
弩ヌー3
弪ジン4
弫ヂェン3
弬イー2
弭ミィ3
弮チュェン1
弯ワン1
弰シャオ1
弱ルォ4
弲シュェン1
弳ジン4
弴ディァォ1
張ヂャン1
弶ジィァン4
強チィァン2
弸ポン2
弹ダン4
强チィァン2
弻ビー4
弼ビー4
弽シェ゛ァ4
弾ダン4
弿ジィェン3
彀ゴウ4
彁グェ゛ァ1
彂ファ1
彃ビー4
彄コウ1
彅ジィェン3
彆ビィェ4
彇シァォ1
彈ダン4
彉グゥォ1
彊ジィァン4
彋ホン2
彌ミィ2
彍グゥォ1
彎ワン1
彏ジュェ2
彐ジー4
彑ジー4
归グゥェイ1
当ダン1
彔ルー4
录ルー4
彖トゥァン4
彗フゥェイ4
彘ヂー4
彙フゥェイ4
彚フゥェイ4
彛イー2
彜イー2
彝イー2
彞イー2
彟ユェ1
彠ユェ1
彡シャン1
形シン2
彣ウェン2
彤トン2
彥イェン4
彦イェン4
彧ユー4
彨チー1
彩ツァィ3
彪ビィャォ1
彫ディァォ1
彬ビン1
彭ポン2
彮ヨン3
彯ピィァオ1
彰ヂャン1
影イン3
彲チー1
彳チー4
彴ヂュオ2
彵トゥォ3
彶ジー2
彷パン2
彸ヂョン1
役イー4
彺ワン2
彻チェ゛ァ4
彼ビー3
彽デ1
彾リン2
彿フー2
往ワン3
征ヂォン1
徂ツゥ2
徃ワン3
径ジン4
待ダイ4
徆シー1
徇シュン4
很ヘン3
徉ヤン2
徊フゥァイ2
律リュ4
後ホウ4
徍ワン3
徎チォン3
徏ヂー4
徐シュ2
徑ジン4
徒トゥ2
従ツォン2
徔ヂー
徕ライ2
徖ツォン2
得ディ2
徘パイ2
徙シー3
徚ドン1
徛ジー4
徜chang2
徝ヂー4
從ツォン2
徟ヂョウ1
徠ライ2
御ユー4
徢シェ4
徣ジェ4
徤ジィェン4
徥シー4
徦ジャ3
徧ビィェン4
徨フゥァン2
復フー4
循シュン2
徫ウェイ3
徬パン2
徭イャォ2
微ウェイ1
徯シー1
徰ヂォン1
徱ピィァオ4
徲ティ2
徳ディ2
徴ヂォン1
徵ヂォン1
徶ビィェ2
德ディ2
徸チョン1
徹チェ゛ァ4
徺ジャオ3
徻フゥェイ4
徼ジャオ3
徽フゥェイ1
徾メイ2
徿ロン4
忀シィァン1
忁バオ4
忂チュ2
心シン1
忄シン
必ビー4
忆イー4
忇ラ4
忈レン2
忉ダオ1
忊ディン4
忋ガイ3
忌ジー4
忍レン3
忎レン2
忏チャン4
忐タン3
忑テェ゛ァ4
忒テェ゛ァ4
忓ガン1
忔チー4
忕シー4
忖ツン3
志ヂー4
忘ワン4
忙マン2
忚シー1
忛ファン1
応イン1
忝ティェン3
忞ミン2
忟ウェン3
忠ヂョン1
忡チョン1
忢ウー4
忣ジー2
忤ウー3
忥シー4
忦ジャ2
忧ヨウ1
忨ワン4
忩ツォン1
忪ソン1
快クァィ4
忬ユー4
忭ビィェン4
忮ヂー4
忯チー2
忰ツェイ4
忱チェン2
忲タイ4
忳トゥン2
忴チィェン2
念ニィェン4
忶フン2
忷シィォン1
忸ニィゥ3
忹クゥァン2
忺シィェン1
忻シン1
忼カン1
忽フー1
忾カイ4
忿フェン4
怀フゥァイ2
态タイ4
怂ソン3
怃ウー3
怄オウ4
怅chang4
怆チュゥァン4
怇ジュ4
怈イー4
怉バオ3
怊チャオ1
怋ミン2
怌ペイ1
怍ズゥォ4
怎ゼン3
怏ヤン4
怐ジュ4
怑バン4
怒ヌー4
怓ナオ2
怔ヂォン1
怕パー4
怖ブー4
怗ティェ1
怘フー4
怙フー4
怚ジュ4
怛ダー2
怜リィェン2
思スー1
怞チョウ2
怟デ4
怠ダイ4
怡イー2
怢トゥ1
怣ヨウ2
怤フー1
急ジー2
怦ポン1
性シン4
怨ユェン4
怩ニー2
怪グゥァイ4
怫フー2
怬シー4
怭ビー4
怮ヨウ1
怯チィェ4
怰シュェン4
怱ツォン1
怲ビン3
怳フゥァン3
怴シュ4
怵チュ4
怶ビー4
怷シュ4
怸シー1
怹タン1
怺ヨン3
总ゾン3
怼ドゥェイ4
怽ムー
怾ヂー3
怿イー4
恀シー4
恁ネン4
恂シュン2
恃シー4
恄シー4
恅ラオ3
恆ホン2
恇クゥァン1
恈モウ2
恉ヂー3
恊シェ2
恋リィェン4
恌ティァォ1
恍フゥァン3
恎ディェ2
恏ハオ4
恐コン3
恑グゥェイ3
恒ホン2
恓シー1
恔ジャオ3
恕シュ4
恖スー1
恗フー1
恘チィゥ1
恙ヤン4
恚フゥェイ4
恛フゥェイ2
恜チー4
恝ジャ2
恞イー2
恟シィォン1
恠グゥァイ4
恡リン4
恢フゥェイ1
恣ズー4
恤シュ4
恥チー3
恦シャン4
恧ニュ4
恨ヘン4
恩エン1
恪クェ゛ァ4
恫ドン4
恬ティェン2
恭ゴン1
恮チュェン1
息シー1
恰チィァ4
恱ユェ4
恲ポン1
恳ケン3
恴ディ2
恵フゥェイ4
恶ェ゛ァ4
恷シァォ
恸トン4
恹イェン1
恺カイ3
恻ツェ゛ァ4
恼ナオ3
恽ユン4
恾マン2
恿ヨン3
悀ヨン3
悁ユェン1
悂ピー1
悃クン3
悄チィァォ1
悅ユェ4
悆ユー4
悇トゥ2
悈ジェ4
悉シー1
悊ヂェ゛ァ2
悋リン4
悌ティ4
悍ハン4
悎ハオ4
悏チィェ4
悐ティ4
悑ブー4
悒イー4
悓チィェン4
悔フゥェイ3
悕シー1
悖ベイ4
悗マン2
悘イー1
悙ホン1
悚ソン3
悛チュェン1
悜チォン3
悝クゥェイ1
悞ウー4
悟ウー4
悠ヨウ1
悡リー2
悢リィァン4
患ファン4
悤ツォン1
悥イー4
悦ユェ4
悧リー4
您ニン2
悩ナオ3
悪ェ゛ァ4
悫チュェ4
悬シュェン2
悭チィェン1
悮ウー4
悯ミン3
悰ツォン2
悱フェイ3
悲ベイ1
悳ディ2
悴ツェイ4
悵chang4
悶メン4
悷リー4
悸ジー4
悹グァン4
悺グァン4
悻シン4
悼ダオ4
悽チー1
悾コン1
悿ティェン3
惀ルゥン2
惁シー1
惂カン3
惃ガン3
惄ニー4
情チン2
惆チョウ2
惇ドゥン1
惈グゥォ3
惉ヂャン1
惊ジン1
惋ワン3
惌ユェン1
惍ジン1
惎ジー4
惏ラン2
惐ユー4
惑フォ4
惒フェ゛ァ2
惓チュェン2
惔タン2
惕ティ4
惖ティ4
惗ニィェ4
惘ワン3
惙チュォ4
惚フー1
惛フン1
惜シー1
惝chang3
惞シン1
惟ウェイ2
惠フゥェイ4
惡ェ゛ァ4
惢スォ3
惣ゾン3
惤ジィェン1
惥ヨン3
惦ディェン4
惧ジュ4
惨ツァン3
惩チォン2
惪ディ2
惫ベイ4
惬チィェ4
惭ツァン2
惮ダン4
惯グァン4
惰ドゥォ4
惱ナオ3
惲ユン4
想シィァン3
惴ヂュェイ4
惵ディェ2
惶フゥァン2
惷チュン3
惸チォン2
惹ルェ゛ァ3
惺シン1
惻ツェ゛ァ4
惼ビィェン3
惽ミン3
惾ゾン1
惿ティ2
愀チィァォ3
愁チョウ2
愂ベイ4
愃シュェン1
愄ウェイ1
愅グェ゛ァ2
愆チィェン1
愇ウェイ3
愈ユー4
愉ユー2
愊ビー4
愋シュェン1
愌ファン4
愍ミン3
愎ビー4
意イー4
愐ミィェン3
愑ヨン3
愒カイ4
愓ダン4
愔イン1
愕ェ゛ァ4
愖チェン2
愗マオ4
愘チィァ4
愙クェ゛ァ4
愚ユー2
愛ェ゛ァ4
愜チィェ4
愝イェン3
愞ヌオ4
感ガン3
愠ユン4
愡ゾン3
愢サイ1
愣ラン4
愤フェン4
愥イン1
愦クゥェイ4
愧クゥェイ4
愨チュェ4
愩ゴン1
愪ユン2
愫スー4
愬スー4
愭チー2
愮イャォ2
愯ソン3
愰フゥァン4
愱ジー2
愲グー3
愳ジュ4
愴チュゥァン4
愵ニー4
愶シェ2
愷カイ3
愸ヂォン3
愹ヨン3
愺ツァォ3
愻シュン4
愼シェン4
愽ブォ2
愾カイ4
愿ユェン4
慀シー4
慁フン4
慂ヨン3
慃ヤン3
慄リー4
慅サオ1
慆タオ1
慇イン1
慈ツー2
慉シュ4
慊チィェン4
態タイ4
慌フゥァン1
慍ユン4
慎シェン4
慏ミン3
慐ゴン
慑シェ゛ァ4
慒ツォン2
慓ピィァオ1
慔ムー4
慕ムー4
慖グゥォ2
慗チー4
慘ツァン3
慙ツァン2
慚ツァン2
慛ツェイ1
慜ミン3
慝テェ゛ァ4
慞ヂャン1
慟トン4
慠アオ4
慡シュゥァン3
慢マン4
慣グァン4
慤チュェ4
慥ザオ4
慦ジゥ4
慧フゥェイ4
慨カイ3
慩リィェン2
慪オウ4
慫ソン3
慬チン2
慭イン4
慮リュ4
慯シャン1
慰ウェイ4
慱トゥァン2
慲マン2
慳チィェン1
慴シェ゛ァ4
慵ヨン1
慶チン4
慷カン1
慸デ4
慹ヂー2
慺ロウ2
慻ジュェン4
慼チー1
慽チー1
慾ユー4
慿ピン2
憀リィァォ2
憁ツォン4
憂ヨウ1
憃チョン1
憄ヂー4
憅トン4
憆チォン1
憇チー4
憈チュ1
憉ポン2
憊ベイ4
憋ビィェ1
憌チォン2
憍ジャオ1
憎ゾン1
憏チー4
憐リィェン2
憑ピン2
憒クゥェイ4
憓フゥェイ4
憔チィァォ2
憕チォン2
憖イン4
憗イン4
憘シー3
憙シー1
憚ダン4
憛タン2
憜ドゥォ4
憝ドゥェイ4
憞ドゥェイ4
憟スー4
憠ジュェ2
憡ツェ゛ァ4
憢シァォ1
憣ファン1
憤フェン4
憥ラオ2
憦ラオ4
憧チョン1
憨ハン1
憩チー4
憪シィェン2
憫ミン3
憬ジン3
憭リィァォ3
憮ウー3
憯ツァン3
憰ジュェ2
憱ツゥ4
憲シィェン4
憳タン3
憴シォン2
憵ピー1
憶イー4
憷チュ4
憸シィェン1
憹ナオ2
憺ダン4
憻タン3
憼ジン3
憽ソン1
憾ハン4
憿ジャオ3
懀ウェイ4
懁シュェン1
懂ドン3
懃チン2
懄チン2
懅ジュ4
懆ツァォ3
懇ケン3
懈シェ4
應イン1
懊アオ4
懋マオ4
懌イー4
懍リン3
懎スェ゛ァ4
懏ジュン4
懐フゥァイ2
懑メン4
懒ラン3
懓ェ゛ァ4
懔リン3
懕イェン1
懖クォ4
懗シァ4
懘チー4
懙ユー3
懚イン4
懛ダイ1
懜モン3
懝ェ゛ァ4
懞モン2
懟ドゥェイ4
懠チー2
懡ムー3
懢ラン2
懣メン4
懤チョウ2
懥ヂー4
懦ヌオ4
懧ヌオ4
懨イェン1
懩ヤン3
懪ブォ2
懫ヂー4
懬クゥァン4
懭クゥァン3
懮ヨウ3
懯フー1
懰リィゥ2
懱ミィェ4
懲チォン2
懳フゥェイ
懴チャン4
懵モン3
懶ラン3
懷フゥァイ2
懸シュェン2
懹ラン4
懺チャン4
懻ジー4
懼ジュ4
懽ファン1
懾シェ゛ァ4
懿イー4
戀リィェン4
戁ナン3
戂ミィ2
戃タン3
戄ジュェ2
戅ガン4
戆ガン4
戇ヂュゥァン4
戈グェ゛ァ1
戉ユェ4
戊ウー4
戋ジィェン1
戌シュ1
戍シュ4
戎ロン2
戏シー4
成チォン2
我ウォ3
戒ジェ4
戓グェ゛ァ1
戔ジィェン1
戕チィァン1
或フォ4
戗チィァン1
战ヂャン4
戙ドン4
戚チー1
戛ジャ2
戜ディェ2
戝ゼイ2
戞ジャ2
戟ジー3
戠ヂー1
戡カン1
戢ジー2
戣クゥェイ2
戤ガイ4
戥ドン3
戦ヂャン4
戧チィァン1
戨グェ゛ァ1
戩ジィェン3
截ジェ2
戫ユー4
戬ジィェン3
戭イェン3
戮ルー4
戯フー1
戰ヂャン4
戱シー4
戲シー4
戳チュォ1
戴ダイ4
戵チュ2
戶フー4
户フー4
戸フー4
戹ェ゛ァ4
戺シー4
戻ティ4
戼マオ3
戽フー4
戾リー4
房ファン2
所スォ3
扁ビィェン3
扂ディェン4
扃ジィォン1
扄シャン3
扅イー2
扆イー3
扇シャン4
扈フー4
扉フェイ1
扊イェン3
手ショウ3
扌ショウ
才ツァィ2
扎ヂャ1
扏チィゥ2
扐ラ4
扑プー1
扒バー1
打ダー3
扔ロン1
払ファン3
扖ルー4
扗ザイ4
托トゥォ1
扙ヂャン4
扚ディァォ3
扛カン2
扜ユー1
扝クー1
扞ガン3
扟シェン1
扠チャ1
扡トゥォ1
扢グー3
扣コウ4
扤ウー4
扥ドン4
扦チィェン1
执ヂー2
扨レン4
扩クォ4
扪メン2
扫サオ3
扬ヤン2
扭ニィゥ3
扮バン4
扯チェ゛ァ3
扰ラオ3
扱シー1
扲チィェン2
扳バン1
扴ジャ2
扵ユー2
扶フー2
扷アオ4
扸シー1
批ピー1
扺ヂー3
扻ヂー4
扼ェ゛ァ4
扽ドン4
找ヂャオ3
承チォン2
技ジー4
抁イェン3
抂クゥァン2
抃ビィェン4
抄チャオ1
抅ジュ1
抆ウェン3
抇フー2
抈ユェ4
抉ジュェ2
把バー3
抋チン4
抌ダン3
抍ヂォン3
抎ユン3
抏ワン2
抐ヌェ゛ァ4
抑イー4
抒シュ1
抓ヂュア1
抔ポウ2
投トウ2
抖ドウ3
抗カン4
折ヂェ゛ァ2
抙ポウ2
抚フー3
抛パオ1
抜バー2
抝アオ3
択ゼェ゛ァ2
抟トゥァン2
抠コウ1
抡ルゥン1
抢チィァン3
抣ユン
护フー4
报バオ4
抦ビン3
抧ヂー3
抨ポン1
抩ナン2
抪ブー4
披ピー1
抬タイ2
抭イャォ3
抮ヂェン3
抯ヂャ1
抰ヤン1
抱バオ4
抲フェ゛ァ1
抳ニー3
抴イェ4
抵デ3
抶チー4
抷ピー1
抸ジャ1
抹ムー3
抺メイ4
抻チェン1
押ヤー1
抽チョウ1
抾チュ1
抿ミン3
拀チュ4
拁ジャ1
拂フー2
拃ヂャ3
拄ヂュ3
担ダン1
拆チャイ1
拇ムー3
拈ニィェン1
拉ラー1
拊フー3
拋パオ1
拌バン4
拍パイ1
拎リン1
拏ナー2
拐グゥァイ3
拑チィェン2
拒ジュ4
拓トゥォ4
拔バー2
拕トゥォ1
拖トゥォ1
拗アオ3
拘ジュ1
拙ヂュオ1
拚パン4
招ヂャオ1
拜バイ4
拝バイ4
拞デ3
拟ニー3
拠ジュ4
拡クォ4
拢ロン3
拣ジィェン3
拤チィァ2
拥ヨン1
拦ラン2
拧ニン2
拨ブォ1
择ゼェ゛ァ2
拪チィェン1
拫ヘン2
括クォ4
拭シー4
拮ジェ2
拯ヂォン3
拰ニン3
拱ゴン3
拲ゴン3
拳チュェン2
拴シュァン1
拵ツン2
拶ザー1
拷カオ3
拸イー2
拹シェ2
拺ツェ゛ァ4
拻フゥェイ1
拼ピン1
拽ヂュァイ1
拾シー2
拿ナー2
挀バイ1
持チー2
挂グァ4
挃ヂー4
挄クォ4
挅ドゥォ3
挆ドゥォ3
指ヂー3
挈チィェ4
按アン4
挊ノン4
挋ヂェン4
挌グェ゛ァ2
挍ジャオ4
挎クァ4
挏ドン4
挐ナー2
挑ティァォ1
挒リィェ4
挓ヂャ1
挔リュ3
挕ディェ2
挖ウァ1
挗ジュェ2
挘リィェ3
挙ジュ3
挚ヂー4
挛ルゥァン2
挜ヤー4
挝ウォ1
挞ター4
挟シェ2
挠ナオ2
挡ダン3
挢ジャオ3
挣ヂォン1
挤ジー3
挥フゥェイ1
挦シィェン2
挧ユー3
挨ェ゛ァ1
挩トゥォ1
挪ヌオ2
挫ツゥォ4
挬ブォ2
挭ゴン3
挮ティ3
振ヂェン4
挰チォン2
挱サー1
挲サー1
挳コン1
挴メイ3
挵ノン4
挶ジュ1
挷ポン2
挸ジィェン3
挹イー4
挺ティン3
挻シャン1
挼rua2
挽ワン3
挾シェ2
挿チャ1
捀フォン2
捁ジャオ3
捂ウー3
捃ジュン4
捄ジゥ4
捅トン3
捆クン3
捇フォ4
捈トゥ2
捉ヂュオ1
捊ポウ2
捋リュ3
捌バー1
捍ハン4
捎シャオ1
捏ニィェ1
捐ジュェン1
捑ゼェ゛ァ4
捒シュ4
捓イェ2
捔ジュェ2
捕ブー3
捖ワン2
捗ブー4
捘ズン4
捙イェ4
捚ヂャイ1
捛リュ3
捜ソウ1
捝トゥォ1
捞ラオ1
损スン3
捠バン1
捡ジィェン3
换ファン4
捣ダオ3
捤ウェイ3
捥ワン4
捦チン2
捧ポン3
捨シェ゛ァ3
捩リィェ4
捪ミン2
捫メン2
捬フー3
捭バイ3
据ジュ4
捯ダオ2
捰ウォ3
捱ェ゛ァ2
捲ジュェン3
捳ユェ4
捴ゾン3
捵チェン1
捶チュェイ2
捷ジェ2
捸トゥ1
捹ベン4
捺ナー4
捻ニィェン3
捼ルォ2
捽ズゥォ2
捾ウォ4
捿チー1
掀シィェン1
掁チォン2
掂ディェン1
掃サオ3
掄ルゥン1
掅チン4
掆ガン1
掇ドゥォ1
授ショウ4
掉ディァォ4
掊ポウ2
掋デ3
掌ヂャン3
掍フン4
掎ジー3
掏タオ1
掐チィァ1
掑チー2
排パイ2
掓シュ1
掔チィェン1
掕リン2
掖イェ1
掗ヤー4
掘ジュェ2
掙ヂォン1
掚リィァン3
掛グァ4
掜イー4
掝フォ4
掞シャン4
掟ヂォン3
掠リュェ4
採ツァィ3
探タン4
掣チェ゛ァ4
掤ビン1
接ジェ1
掦ティ4
控コン4
推トゥェイ1
掩イェン3
措ツゥォ4
掫ヂョウ1
掬ジュ1
掭ティェン4
掮チィェン2
掯ケン4
掰バイ1
掱パー2
掲ジェ1
掳ルー3
掴グゥァイ1
掵ミン
掶ジェ2
掷ヂー4
掸ダン3
掹モン
掺ツァン4
掻サオ1
掼グァン4
掽ポン4
掾ユェン4
掿ヌオ4
揀ジィェン3
揁ヂォン1
揂ジゥ1
揃ジィェン3
揄ユー2
揅イェン2
揆クゥェイ2
揇ナン3
揈ホン1
揉ロウ2
揊ピー4
揋ウェイ1
揌サイ1
揍ゾウ4
揎シュェン1
描ミィァォ2
提ティ2
揑ニィェ1
插チャ1
揓シー4
揔ゾン3
揕ヂェン4
揖イー1
揗シュン2
揘ヨン2
揙ビィェン1
揚ヤン2
換ファン4
揜イェン3
揝ザン3
揞アン3
揟シュ1
揠ヤー4
握ウォ4
揢クェ゛ァ2
揣チュァイ1
揤ジー2
揥ティ4
揦ラー2
揧ラー4
揨チェン2
揩カイ1
揪ジゥ1
揫ジゥ1
揬トゥ2
揭ジェ1
揮フゥェイ1
揯ゲン4
揰チョン4
揱シァォ1
揲ディェ2
揳シェ1
援ユェン2
揵チィェン2
揶イェ2
揷チャ1
揸ヂャ1
揹ベイ1
揺イャォ2
揻ウェイ1
揼ボン
揽ラン3
揾ウェン4
揿チン4
搀チャン1
搁グェ゛ァ1
搂ロウ3
搃ゾン3
搄ゲン4
搅ジャオ3
搆ゴウ4
搇チン4
搈ロン2
搉チュェ4
搊チョウ1
搋チュァイ1
搌ヂャン3
損スン3
搎スン1
搏ブォ2
搐チュ4
搑ロン2
搒バン4
搓ツゥォ1
搔サオ1
搕クェ゛ァ1
搖イャォ2
搗ダオ3
搘ヂー1
搙ヌー4
搚ラー1
搛ジィェン1
搜ソウ1
搝チィゥ3
搞ガオ3
搟シィェン3
搠シュォ4
搡サン3
搢ジン4
搣ミィェ4
搤ェ゛ァ4
搥チュェイ2
搦ヌオ4
搧シャン1
搨ター4
搩ヂャ3
搪タン2
搫パン2
搬バン1
搭ダー1
搮リー4
搯タオ1
搰フー2
搱ヂー4
搲ウァ1
搳ファ2
搴チィェン1
搵ウェン4
搶チィァン3
搷ティェン2
搸ヂェン1
搹ェ゛ァ4
携シェ2
搻ヌオ4
搼チュェン2
搽チャ2
搾ヂャ4
搿グェ゛ァ2
摀ウー3
摁エン4
摂シェ゛ァ4
摃カン2
摄シェ゛ァ4
摅シュ1
摆バイ3
摇イャォ2
摈ビン4
摉ソウ1
摊タン1
摋サー4
摌チャン3
摍スォ1
摎ジゥ1
摏チョン1
摐チュゥァン1
摑グゥァイ1
摒ビン3
摓フォン2
摔シュァイ1
摕デ4
摖チー4
摗ソウ1
摘ヂャイ1
摙リィェン3
摚チォン1
摛チー1
摜グァン4
摝ルー4
摞ルゥォ4
摟ロウ3
摠ゾン3
摡ガイ4
摢フー4
摣ヂャ1
摤チュゥァン3
摥タン4
摦ファ4
摧ツェイ1
摨ナイ2
摩ムー2
摪ジィァン1
摫グゥェイ1
摬イン3
摭ヂー2
摮アオ2
摯ヂー4
摰ニィェ4
摱マン4
摲チャン4
摳コウ1
摴チュ1
摵シェ゛ァ4
摶トゥァン2
摷ジャオ3
摸ムー1
摹ムー2
摺ヂェ゛ァ2
摻ツァン4
摼コン1
摽ビィャォ1
摾ジィァン4
摿イャォ2
撀ゴウ4
撁チィェン1
撂リィァォ4
撃ジー1
撄イン1
撅ジュェ1
撆ピェ1
撇ピェ1
撈ラオ1
撉ドゥン1
撊シィェン4
撋ルァン2
撌グゥェイ4
撍ザン3
撎イー4
撏シィェン2
撐チォン1
撑チォン1
撒サー1
撓ナオ2
撔ホン4
撕スー1
撖ハン4
撗グゥァン4
撘ダー1
撙ズン3
撚ニィェン3
撛リン3
撜ヂォン3
撝フゥェイ1
撞ヂュゥァン4
撟ジャオ3
撠ジー3
撡ツァォ1
撢ダン3
撣ダン3
撤チェ゛ァ4
撥ブォ1
撦チェ゛ァ3
撧ジュェ1
撨フー3
撩リィァォ1
撪ベン4
撫フー3
撬チィァォ4
播ブォ1
撮ツゥォ1
撯ヂュオ2
撰ヂュァン4
撱ウェイ3
撲プー1
撳チン4
撴ドゥン1
撵ニィェン3
撶ファ2
撷シェ2
撸ルー1
撹ジャオ3
撺ツァン1
撻ター4
撼ハン4
撽チィァォ4
撾ウォ1
撿ジィェン3
擀ガン3
擁ヨン1
擂レイ2
擃ナン3
擄ルー3
擅シャン4
擆ヂュオ2
擇ゼェ゛ァ2
擈プー1
擉チュォ4
擊ジー1
擋ダン3
擌スェ゛ァ4
操ツァォ1
擎チン2
擏チン2
擐ファン4
擑ジェ1
擒チン2
擓クァィ3
擔ダン1
擕シェ2
擖カー1
擗ピー3
擘バイ1
擙アオ4
據ジュ4
擛イェ4
擜ェ゛ァ4
擝モン1
擞ソウ3
擟ミィ2
擠ジー3
擡タイ2
擢ヂュオ2
擣ダオ3
擤シン3
擥ラン3
擦ツァ1
擧ジュ3
擨イェ2
擩ルー3
擪イェ4
擫イェ4
擬ニー3
擭ウォ4
擮ジェ2
擯ビン4
擰ニン2
擱グェ゛ァ1
擲ヂー4
擳ヂー4
擴クォ4
擵ムー2
擶ジィェン4
擷シェ2
擸リィェ4
擹タン1
擺バイ3
擻ソウ3
擼ルー3
擽リュェ4
擾ラオ3
擿ティ1
攀パン1
攁ヤン3
攂レイ4
攃ツァ1
攄シュ1
攅ザン3
攆ニィェン3
攇シィェン3
攈ジュン4
攉フォ1
攊リー4
攋ラー4
攌ファン3
攍イン2
攎ルー2
攏ロン3
攐チィェン1
攑チィェン1
攒ザン3
攓チィェン1
攔ラン2
攕シィェン1
攖イン1
攗メイ2
攘ラン3
攙チャン1
攚ウォン3
攛ツァン1
攜シェ2
攝シェ゛ァ4
攞ルゥォ2
攟ジュン4
攠ミィ2
攡チー1
攢ザン3
攣ルゥァン2
攤タン1
攥ズァン4
攦リー4
攧ディェン1
攨ウァ1
攩ダン3
攪ジャオ3
攫ジュェ2
攬ラン3
攭リー4
攮ナン3
支ヂー1
攰グゥェイ4
攱グゥェイ3
攲チー1
攳シュン2
攴プー1
攵プー1
收ショウ1
攷カオ3
攸ヨウ1
改ガイ3
攺イー3
攻ゴン1
攼ガン1
攽バン1
放ファン4
政ヂォン4
敀ポォ4
敁ディェン1
敂コウ4
敃ミン3
敄ウー4
故グー4
敆フェ゛ァ2
敇ツェ゛ァ4
效シァォ4
敉ミィ3
敊チュ4
敋グェ゛ァ2
敌デ2
敍シュ4
敎ジャオ4
敏ミン3
敐チェン2
救ジゥ4
敒シェン1
敓ドゥォ2
敔ユー3
敕チー4
敖アオ2
敗バイ4
敘シュ4
教ジャオ4
敚ドゥォ2
敛リィェン3
敜ニィェ4
敝ビー4
敞chang3
敟ディェン3
敠ドゥォ1
敡イー4
敢ガン3
散サン4
敤クェ゛ァ3
敥イェン4
敦ドゥン1
敧ジー1
敨トウ3
敩シァォ4
敪ドゥォ1
敫ジャオ3
敬ジン4
敭ヤン2
敮シァ2
敯ミン3
数シュ4
敱ェ゛ァ2
敲チィァォ1
敳ェ゛ァ2
整ヂォン3
敵デ2
敶ヂェン4
敷フー1
數シュ4
敹リィァォ2
敺チュ1
敻シィォン4
敼イー3
敽ジャオ3
敾シャン4
敿ジャオ3
斀ヂュオ2
斁イー4
斂リィェン3
斃ビー4
斄リー2
斅シァォ4
斆シァォ4
文ウェン2
斈シュェ2
斉チー2
斊チー2
斋ヂャイ1
斌ビン1
斍ジュェ2
斎ヂャイ1
斏ラン2
斐フェイ3
斑バン1
斒バン1
斓ラン2
斔ユー3
斕ラン2
斖ウェイ3
斗ドウ4
斘シォン1
料リィァォ4
斚ジャ3
斛フー2
斜シェ2
斝ジャ3
斞ユー3
斟ヂェン1
斠ジャオ4
斡ウォ4
斢ティァォ3
斣ドウ4
斤ジン1
斥チー4
斦イン2
斧フー3
斨チィァン1
斩ヂャン3
斪チュ2
斫ヂュオ2
斬ヂャン3
断ドゥァン4
斮ツゥォ4
斯スー1
新シン1
斱ヂュオ2
斲ヂュオ2
斳チン2
斴リン2
斵ヂュオ2
斶チュ4
斷ドゥァン4
斸ヂュ3
方ファン1
斺チャン3
斻ハン2
於ユー2
施シー1
斾ペイ4
斿ヨウ2
旀メイ4
旁パン2
旂チー2
旃ヂャン1
旄マオ2
旅リュ3
旆ペイ4
旇ピー1
旈リィゥ2
旉フー1
旊ファン3
旋シュェン2
旌ジン1
旍ジン1
旎ニー3
族ズー2
旐ヂャオ4
旑イー3
旒リィゥ2
旓シャオ1
旔ジィェン4
旕ユー2
旖イー3
旗チー2
旘ヂー4
旙ファン1
旚ピィァオ1
旛ファン1
旜ヂャン1
旝クァィ4
旞スェイ4
旟ユー2
无ウー2
旡ジー4
既ジー4
旣ジー4
旤フォ4
日リ゛ー4
旦ダン4
旧ジゥ4
旨ヂー3
早ザオ3
旪シェ2
旫ティァォ1
旬シュン2
旭シュ4
旮チィェ1
旯ラー2
旰ガン4
旱ハン4
旲タイ2
旳デ4
旴シュ1
旵チャン3
时シー2
旷クゥァン4
旸ヤン2
旹シー2
旺ワン4
旻ミン2
旼ミン2
旽トゥン1
旾チュン1
旿ウー3
昀ユン2
昁ベイ4
昂アン2
昃ゼェ゛ァ4
昄バン3
昅ジェ2
昆クン1
昇シォン1
昈フー4
昉ファン3
昊ハオ4
昋グゥェイ4
昌chang1
昍シュェン1
明ミン2
昏フン1
昐フェン1
昑チン3
昒フー1
易イー4
昔シー1
昕シン1
昖イェン2
昗ゼェ゛ァ4
昘ファン3
昙タン2
昚シェン4
昛ジュ4
昜ヤン2
昝ザン3
昞ビン3
星シン1
映イン4
昡シュェン4
昢ポォ4
昣ヂェン3
昤リン2
春チュン1
昦ハオ4
昧メイ4
昨ズゥォ2
昩ムー4
昪ビィェン4
昫シュ4
昬フン1
昭ヂャオ1
昮ゾン4
是シー4
昰シー4
昱ユー4
昲フェイ4
昳ディェ2
昴マオ3
昵ニー4
昶chang3
昷ウェン1
昸ドン1
昹ェ゛ァ3
昺ビン3
昻アン2
昼ヂョウ4
昽ロン2
显シィェン3
昿クゥァン4
晀ティァォ3
晁チャオ2
時シー2
晃フゥァン3
晄フゥァン3
晅シュェン3
晆クゥェイ2
晇シュ1
晈ジャオ3
晉ジン4
晊ヂー4
晋ジン4
晌シャン3
晍トン2
晎ホン3
晏イェン4
晐ガイ1
晑シィァン3
晒シャイ4
晓シァォ3
晔イェ4
晕ユン1
晖フゥェイ1
晗ハン2
晘ハン4
晙ジュン4
晚ワン3
晛シィェン4
晜クン1
晝ヂョウ4
晞シー1
晟チォン2
晠シォン4
晡ブー1
晢ヂェ゛ァ2
晣ヂェ゛ァ2
晤ウー4
晥ワン3
晦フゥェイ4
晧ハオ4
晨チェン2
晩ワン3
晪ティェン3
晫ヂュオ2
晬ズェイ4
晭ヂョウ3
普プー3
景ジン3
晰シー1
晱シャン3
晲ニー3
晳シー1
晴チン2
晵チー3
晶ジン1
晷グゥェイ3
晸ヂォン3
晹イー4
智ヂー4
晻アン4
晼ワン3
晽リン2
晾リィァン4
晿chang1
暀ワン3
暁シァォ3
暂ザン4
暃フェイ1
暄シュェン1
暅ゴン4
暆イー2
暇シァ2
暈ユン1
暉フゥェイ1
暊シュ3
暋ミン3
暌クゥェイ2
暍イェ1
暎イン4
暏シュ3
暐ウェイ3
暑シュ3
暒チン2
暓マオ4
暔ナン2
暕ジィェン3
暖ヌァン3
暗アン4
暘ヤン2
暙チュン1
暚イャォ2
暛スォ3
暜プー3
暝ミン2
暞ジャオ3
暟カイ3
暠ガオ3
暡ウォン3
暢chang4
暣チー4
暤ハオ4
暥イェン4
暦リー4
暧ェ゛ァ4
暨ジー4
暩ジー4
暪メン4
暫ザン4
暬シェ4
暭ハオ4
暮ムー4
暯ムー4
暰ツォン1
暱ニー4
暲ヂャン1
暳フゥェイ4
暴バオ4
暵ハン4
暶シュェン2
暷チュァン2
暸リィァォ2
暹シィェン1
暺タン3
暻ジン3
暼ピェ1
暽リン2
暾トゥン1
暿シー3
曀イー4
曁ジー4
曂フゥァン4
曃ダイ4
曄イェ4
曅イェ4
曆リー4
曇タン2
曈トン2
曉シァォ3
曊フェイ4
曋シェン3
曌ヂャオ4
曍ハオ4
曎イー4
曏シィァン3
曐シン1
曑シェン1
曒ジャオ3
曓バオ4
曔ジン4
曕イェン4
曖ェ゛ァ4
曗イェ4
曘ルー2
曙シュ3
曚モン2
曛シュン1
曜イャォ4
曝プー4
曞リー4
曟チェン2
曠クゥァン4
曡ディェ2
曢リィァォ3
曣イェン4
曤フォ4
曥ルー2
曦シー1
曧ロン2
曨ロン2
曩ナン3
曪ルゥォ3
曫ルゥァン2
曬シャイ4
曭タン3
曮イェン3
曯ヂュ2
曰ユェ1
曱ユェ1
曲チュ1
曳イェ4
更ゴン4
曵イェ4
曶フー1
曷フェ゛ァ2
書シュ1
曹ツァォ2
曺ツァォ2
曻シォン1
曼マン4
曽ツォン1
曾ツォン2
替ティ4
最ズェイ4
朁ツァン3
朂シュ4
會フゥェイ4
朄イン3
朅チィェ4
朆フェン1
朇ピー2
月ユェ4
有ヨウ3
朊ルァン3
朋ポン2
朌フェン2
服フー2
朎リン2
朏フェイ3
朐チュ2
朑ティ4
朒ニュ4
朓ティァォ3
朔シュォ4
朕ヂェン4
朖ラン3
朗ラン3
朘ズェイ1
朙ミン2
朚フゥァン1
望ワン4
朜トゥン1
朝チャオ2
朞ジー1
期チー1
朠イン1
朡ゾン1
朢ワン4
朣トン2
朤ラン3
朥ラオ2
朦モン2
朧ロン2
木ムー4
朩ドン3
未ウェイ4
末ムー4
本ベン3
札ヂャ2
朮シュ4
术シュ4
朰ムー4
朱ヂュ1
朲レン2
朳バー1
朴プー3
朵ドゥォ3
朶ドゥォ3
朷ダオ1
朸リー4
朹グゥェイ3
机ジー1
朻ジゥ1
朼ビー3
朽シゥ3
朾チォン2
朿ツー4
杀シャ1
杁ルー4
杂ザー2
权チュェン2
杄チィェン1
杅ユー2
杆ガン1
杇ウー1
杈チャ1
杉シャン1
杊シュン2
杋ファン2
杌ウー4
杍ズー3
李リー3
杏シン4
材ツァィ2
村ツン1
杒レン4
杓ビィャォ1
杔トゥォ1
杕デ4
杖ヂャン4
杗マン2
杘チー4
杙イー4
杚ガイ4
杛ゴン1
杜ドゥ4
杝リー2
杞チー3
束シュ4
杠ガン1
条ティァォ2
杢ジィァン
杣ミィェン2
杤ワン4
来ライ2
杦ジゥ3
杧マン2
杨ヤン2
杩マー4
杪ミィァォ3
杫スー4
杬ユェン2
杭ハン2
杮フェイ4
杯ベイ1
杰ジェ2
東ドン1
杲ガオ3
杳イャォ3
杴シィェン1
杵チュ3
杶チュン1
杷パー2
杸シュ1
杹ファ4
杺シン1
杻チョウ3
杼ヂュ4
杽チョウ3
松ソン1
板バン3
枀ソン1
极ジー2
枂ウォ4
枃ジン4
构ゴウ4
枅ジー1
枆マオ2
枇ピー2
枈ビー4
枉ワン3
枊アン4
枋ファン1
枌フェン2
枍イー4
枎フー2
枏ナン2
析シー1
枑フー4
枒ヤー1
枓ドウ3
枔シン2
枕ヂェン3
枖イャォ1
林リン2
枘ルェイ4
枙ェ゛ァ3
枚メイ2
枛ヂャオ4
果グゥォ3
枝ヂー1
枞ツォン1
枟ユン4
枠ズェイ
枡シォン1
枢シュ1
枣ザオ3
枤デ4
枥リー4
枦ルー2
枧ジィェン3
枨チォン2
枩ソン1
枪チィァン1
枫フォン1
枬ヂャン1
枭シァォ1
枮シィェン1
枯クー1
枰ピン2
枱タイ2
枲シー3
枳ヂー3
枴グゥァイ3
枵シァォ1
架ジャ4
枷ジャ1
枸ゴウ3
枹バオ1
枺ムー4
枻イー4
枼イェ4
枽イェ4
枾シー4
枿ニィェ4
柀ビー3
柁ドゥォ4
柂イー2
柃リン2
柄ビン3
柅ニー3
柆ラー1
柇フェ゛ァ2
柈バン4
柉ファン2
柊ヂョン1
柋ダイ4
柌ツー2
柍ヤン3
柎フー1
柏バイ3
某モウ3
柑ガン1
柒チー1
染ラン3
柔ロウ2
柕マオ4
柖シャオ2
柗ソン1
柘ヂェ゛ァ4
柙シァ2
柚ヨウ4
柛シェン1
柜グゥェイ4
柝トゥォ4
柞ヂャ4
柟ナン2
柠ニン2
柡ヨン3
柢デ3
柣ヂー4
柤ヂャ1
查チャ2
柦ダン4
柧グー1
柨ブー4
柩ジゥ4
柪アオ1
柫フー2
柬ジィェン3
柭バー1
柮ドゥォ4
柯クェ゛ァ1
柰ナイ4
柱ヂュ4
柲ビー4
柳リィゥ3
柴チャイ2
柵シャン1
柶スー4
柷チュ4
柸ペイ1
柹シー4
柺グゥァイ3
査ヂャ1
柼イャォ3
柽チォン1
柾ジゥ4
柿シー4
栀ヂー1
栁リィゥ3
栂メイ2
栃リー4
栄ロン2
栅ヂャ4
栆ザオ3
标ビィャォ1
栈ヂャン4
栉ヂー4
栊ロン2
栋ドン4
栌ルー2
栍シォン1
栎リー4
栏ラン2
栐ヨン3
树シュ4
栒シュン2
栓シュァン1
栔チー4
栕ヂェン1
栖チー1
栗リー4
栘イー2
栙シィァン2
栚ヂェン4
栛リー4
栜スェ゛ァ4
栝グァ1
栞カン1
栟ベン1
栠レン3
校シァォ4
栢バイ3
栣レン3
栤ビン4
栥ズー1
栦チョウ2
栧イー4
栨ツー4
栩シュ3
株ヂュ1
栫ジィェン4
栬ズェイ4
栭ェ゛ァー2
栮ェ゛ァー3
栯ヨウ3
栰ファ2
栱ゴン3
栲カオ3
栳ラオ3
栴ヂャン1
栵リィェ4
栶イン1
样ヤン4
核フェ゛ァ2
根ゲン1
栺イー4
栻シー4
格グェ゛ァ2
栽ザイ1
栾ルゥァン2
栿フー2
桀ジェ2
桁ホン2
桂グゥェイ4
桃タオ2
桄グゥァン1
桅ウェイ2
框クゥァン1
桇ルー2
案アン4
桉アン1
桊ジュェン4
桋イー2
桌ヂュオ1
桍クー1
桎ヂー4
桏チォン2
桐トン2
桑サン1
桒サン1
桓ファン2
桔ジュ2
桕ジゥ4
桖シュェ4
桗ドゥォ4
桘ヂュェイ4
桙ユー2
桚ザン3
桜イン1
桝ジェ2
桞リィゥ3
桟ヂャン4
桠ヤー1
桡ラオ2
桢ヂェン1
档ダン4
桤チー1
桥チィァォ2
桦ファ4
桧グゥェイ4
桨ジィァン3
桩ヂュゥァン1
桪シュン2
桫スォ1
桬シャ1
桭ヂェン1
桮ベイ1
桯ティン1
桰クォ4
桱ジン4
桲ポォ
桳ベン4
桴フー2
桵ルェイ2
桶トン3
桷ジュェ2
桸シー1
桹ラン2
桺リィゥ3
桻フォン1
桼チー1
桽ウェン3
桾ジュン1
桿ガン3
梀スー4
梁リィァン2
梂チィゥ2
梃ティン3
梄ヨウ3
梅メイ2
梆バン1
梇ロン4
梈ポン1
梉ヂュゥァン1
梊デ4
梋シュェン1
梌トゥ2
梍ザオ4
梎アオ1
梏グー4
梐ビー4
梑デ2
梒ハン2
梓ズー3
梔ヂー1
梕レン4
梖ベイ4
梗ゴン3
梘ジィェン3
梙ファン4
梚ワン3
梛ヌオ2
梜ジャ1
條ティァォ2
梞ジー4
梟シァォ1
梠リュ3
梡フン2
梢シャオ1
梣cen2
梤フェン2
梥ソン1
梦モン4
梧ウー2
梨リー2
梩リー2
梪ドウ4
梫チン3
梬イン3
梭スォ1
梮ジュ1
梯ティ1
械シェ4
梱クン3
梲ヂュオ2
梳シュ1
梴チャン1
梵ファン4
梶ウェイ3
梷ジン4
梸リー2
梹ビン1
梺シァ4
梻fo2
梼タオ2
梽ヂー4
梾ライ2
梿リィェン2
检ジィェン3
棁ヂュオ1
棂リン2
棃リー2
棄チー4
棅ビン3
棆ルゥン2
棇ツォン1
棈チィェン4
棉ミィェン2
棊チー2
棋チー2
棌ツァィ4
棍ガン4
棎チャン2
棏ディ2
棐フェイ3
棑パイ2
棒バン4
棓バン4
棔フン1
棕ゾン1
棖チォン2
棗ザオ3
棘ジー2
棙リー4
棚ポン2
棛ユー4
棜ユー4
棝グー4
棞ジュン4
棟ドン4
棠タン2
棡ガン1
棢ワン3
棣デ4
棤ツゥォ4
棥ファン2
棦チォン1
棧ヂャン4
棨チー3
棩ユェン1
棪イェン3
棫ユー4
棬チュェン1
棭イー4
森セン1
棯レン3
棰チュェイ2
棱ラン2
棲チー1
棳ヂュオ1
棴フー2
棵クェ゛ァ1
棶ライ2
棷ゾウ1
棸ゾウ1
棹ヂャオ4
棺グァン1
棻フェン1
棼フェン2
棽シェン1
棾チン2
棿ニー2
椀ワン3
椁グゥォ3
椂ルー4
椃ハオ2
椄ジェ1
椅イー3
椆チョウ2
椇ジュ3
椈ジュ2
椉チォン2
椊ズゥォ2
椋リィァン2
椌チィァン1
植ヂー2
椎チュェイ2
椏ヤー1
椐ジュ1
椑ベイ1
椒ジャオ1
椓ヂュオ2
椔ズー1
椕ビン1
椖ポン2
椗ディン4
椘チュ3
椙chang1
椚メン1
椛ファ1
検ジィェン3
椝グゥェイ1
椞シー4
椟ドゥ2
椠チィェン4
椡ダオ4
椢グゥェイ4
椣ディェン3
椤ルゥォ2
椥ヂー1
椦チュェン
椧ミン4
椨フー3
椩ゴン1
椪ポン4
椫シャン4
椬イー2
椭トゥォ3
椮セン1
椯ドゥォ3
椰イェ1
椱フー4
椲ウェイ3
椳ウェイ1
椴ドゥァン4
椵ジャ3
椶ゾン1
椷ジィェン1
椸イー2
椹シェン4
椺シー2
椻イェン4
椼イェン3
椽チュァン2
椾ジィェン1
椿チュン1
楀ユー3
楁フェ゛ァ2
楂ヂャ1
楃ウォ4
楄ピィェン2
楅ビー1
楆イャォ1
楇フォ4
楈シュ1
楉ルォ4
楊ヤン2
楋ラー4
楌イェン2
楍ベン3
楎フゥェイ1
楏クゥェイ2
楐ジェ4
楑クゥェイ2
楒スー1
楓フォン1
楔シェ1
楕トゥォ3
楖ヂー4
楗ジィェン4
楘ムー4
楙マオ4
楚チュ3
楛フー4
楜フー2
楝リィェン4
楞ラン2
楟ティン2
楠ナン2
楡ユー2
楢ヨウ2
楣メイ2
楤ソン3
楥シュェン4
楦シュェン4
楧ヤン3
楨ヂェン1
楩ピィェン2
楪イェ4
楫ジー2
楬ジェ2
業イェ4
楮チュ3
楯ドゥン4
楰ユー2
楱ゾウ4
楲ウェイ1
楳メイ2
楴ティ4
極ジー2
楶ジェ2
楷カイ3
楸チィゥ1
楹イン2
楺ロウ3
楻フゥァン2
楼ロウ2
楽ラ4
楾チュェン2
楿シィァン1
榀ピン3
榁シー3
概ガイ4
榃タン2
榄ラン3
榅ウェン1
榆ユー2
榇チェン4
榈リュ2
榉ジュ3
榊シェン2
榋チュ
榌ビー1
榍シェ4
榎ジャ3
榏イー4
榐ヂャン3
榑フー2
榒ヌオ4
榓ミィ4
榔ラン2
榕ロン2
榖グー3
榗ジィェン4
榘ジュ3
榙ター1
榚イャォ3
榛ヂェン1
榜バン3
榝シャ1
榞ユェン2
榟ズー3
榠ミン2
榡スー4
榢ジャ4
榣イャォ2
榤ジェ2
榥フゥァン4
榦ガン4
榧フェイ3
榨ヂャ4
榩チィェン2
榪マー4
榫スン3
榬ユェン2
榭シェ4
榮ロン2
榯シー2
榰ヂー1
榱ツェイ1
榲ウェン1
榳ティン2
榴リィゥ2
榵ロン2
榶タン2
榷チュェ4
榸ヂャイ1
榹スー1
榺シォン4
榻ター4
榼クェ゛ァ1
榽シー1
榾グー3
榿チー1
槀ガオ3
槁ガオ3
槂スン1
槃パン2
槄タオ1
槅グェ゛ァ2
槆チュン1
槇ディェン1
槈ノウ4
槉ジー2
槊シュォ4
構ゴウ4
槌チュェイ2
槍チィァン1
槎チャ2
槏チィェン3
槐フゥァイ2
槑メイ2
槒シュ4
槓ガン4
槔ガオ1
槕ヂュオ1
槖トゥォ2
槗チィァォ2
様ヤン4
槙ディェン1
槚ジャ3
槛カン3
槜ズェイ4
槝ダオ3
槞ロン2
槟ビン1
槠ヂュ1
槡サン1
槢シー2
槣ジー1
槤リィェン2
槥フゥェイ4
槦ヨン1
槧チィェン4
槨グゥォ3
槩ガイ4
槪ガイ4
槫トゥァン2
槬ファ4
槭チー1
槮セン1
槯ツェイ1
槰ポン2
槱ヨウ3
槲フー2
槳ジィァン3
槴フー4
槵ファン4
槶グゥェイ4
槷ニィェ4
槸イー4
槹ガオ1
槺カン1
槻グゥェイ1
槼グゥェイ1
槽ツァォ2
槾マン4
槿ジン3
樀デ2
樁ヂュゥァン1
樂ラ4
樃ラン3
樄チェン2
樅ツォン1
樆リー2
樇シゥ1
樈チン2
樉シュゥァン3
樊ファン2
樋トン1
樌グァン4
樍ゼェ゛ァ2
樎スー4
樏レイ3
樐ルー3
樑リィァン2
樒ミィ4
樓ロウ2
樔チャオ2
樕スー4
樖クェ゛ァ1
樗チュ1
樘タン2
標ビィャォ1
樚ルー4
樛ジゥ1
樜ヂェ゛ァ4
樝ヂャ1
樞シュ1
樟ヂャン1
樠マン2
模ムー2
樢ニィァォ3
樣ヤン4
樤ティァォ2
樥ポン2
樦ヂュ4
樧シャ1
樨シー1
権チュェン2
横ホン2
樫ジィェン1
樬ツォン1
樭ジー1
樮イェン1
樯チィァン2
樰シュェ3
樱イン1
樲ェ゛ァー4
樳シュン2
樴ヂー2
樵チィァォ2
樶ズェイ1
樷ツォン2
樸プー3
樹シュ4
樺ファ4
樻クゥェイ4
樼ヂェン1
樽ズン1
樾ユェ4
樿シャン4
橀シー1
橁チュン1
橂ディェン4
橃ファ2
橄ガン3
橅ムー2
橆ウー3
橇チィァォ1
橈ラオ2
橉リン4
橊リィゥ2
橋チィァォ2
橌シィェン4
橍ルン4
橎ファン2
橏ヂャン3
橐トゥォ2
橑ラオ3
橒ユン2
橓シュン4
橔ドゥン1
橕チォン1
橖タン2
橗モン2
橘ジュ2
橙チォン2
橚スー4
橛ジュェ2
橜ジュェ2
橝ディェン4
橞フゥェイ4
機ジー1
橠ヌオ3
橡シィァン4
橢トゥォ3
橣ニン3
橤ルェイ3
橥ヂュ1
橦トン2
橧ゾン1
橨フェン2
橩チォン2
橪ラン3
橫ホン2
橬チィェン2
橭グー1
橮リィゥ3
橯ラオ4
橰ガオ1
橱チュ2
橲シー3
橳シォン4
橴ズー3
橵サン
橶ジー2
橷ドウ1
橸ジン1
橹ルー3
橺ジィェン
橻チュ
橼ユェン2
橽ター4
橾シュ1
橿ジィァン1
檀タン2
檁リン3
檂ノン2
檃イン3
檄シー2
檅フゥェイ4
檆シャン1
檇ズェイ4
檈シュェン2
檉チォン1
檊ガン4
檋ジュ2
檌ズェイ4
檍イー4
檎チン2
檏プー3
檐イェン2
檑レイ2
檒フォン1
檓フゥェイ3
檔ダン4
檕ジー4
檖スェイ4
檗ブォ4
檘ピン2
檙チォン2
檚チュ3
檛ヂュア1
檜グゥェイ4
檝ジー2
檞ジェ3
檟ジャ3
檠チン2
檡ヂャイ2
檢ジィェン3
檣チィァン2
檤ダオ4
檥イー3
檦ビィャォ3
檧ソン1
檨シェ゛ァ1
檩リン3
檪リー4
檫チャ2
檬モン2
檭イン2
檮タオ2
檯タイ2
檰ミィェン2
檱チー2
檲トゥァン2
檳ビン1
檴フォ4
檵ジー4
檶チィェン1
檷ニー3
檸ニン2
檹イー1
檺ガオ3
檻カン3
檼イン4
檽ノウ4
檾チン3
檿イェン3
櫀チー2
櫁ミィ4
櫂ヂャオ4
櫃グゥェイ4
櫄チュン1
櫅ジー1
櫆クゥェイ2
櫇ポォ2
櫈ドン4
櫉チュ2
櫊グェ゛ァ2
櫋ミィェン2
櫌ヨウ1
櫍ヂー4
櫎フゥァン3
櫏チィェン1
櫐レイ3
櫑レイ2
櫒サー4
櫓ルー3
櫔リー4
櫕ツァン2
櫖リュ4
櫗ミィェ4
櫘フゥェイ4
櫙オウ1
櫚リュ2
櫛ヂー4
櫜ガオ1
櫝ドゥ2
櫞ユェン2
櫟リー4
櫠フェイ4
櫡ヂュオ2
櫢ソウ3
櫣リィェン2
櫤ジィァン4
櫥チュ2
櫦チン4
櫧ヂュ1
櫨ルー2
櫩イェン2
櫪リー4
櫫ヂュ1
櫬チェン4
櫭ジェ2
櫮ェ゛ァ4
櫯スー1
櫰フゥァイ2
櫱ニィェ4
櫲ユー4
櫳ロン2
櫴ライ4
櫵ジャオ
櫶シィェン3
櫷グゥェイ1
櫸ジュ3
櫹シァォ1
櫺リン2
櫻イン1
櫼ジィェン1
櫽イン3
櫾ヨウ2
櫿イン2
欀シィァン1
欁ノン2
欂ブォ2
欃チャン2
欄ラン2
欅ジュ3
欆シュゥァン1
欇シェ゛ァ4
欈ウェイ2
欉ツォン2
權チュェン2
欋チュ2
欌ツァン2
欍ジゥ4
欎ユー4
欏ルゥォ2
欐リー4
欑ツァン2
欒ルゥァン2
欓ダン3
欔ジュェ2
欕イェン2
欖ラン3
欗ラン2
欘ヂュ2
欙レイ2
欚リー3
欛バー4
欜ナン2
欝ユー4
欞リン2
欟グゥァン
欠チィェン4
次ツー4
欢ファン1
欣シン1
欤ユー2
欥イー4
欦チィェン1
欧オウ1
欨シュ1
欩チャオ1
欪チュ4
欫チー4
欬カイ4
欭イー4
欮ジュェ2
欯シー4
欰シュ4
欱フェ゛ァ1
欲ユー4
欳クゥェイ4
欴ラン2
欵クァン3
欶シュォ4
欷シー1
欸ェ゛ァ1
欹イー1
欺チー1
欻chua1
欼チー3
欽チン1
款クァン3
欿カン3
歀クァン3
歁カン3
歂チュァン3
歃シャ4
歄グァ1
歅イン1
歆シン1
歇シェ1
歈ユー2
歉チィェン4
歊シァォ1
歋イェ4
歌グェ゛ァ1
歍ウー1
歎タン4
歏ジン4
歐オウ1
歑フー1
歒ティ4
歓ファン1
歔シュ1
歕ペン1
歖シー3
歗シァォ4
歘chua1
歙シェ゛ァ4
歚シャン4
歛ハン1
歜チュ4
歝イー4
歞ェ゛ァ4
歟ユー2
歠チュォ4
歡ファン1
止ヂー3
正ヂォン4
此ツー3
步ブー4
武ウー3
歧チー2
歨ブー4
歩ブー4
歪ワィ1
歫ジュ4
歬チィェン2
歭チー2
歮スェ゛ァ4
歯チー3
歰スェ゛ァ4
歱ヂョン3
歲スェイ4
歳スェイ4
歴リー4
歵ゼェ゛ァ2
歶ユー2
歷リー4
歸グゥェイ1
歹ダイ3
歺ェ゛ァ4
死スー3
歼ジィェン1
歽ヂェ゛ァ2
歾ムー4
歿ムー4
殀イャォ1
殁ムー4
殂ツゥ2
殃ヤン1
殄ティェン3
殅シォン1
殆ダイ4
殇シャン1
殈シュ4
殉シュン4
殊シュ1
残ツァン2
殌ジュェ2
殍ピィァオ3
殎チィァ4
殏チィゥ2
殐スー4
殑チン2
殒ユン3
殓リィェン4
殔イー4
殕フォウ3
殖ヂー2
殗イェ4
殘ツァン2
殙フン1
殚ダン1
殛ジー2
殜ディェ2
殝ヂェン1
殞ユン3
殟ウェン1
殠チョウ4
殡ビン4
殢ティ4
殣ジン4
殤シャン1
殥イン2
殦ディァォ1
殧ジゥ4
殨フゥェイ4
殩ツァン4
殪イー4
殫ダン1
殬ドゥ4
殭ジィァン1
殮リィェン4
殯ビン4
殰ドゥ2
殱ジィェン1
殲ジィェン1
殳シュ1
殴オウ1
段ドゥァン4
殶ヂュ4
殷イン1
殸チン4
殹イー4
殺シャ1
殻チィァォ4
殼クェ゛ァ2
殽シァォ2
殾シュン4
殿ディェン4
毀フゥェイ3
毁フゥェイ3
毂グー3
毃チィァォ1
毄ジー1
毅イー4
毆オウ1
毇フゥェイ3
毈ドゥァン4
毉イー1
毊シァォ1
毋ウー2
毌グァン4
母ムー3
毎メイ3
每メイ3
毐ェ゛ァ3
毑ジェ3
毒ドゥ2
毓ユー4
比ビー3
毕ビー4
毖ビー4
毗ピー2
毘ピー2
毙ビー4
毚チャン2
毛マオ2
毜ハオ2
毝ツァィ3
毞ピー2
毟リィェ3
毠ジャ1
毡ヂャン1
毢サイ1
毣ムー4
毤トゥォ4
毥シュン2
毦ェ゛ァー3
毧ロン2
毨シィェン3
毩ジュ2
毪ムー2
毫ハオ2
毬チィゥ2
毭ドウ4
毮シャ1
毯タン3
毰ペイ2
毱ジュ2
毲ドゥォ1
毳ツェイ4
毴ビー1
毵サン1
毶サン1
毷マオ4
毸サイ1
毹シュ1
毺シュ1
毻トゥォ4
毼フェ゛ァ2
毽ジィェン4
毾ター4
毿サン1
氀リュ2
氁ムー2
氂マオ2
氃トン2
氄ロン3
氅chang3
氆プー3
氇ルー
氈ヂャン1
氉サオ4
氊ヂャン1
氋モン2
氌ルー3
氍チュ2
氎ディェ2
氏シー4
氐デ1
民ミン2
氒ジュェ2
氓マン2
气チー4
氕ピェ1
氖ナイ3
気チー4
氘ダオ1
氙シィェン1
氚チュァン1
氛フェン1
氜ヤン2
氝ネイ4
氞ビン
氟フー2
氠シェン1
氡ドン1
氢チン1
氣チー4
氤イン1
氥シー1
氦ファン4
氧ヤン3
氨アン1
氩ヤー4
氪クェ゛ァ4
氫チン1
氬ヤー4
氭ドン1
氮ダン4
氯リュ4
氰チン2
氱ヤン3
氲ユン1
氳ユン1
水シュェイ3
氵シュェイ
氶ヂォン3
氷ビン1
永ヨン3
氹ダン4
氺シュェイ3
氻ラ4
氼ニー4
氽トゥン3
氾ファン4
氿グゥェイ3
汀ティン1
汁ヂー1
求チィゥ2
汃ビン1
汄ゼェ゛ァ4
汅ミィェン3
汆ツァン1
汇フゥェイ4
汈ディァォ1
汉ハン4
汊チャ4
汋ヂュオ2
汌チュァン4
汍ワン2
汎ファン4
汏ダー4
汐シー1
汑トゥォ1
汒マン2
汓チィゥ2
汔チー4
汕シャン4
汖ピン4
汗ハン4
汘チィェン1
汙ウー1
汚ウー1
汛シュン4
汜スー4
汝ルー3
汞ゴン3
江ジィァン1
池チー2
污ウー1
汢トゥ
汣ジゥ3
汤タン1
汥ヂー1
汦ヂー3
汧チィェン1
汨ミィ4
汩グー3
汪ワン1
汫ジン3
汬ジン3
汭ルェイ4
汮ジュン1
汯ホン2
汰タイ4
汱チュェン3
汲ジー2
汳ビィェン4
汴ビィェン4
汵ガン4
汶ウェン4
汷ヂョン1
汸ファン1
汹シィォン1
決ジュェ2
汻フー3
汼ニィゥ2
汽チー4
汾フェン2
汿シュ4
沀シュ4
沁チン4
沂イー2
沃ウォ4
沄ユン2
沅ユェン2
沆ハン4
沇イェン3
沈シェン3
沉チェン2
沊ダン4
沋ヨウ2
沌ドゥン4
沍フー4
沎フォ4
沏チー1
沐ムー4
沑ニュ4
沒メイ2
沓ダー2
沔ミィェン3
沕ミィ4
沖チョン1
沗パン1
沘ビー3
沙シャ1
沚ヂー3
沛ペイ4
沜パン4
沝ヂュェイ3
沞ザー1
沟ゴウ1
沠リィゥ2
没メイ2
沢ゼェ゛ァ2
沣フォン1
沤オウ1
沥リー4
沦ルゥン2
沧ツァン1
沨フォン1
沩ウェイ2
沪フー4
沫ムー4
沬メイ4
沭シュ4
沮ジュ3
沯ザー2
沰トゥォ1
沱トゥォ2
沲トゥォ2
河フェ゛ァ2
沴リー4
沵ミィ3
沶イー2
沷ファ1
沸フェイ4
油ヨウ2
沺ティェン2
治ヂー4
沼ヂャオ3
沽グー1
沾ヂャン1
沿イェン2
泀スー1
況クゥァン4
泂ジィォン3
泃ジュ1
泄シェ4
泅チィゥ2
泆イー4
泇ジャ1
泈ヂョン1
泉チュェン2
泊ポォ1
泋フゥェイ4
泌ミィ4
泍ベン1
泎ゼェ゛ァ2
泏ヂュ2
泐ラ4
泑ヨウ1
泒グー1
泓ホン2
泔ガン1
法ファ3
泖マオ3
泗スー4
泘フー1
泙ピン2
泚ツー3
泛ファン4
泜ヂー1
泝スー4
泞ニン4
泟チォン1
泠リン2
泡パオ4
波ブォ1
泣チー4
泤スー4
泥ニー2
泦ジュ2
泧サー4
注ヂュ4
泩シォン1
泪レイ4
泫シュェン4
泬ジュェ2
泭フー2
泮パン4
泯ミン3
泰タイ4
泱ヤン1
泲ジー3
泳ヨン3
泴グァン4
泵ボン4
泶シュェ2
泷ロン2
泸ルー2
泹ダン4
泺ルゥォ4
泻シェ4
泼ポォ1
泽ゼェ゛ァ2
泾ジン1
泿イン2
洀パン2
洁ジェ2
洂イェ4
洃フゥェイ1
洄フゥェイ2
洅ザイ4
洆チォン2
洇イン1
洈ウェイ2
洉ホウ4
洊ジィェン4
洋ヤン2
洌リィェ4
洍スー4
洎ジー4
洏ェ゛ァー2
洐シン2
洑フー2
洒サー3
洓スェ゛ァ4
洔ヂー3
洕イン4
洖ウー2
洗シー3
洘カオ3
洙ヂュ1
洚ジィァン4
洛ルゥォ4
洜ルゥォ4
洝アン4
洞ドン4
洟ティ4
洠モウ2
洡レイ4
洢イー1
洣ミィ3
洤チュェン2
津ジン1
洦ポォ4
洧ウェイ3
洨シァォ2
洩シェ4
洪ホン2
洫シュ4
洬スー4
洭クゥァン1
洮タオ2
洯チィェ4
洰ジュ4
洱ェ゛ァー3
洲ヂョウ1
洳ルー4
洴ピン2
洵シュン2
洶シィォン1
洷ヂー4
洸グゥァン1
洹ファン2
洺ミン2
活フォ2
洼ウァ1
洽チィァ4
派パイ4
洿ウー1
浀チュ1
流リィゥ2
浂イー4
浃ジャ1
浄ジン4
浅チィェン3
浆ジィァン1
浇ジャオ1
浈ヂェン1
浉シー1
浊ヂュオ2
测ツェ゛ァ4
浌ファ2
浍フゥェイ4
济ジー4
浏リィゥ2
浐チャン3
浑フン2
浒フー3
浓ノン2
浔シュン2
浕ジン4
浖リィェ4
浗チィゥ2
浘ウェイ3
浙ヂェ゛ァ4
浚ジュン4
浛ハン2
浜バン1
浝マン2
浞ヂュオ2
浟ヨウ2
浠シー1
浡ブォ2
浢ドウ4
浣ファン4
浤ホン2
浥イー4
浦プー3
浧イン3
浨ラン3
浩ハオ4
浪ラン4
浫ハン3
浬リー3
浭ゴン1
浮フー2
浯ウー2
浰リィェン4
浱チュン2
浲フォン2
浳イー4
浴ユー4
浵トン2
浶ラオ2
海ファン3
浸ジン4
浹ジャ1
浺チョン1
浻ジィォン3
浼メイ3
浽スェイ1
浾チォン1
浿ペイ4
涀シィェン4
涁シェン4
涂トゥ2
涃クン4
涄ピン1
涅ニィェ4
涆ハン4
涇ジン1
消シァォ1
涉シェ゛ァ4
涊ニィェン3
涋トゥ1
涌ヨン3
涍シァォ4
涎シィェン2
涏ティン3
涐ェ゛ァ2
涑スー4
涒トゥン1
涓ジュェン1
涔cen2
涕ティ4
涖リー4
涗シュェイ4
涘スー4
涙レイ4
涚シュェイ4
涛タオ1
涜ドゥ2
涝ラオ4
涞ライ2
涟リィェン2
涠ウェイ2
涡ウォ1
涢ユン2
涣ファン4
涤デ2
涥ホン1
润ルン4
涧ジィェン4
涨ヂャン3
涩スェ゛ァ4
涪フー2
涫グァン4
涬シン4
涭ショウ4
涮シュァン4
涯ヤー2
涰チュォ4
涱ヂャン4
液イェ4
涳コン1
涴ウォ4
涵ハン2
涶トゥォ1
涷ドン1
涸フェ゛ァ2
涹ウォ1
涺ジュ1
涻シェ゛ァ4
涼リィァン2
涽フン1
涾ター4
涿ヂュオ1
淀ディェン4
淁チィェ4
淂ディ2
淃ジュェン4
淄ズー1
淅シー1
淆シァォ2
淇チー2
淈グー3
淉グゥォ3
淊イェン1
淋リン2
淌タン3
淍ヂョウ1
淎ポン3
淏ハオ4
淐chang1
淑シュ1
淒チー1
淓ファン1
淔ヂー2
淕ルー4
淖ナオ4
淗ジュ2
淘タオ2
淙ツォン2
淚レイ4
淛ヂェ゛ァ4
淜ピン2
淝フェイ2
淞ソン1
淟ティェン3
淠ピー4
淡ダン4
淢ユー4
淣ニー2
淤ユー1
淥ルー4
淦ガン4
淧ミィ4
淨ジン4
淩リン2
淪ルゥン2
淫イン2
淬ツェイ4
淭チュ2
淮フゥァイ2
淯ユー4
淰ニィェン3
深シェン1
淲ビィャォ1
淳チュン2
淴フー1
淵ユェン1
淶ライ2
混フン4
淸チン1
淹イェン1
淺チィェン3
添ティェン1
淼ミィァォ3
淽ヂー3
淾イン3
淿ブォ2
渀ベン4
渁ユェン1
渂ウェン4
渃ルォ4
渄フェイ1
清チン1
渆ユェン1
渇クェ゛ァ3
済ジー4
渉シェ゛ァ4
渊ユェン1
渋スェ゛ァ4
渌ルー4
渍ズー4
渎ドゥ2
渏イー1
渐ジィェン4
渑ミィェン3
渒パイ4
渓シー1
渔ユー2
渕ユェン1
渖シェン3
渗シェン4
渘ロウ2
渙ファン4
渚ヂュ3
減ジィェン3
渜ヌァン3
渝ユー2
渞チィゥ2
渟ティン2
渠チュ2
渡ドゥ4
渢ファン2
渣ヂャ1
渤ブォ2
渥ウォ4
渦ウォ1
渧デ4
渨ウェイ1
温ウェン1
渪ルー2
渫シェ4
測ツェ゛ァ4
渭ウェイ4
渮フェ゛ァ2
港ガン3
渰イェン3
渱ホン2
渲シュェン4
渳ミィ3
渴クェ゛ァ3
渵マオ2
渶イン1
渷イェン3
游ヨウ2
渹ホン1
渺ミィァォ3
渻シォン3
渼メイ3
渽ザイ1
渾フン2
渿ナイ4
湀グゥェイ3
湁チー4
湂ェ゛ァ4
湃パイ4
湄メイ2
湅リィェン4
湆チー4
湇チー4
湈メイ2
湉ティェン2
湊コゥ4
湋ウェイ2
湌ツァン1
湍トゥァン1
湎ミィェン3
湏フゥェイ4
湐ムー4
湑シュ1
湒ジー2
湓ペン2
湔ジィェン1
湕ジィェン3
湖フー2
湗フォン4
湘シィァン1
湙イー4
湚イン4
湛ヂャン4
湜シー2
湝ジェ1
湞ヂェン1
湟フゥァン2
湠タン4
湡ユー2
湢ビー4
湣ミン3
湤シー1
湥トゥ1
湦シォン1
湧ヨン3
湨ジュ2
湩ドン4
湪トゥァン4
湫ジャオ3
湬ジャオ3
湭チィゥ2
湮イェン1
湯タン1
湰ロン2
湱フォ4
湲ユェン2
湳ナン3
湴バン4
湵ヨウ3
湶チュェン2
湷ヂュゥァン1
湸リィァン4
湹チャン2
湺シィェン2
湻チュン2
湼ニィェ4
湽ズー1
湾ワン1
湿シー1
満マン3
溁イン2
溂ラー4
溃クゥェイ4
溄フォン2
溅ジィェン4
溆シュ4
溇ロウ2
溈ウェイ2
溉ガイ4
溊ブォ1
溋イン2
溌ポォ1
溍ジン4
溎イェン4
溏タン2
源ユェン2
溑スォ3
溒ユェン2
溓リィェン2
溔イャォ3
溕モン2
準ヂュン3
溗チォン2
溘クェ゛ァ4
溙タイ4
溚ター3
溛ウァ1
溜リィゥ1
溝ゴウ1
溞サオ1
溟ミン2
溠ヂャ4
溡シー2
溢イー4
溣ルゥン4
溤マー3
溥プー3
溦ウェイ1
溧リー4
溨ザイ1
溩ウー4
溪シー1
溫ウェン1
溬チィァン1
溭ゼェ゛ァ2
溮シー1
溯スー4
溰ェ゛ァ2
溱チン2
溲ソウ1
溳ユン2
溴シゥ4
溵イン1
溶ロン2
溷フン4
溸スー4
溹スォ4
溺ニー4
溻ター1
溼シー1
溽ルー4
溾ェ゛ァ1
溿パン4
滀チュ4
滁チュ2
滂パン1
滃ウォン1
滄ツァン1
滅ミィェ4
滆グェ゛ァ2
滇ディェン1
滈ハオ4
滉フゥァン4
滊シー4
滋ズー1
滌デ2
滍ヂー4
滎シン2
滏フー3
滐ジェ2
滑ファ2
滒グェ゛ァ1
滓ズー3
滔タオ1
滕トン2
滖スェイ1
滗ビー4
滘ジャオ4
滙フゥェイ4
滚ガン3
滛イン2
滜ガオ1
滝ロン2
滞ヂー4
滟イェン4
滠シェ゛ァ4
满マン3
滢イン2
滣チュン2
滤リュ4
滥ラン4
滦ルゥァン2
滧イャォ2
滨ビン1
滩タン1
滪ユー4
滫シゥ3
滬フー4
滭ビー4
滮ビィャォ1
滯ヂー4
滰ジィァン4
滱コウ4
滲シェン4
滳シャン1
滴デ1
滵ミィ4
滶アオ2
滷ルー3
滸フー3
滹フー1
滺ヨウ1
滻チャン3
滼ファン4
滽ヨン1
滾ガン3
滿マン3
漀チン3
漁ユー2
漂ピィァオ1
漃ジー4
漄ヤー2
漅チャオ2
漆チー1
漇シー3
漈ジー4
漉ルー4
漊ロウ2
漋ロン2
漌ジン3
漍グゥォ2
漎ツォン2
漏ロウ4
漐ヂー2
漑ガイ4
漒チィァン2
漓リー2
演イェン3
漕ツァォ2
漖ジャオ4
漗ツォン1
漘チュン2
漙トゥァン2
漚オウ1
漛トン2
漜イェ3
漝シー2
漞ミィ4
漟タン2
漠ムー4
漡シャン1
漢ハン4
漣リィェン2
漤ラン3
漥ウァ1
漦チー2
漧ガン1
漨フォン2
漩シュェン2
漪イー1
漫マン4
漬ズー4
漭マン3
漮カン1
漯ルゥォ4
漰ポン1
漱シュ4
漲ヂャン3
漳ヂャン1
漴ヂュゥァン4
漵シュ4
漶ファン4
漷フォ3
漸ジィェン4
漹イェン1
漺シュゥァン3
漻リィァォ2
漼ツェイ3
漽ティ2
漾ヤン4
漿ジィァン1
潀ツォン2
潁イン3
潂ホン2
潃シゥ3
潄シュ4
潅グァン4
潆イン2
潇シァォ1
潈ゾン
潉クン1
潊シュ4
潋リィェン4
潌ヂー4
潍ウェイ2
潎ピー4
潏ユー4
潐ジャオ4
潑ポォ1
潒ダン4
潓フゥェイ4
潔ジェ2
潕ウー3
潖パー2
潗ジー2
潘パン1
潙ウェイ2
潚スー4
潛チィェン2
潜チィェン2
潝シー1
潞ルー4
潟シー4
潠シュン4
潡ドゥン4
潢フゥァン2
潣ミン3
潤ルン4
潥スー4
潦ラオ3
潧ヂェン1
潨ツォン2
潩イー4
潪ヂェ゛ァ4
潫ワン1
潬シャン4
潭タン2
潮チャオ2
潯シュン2
潰クゥェイ4
潱イェ1
潲シャオ4
潳トゥ2
潴ヂュ1
潵サー3
潶ヘイ1
潷ビー4
潸シャン1
潹チャン2
潺チャン2
潻シュ3
潼トン2
潽プー1
潾リン2
潿ウェイ2
澀スェ゛ァ4
澁スェ゛ァ4
澂チォン2
澃ジィォン3
澄チォン2
澅ファ4
澆ジャオ1
澇ラオ4
澈チェ゛ァ4
澉ガン3
澊ツン1
澋ホン4
澌スー1
澍シュ4
澎ポン1
澏ハン2
澐ユン2
澑リィゥ4
澒ホン4
澓フー2
澔ハオ4
澕フェ゛ァ2
澖シィェン2
澗ジィェン4
澘シャン1
澙シー4
澚ユー
澛ルー3
澜ラン2
澝ニン4
澞ユー2
澟リン3
澠ミィェン3
澡ザオ3
澢ダン1
澣ファン4
澤ゼェ゛ァ2
澥シェ4
澦ユー4
澧リー3
澨シー4
澩シュェ2
澪リン2
澫ワン4
澬ズー1
澭ヨン1
澮フゥェイ4
澯ツァン4
澰リィェン4
澱ディェン4
澲イェ4
澳アオ4
澴ファン2
澵ヂェン1
澶チャン2
澷マン4
澸ダン3
澹ダン4
澺イー4
澻スェイ4
澼ピー4
澽ジュ4
澾ター4
澿チン2
激ジー1
濁ヂュオ2
濂リィェン2
濃ノン2
濄グゥォ1
濅ジン4
濆フェン2
濇スェ゛ァ4
濈ジー2
濉スェイ1
濊フゥェイ4
濋チュ3
濌ター4
濍ソン1
濎ディン3
濏スェ゛ァ4
濐ヂュ3
濑ライ4
濒ビン1
濓リィェン2
濔ミィ3
濕シー1
濖シュ4
濗ミィ4
濘ニン4
濙イン2
濚イン2
濛モン2
濜ジン4
濝チー2
濞ビー4
濟ジー4
濠ハオ2
濡ルー2
濢ツェイ4
濣ウォ4
濤タオ1
濥イン3
濦イン3
濧ドゥェイ4
濨ツー2
濩フォ4
濪チン4
濫ラン4
濬ジュン4
濭ェ゛ァ3
濮プー2
濯ヂュオ2
濰ウェイ2
濱ビン1
濲グー3
濳チィェン2
濴イン2
濵ビン1
濶クォ4
濷フェイ4
濸ツァン1
濹ムェ゛ァ
濺ジィェン4
濻ウェイ3
濼ルゥォ4
濽ザン4
濾リュ4
濿リー4
瀀ヨウ1
瀁ヤン4
瀂ルー3
瀃スー4
瀄ヂー4
瀅イン2
瀆ドゥ2
瀇ワン3
瀈フゥェイ1
瀉シェ4
瀊パン2
瀋シェン3
瀌ビィャォ1
瀍チャン2
瀎ムー4
瀏リィゥ2
瀐ジィェン1
瀑プー4
瀒スェ゛ァ4
瀓チォン2
瀔グー3
瀕ビン1
瀖フォ4
瀗シィェン4
瀘ルー2
瀙チン4
瀚ハン4
瀛イン2
瀜ロン2
瀝リー4
瀞ジン4
瀟シァォ1
瀠イン2
瀡スェイ3
瀢ウェイ3
瀣シェ4
瀤フゥァイ2
瀥シュェ4
瀦ヂュ1
瀧ロン2
瀨ライ4
瀩ドゥェイ4
瀪ファン2
瀫フー2
瀬ライ4
瀭シュ1
瀮リン
瀯イン2
瀰ミィ2
瀱ジー4
瀲リィェン4
瀳ジィェン4
瀴イン2
瀵フェン4
瀶リン2
瀷イー4
瀸ジィェン1
瀹ユェ4
瀺チャン2
瀻ダイ4
瀼ラン2
瀽ジィェン3
瀾ラン2
瀿ファン2
灀シュゥァン4
灁ユェン1
灂ヂュオ2
灃フォン1
灄シェ゛ァ4
灅レイ3
灆ラン2
灇ツォン2
灈チュ2
灉ヨン1
灊チィェン2
灋ファ3
灌グァン4
灍ジュェ2
灎イェン4
灏ハオ4
灐イン2
灑サー3
灒ザン4
灓ルゥァン2
灔イェン4
灕リー2
灖ミィ3
灗シャン4
灘タン1
灙ダン3
灚ジャオ3
灛チャン3
灜イン2
灝ハオ4
灞バー4
灟ヂュ2
灠ラン3
灡ラン2
灢ナン3
灣ワン1
灤ルゥァン2
灥シュン2
灦シィェン3
灧イェン4
灨ガン4
灩イェン4
灪ユー4
火フォ3
灬ビィャォ1
灭ミィェ4
灮グゥァン1
灯ドン1
灰フゥェイ1
灱シァォ1
灲シァォ1
灳フゥェイ1
灴ホン1
灵リン2
灶ザオ4
灷ヂュァン4
灸ジゥ3
灹ヂャ4
灺シェ4
灻チー4
灼ヂュオ2
災ザイ1
灾ザイ1
灿ツァン4
炀ヤン2
炁チー4
炂ヂョン1
炃フェン2
炄ニィゥ3
炅ジィォン3
炆ウェン2
炇プー1
炈イー4
炉ルー2
炊チュェイ1
炋ピー1
炌カイ4
炍パン4
炎イェン2
炏カイ4
炐パン4
炑ムー4
炒チャオ3
炓リィァォ4
炔グゥェイ4
炕カン4
炖ドゥン4
炗グゥァン1
炘シン1
炙ヂー4
炚グゥァン1
炛グゥァン1
炜ウェイ3
炝チィァン4
炞ビィェン
炟ダー2
炠シァ2
炡ヂォン1
炢ヂュ2
炣クェ゛ァ3
炤ヂャオ4
炥フー2
炦バー2
炧シェ4
炨シェ4
炩リン4
炪ヂュオ1
炫シュェン4
炬ジュ4
炭タン4
炮パオ4
炯ジィォン3
炰パオ2
炱タイ2
炲タイ2
炳ビン3
炴ヤン3
炵トン1
炶シャン3
炷ヂュ4
炸ヂャ4
点ディェン3
為ウェイ4
炻シー2
炼リィェン4
炽チー4
炾フゥァン3
炿ヂョウ1
烀フー1
烁シュォ4
烂ラン4
烃ティン1
烄ジャオ3
烅シュ4
烆ホン2
烇チュェン3
烈リィェ4
烉ファン4
烊ヤン2
烋シゥ1
烌シゥ1
烍シィェン3
烎イン2
烏ウー1
烐ヂョウ1
烑イャォ2
烒シー4
烓ウェイ1
烔トン2
烕ミィェ4
烖ザイ1
烗カイ4
烘ホン1
烙ラオ4
烚シァ2
烛ヂュ2
烜シュェン3
烝ヂォン1
烞ポォ4
烟イェン1
烠フゥェイ2
烡グゥァン1
烢チェ゛ァ4
烣フゥェイ1
烤カオ3
烥ジュ4
烦ファン2
烧シャオ1
烨イェ4
烩フゥェイ4
烫タン4
烬ジン4
热ルェ゛ァ4
烮リィェ4
烯シー1
烰フー2
烱ジィォン3
烲シェ4
烳プー3
烴ティン1
烵ヂュオ2
烶ティン3
烷ワン2
烸ファン3
烹ポン1
烺ラン3
烻イェン4
烼シュ4
烽フォン1
烾チー4
烿ロン2
焀フー2
焁シー1
焂シュ1
焃フェ゛ァ4
焄シュン1
焅クー4
焆ジュェン1
焇シァォ1
焈シー1
焉イェン1
焊ハン4
焋ヂュゥァン4
焌ジュン4
焍デ4
焎シェ4
焏ジー2
焐ウー4
焑イェン1
焒リュ3
焓ハン2
焔イェン4
焕ファン4
焖メン4
焗ジュ2
焘ダオ4
焙ベイ4
焚フェン2
焛リン4
焜クン1
焝フン4
焞トゥン1
焟シー1
焠ツェイ4
無ウー2
焢ホン1
焣チャオ3
焤フー3
焥ウォ4
焦ジャオ1
焧ツォン1
焨フォン4
焩ピン2
焪チォン2
焫ルォ4
焬シー1
焭チォン2
焮シン4
焯チャオ1
焰イェン4
焱イェン4
焲イー4
焳ジュェ2
焴ユー4
焵ガン4
然ラン2
焷ピー2
焸シィォン4
焹ガン4
焺シォン1
焻chang4
焼シャオ1
焽シィォン3
焾ニィェン3
焿ゴン1
煀ウェイ
煁チェン2
煂フェ゛ァ4
煃クゥェイ3
煄ヂョン3
煅ドゥァン4
煆シァ1
煇フゥェイ1
煈フォン4
煉リィェン4
煊シュェン1
煋シン1
煌フゥァン2
煍ジャオ3
煎ジィェン1
煏ビー4
煐イン1
煑ヂュ3
煒ウェイ3
煓トゥァン1
煔シャン3
煕シー1
煖ヌァン3
煗ヌァン3
煘チャン2
煙イェン1
煚ジィォン3
煛ジィォン3
煜ユー4
煝メイ4
煞シャ1
煟ウェイ4
煠ヂャ2
煡ジン4
煢チォン2
煣ロウ2
煤メイ2
煥ファン4
煦シュ4
照ヂャオ4
煨ウェイ1
煩ファン2
煪チィゥ2
煫スェイ4
煬ヤン2
煭リィェ4
煮ヂュ3
煯ジェ1
煰ザオ4
煱グァ1
煲バオ1
煳フー2
煴ユン1
煵ナン3
煶シー4
煷リィァン
煸ビィェン1
煹ゴウ4
煺トゥェイ4
煻タン2
煼チャオ3
煽シャン1
煾エン1
煿ブォ2
熀フゥァン3
熁シェ2
熂シー4
熃ウー4
熄シー1
熅ユン4
熆フェ゛ァ2
熇フェ゛ァ4
熈シー1
熉ユン2
熊シィォン2
熋ナイ2
熌シャン3
熍チォン2
熎イャォ4
熏シュン1
熐ミィ4
熑リィェン2
熒イン2
熓ウー3
熔ロン2
熕ゴン1
熖イェン4
熗チィァン4
熘リィゥ1
熙シー1
熚ビー4
熛ビィャォ1
熜ツォン1
熝ルー4
熞ジィェン1
熟シュ2
熠イー4
熡ロウ2
熢ポン2
熣スェイ1
熤イー4
熥トン1
熦ジュェ2
熧ゾン1
熨ユン4
熩フー4
熪イー2
熫ヂー4
熬アオ2
熭ウェイ4
熮リィゥ3
熯ハン4
熰オウ1
熱ルェ゛ァ4
熲ジィォン3
熳マン4
熴クン1
熵シャン1
熶ツァン4
熷ゾン1
熸ジィェン1
熹シー1
熺シー1
熻シー1
熼イー4
熽シァォ4
熾チー4
熿フゥァン2
燀チャン3
燁イェ4
燂タン2
燃ラン2
燄イェン4
燅シュン2
燆チィァォ1
燇ジュン4
燈ドン1
燉ドゥン4
燊シェン1
燋ジャオ1
燌フェン2
燍スー1
燎リィァォ2
燏ユー4
燐リン2
燑トン2
燒シャオ1
燓フェン2
燔ファン2
燕イェン4
燖シュン2
燗ラン4
燘メイ3
燙タン4
燚イー4
燛ジィォン3
燜メン4
燝ジン
燞ジャオ3
營イン2
燠ユー4
燡イー4
燢シュェ2
燣ラン2
燤タイ4
燥ザオ4
燦ツァン4
燧スェイ4
燨シー1
燩チュェ4
燪ゾン3
燫リィェン2
燬フゥェイ3
燭ヂュ2
燮シェ4
燯リン2
燰ウェイ1
燱イー4
燲シェ2
燳ヂャオ4
燴フゥェイ4
燵ダー2
燶ノン2
燷ラン2
燸ルー2
燹シィェン3
燺フェ゛ァ4
燻シュン1
燼ジン4
燽チョウ2
燾ダオ4
燿イャォ4
爀フェ゛ァ4
爁ラン4
爂ビィャォ1
爃ロン2
爄リー4
爅ムー4
爆バオ4
爇ルォ4
爈リュ4
爉ラー4
爊アオ1
爋シュン1
爌クゥァン4
爍シュォ4
爎リィァォ2
爏リー4
爐ルー2
爑ジュェ2
爒リィァォ3
爓イェン4
爔シー1
爕シェ4
爖ロン2
爗イェ4
爘ツァン1
爙ラン3
爚ユェ4
爛ラン4
爜ツォン2
爝ジュェ2
爞チョン2
爟グァン4
爠ジュ
爡チェ゛ァ4
爢ミィ2
爣タン3
爤ラン4
爥ヂュ2
爦ラン3
爧リン2
爨ツァン4
爩ユー4
爪ヂャオ3
爫ヂャオ3
爬パー2
爭ヂォン1
爮パオ2
爯チォン1
爰ユェン2
爱ェ゛ァ4
爲ウェイ4
爳ハン
爴ジュェ2
爵ジュェ2
父フー4
爷イェ2
爸バー4
爹ディェ1
爺イェ2
爻イャォ2
爼ズー3
爽シュゥァン3
爾ェ゛ァー3
爿パン2
牀チュゥァン2
牁クェ゛ァ1
牂ザン1
牃ディェ2
牄チィァン1
牅ヨン1
牆チィァン2
片ピィェン4
版バン3
牉パン4
牊チャオ2
牋ジィェン1
牌パイ2
牍ドゥ2
牎チュゥァン1
牏ユー2
牐ヂャ2
牑ビィェン1
牒ディェ2
牓バン3
牔ブォ2
牕チュゥァン1
牖ヨウ3
牗ヨウ3
牘ドゥ2
牙ヤー2
牚チォン1
牛ニィゥ2
牜ニィゥ2
牝ピン4
牞ジゥ1
牟モウ2
牠ター1
牡ムー3
牢ラオ2
牣レン4
牤マン1
牥ファン1
牦マオ2
牧ムー4
牨ガン1
物ウー4
牪イェン4
牫グェ゛ァ1
牬ベイ4
牭スー4
牮ジィェン4
牯グー3
牰ヨウ4
牱グェ゛ァ1
牲シォン1
牳ムー3
牴デ3
牵チィェン1
牶チュェン4
牷チュェン2
牸ズー4
特テェ゛ァ4
牺シー1
牻マン2
牼コン1
牽チィェン1
牾ウー3
牿グー4
犀シー1
犁リー2
犂リー2
犃ポウ3
犄ジー1
犅ガン1
犆ヂー2
犇ベン1
犈チュェン2
犉チュン2
犊ドゥ2
犋ジュ4
犌ジャ1
犍ジィェン1
犎フォン1
犏ピィェン1
犐クェ゛ァ1
犑ジュ2
犒カオ4
犓チュ2
犔シー4
犕ベイ4
犖ルゥォ4
犗ジェ4
犘マー2
犙サン1
犚ウェイ4
犛マオ2
犜ドゥン1
犝トン2
犞チィァォ2
犟ジィァン4
犠シー1
犡リー4
犢ドゥ2
犣リィェ4
犤パイ2
犥ピィァオ1
犦ブォ2
犧シー1
犨チョウ1
犩ウェイ2
犪クゥェイ2
犫チョウ1
犬チュェン3
犭チュェン3
犮バー2
犯ファン4
犰チィゥ2
犱ジー3
犲チャイ2
犳ヂュオ2
犴アン4
犵グェ゛ァ1
状ヂュゥァン4
犷グゥァン3
犸マー4
犹ヨウ2
犺カン4
犻ブォ2
犼ホウ3
犽ヤー4
犾イン2
犿ファン1
狀ヂュゥァン4
狁ユン3
狂クゥァン2
狃ニィゥ3
狄デ2
狅クゥァン2
狆ヂョン4
狇ムー4
狈ベイ4
狉ピー1
狊ジュ2
狋イー2
狌シォン1
狍パオ2
狎シァ2
狏トゥォ2
狐フー2
狑リン2
狒フェイ4
狓ピー2
狔ニー3
狕イャォ3
狖ヨウ4
狗ゴウ3
狘シュェ4
狙ジュ1
狚ダン4
狛ブォ2
狜クー3
狝シィェン3
狞ニン2
狟ファン2
狠ヘン3
狡ジャオ3
狢フェ゛ァ2
狣ヂャオ4
狤ジー2
狥シュン4
狦シャン1
狧ター4
狨ロン2
狩ショウ4
狪トン2
狫ラオ3
独ドゥ2
狭シァ2
狮シー1
狯クァィ4
狰ヂォン1
狱ユー4
狲スン1
狳ユー2
狴ビー4
狵マン2
狶シー1
狷ジュェン4
狸リー2
狹シァ2
狺イン2
狻スゥァン1
狼ラン2
狽ベイ4
狾ヂー4
狿イェン2
猀シャ1
猁リー4
猂ハン4
猃シィェン3
猄ジン1
猅パイ2
猆フェイ1
猇シァォ1
猈バイ4
猉チー2
猊ニー2
猋ビィャォ1
猌イン4
猍ライ2
猎リィェ4
猏ジィェン1
猐チィァン1
猑クン1
猒イェン4
猓グゥォ3
猔ゾン4
猕ミィ2
猖chang1
猗イー1
猘ヂー4
猙ヂォン1
猚ヤー2
猛モン3
猜ツァィ1
猝ツゥ4
猞シェ゛ァ1
猟リィェ4
猠ディェン3
猡ルゥォ2
猢フー2
猣ゾン1
猤グゥェイ4
猥ウェイ3
猦フォン1
猧ウォ1
猨ユェン2
猩シン1
猪ヂュ1
猫マオ1
猬ウェイ4
猭チュァン1
献シィェン4
猯トゥァン1
猰ヤー4
猱ナオ2
猲シェ1
猳ジャ1
猴ホウ2
猵ビィェン1
猶ヨウ2
猷ヨウ2
猸メイ2
猹チャ2
猺イャォ2
猻スン1
猼ブォ2
猽ミン2
猾ファ2
猿ユェン2
獀ソウ1
獁マー4
獂ユェン2
獃ダイ1
獄ユー4
獅シー1
獆ハオ2
獇チィァン1
獈イー4
獉ヂェン1
獊ツァン1
獋ハオ2
獌マン4
獍ジン4
獎ジィァン3
獏ムー4
獐ヂャン1
獑チャン2
獒アオ2
獓アオ2
獔ハオ2
獕ツェイ1
獖ベン4
獗ジュェ2
獘ビー4
獙ビー4
獚フゥァン2
獛プー2
獜リン2
獝シュ4
獞トン2
獟イャォ4
獠リィァォ2
獡シュォ4
獢シァォ1
獣ショウ4
獤ドゥン1
獥ジャオ4
獦グェ゛ァ2
獧ジュェン4
獨ドゥ2
獩フゥェイ4
獪クァィ4
獫シィェン3
獬シェ4
獭ター3
獮シィェン3
獯シュン1
獰ニン2
獱ビィェン1
獲フォ4
獳ノウ4
獴モン3
獵リィェ4
獶ナオ3
獷グゥァン3
獸ショウ4
獹ルー2
獺ター3
獻シィェン4
獼ミィ2
獽ラン2
獾ファン1
獿ナオ3
玀ルゥォ2
玁シィェン3
玂チー2
玃ジュェ2
玄シュェン2
玅ミィァォ4
玆ズー1
率リュ4
玈ルー2
玉ユー4
玊スー4
王ワン2
玌チィゥ2
玍チィェ3
玎ディン1
玏ラ4
玐バー1
玑ジー1
玒ホン2
玓デ4
玔チュァン4
玕ガン1
玖ジゥ3
玗ユー2
玘チー3
玙ユー2
玚chang4
玛マー3
玜ホン2
玝ウー3
玞フー1
玟ウェン2
玠ジェ4
玡ヤー2
玢ビン1
玣ビィェン4
玤バン4
玥ユェ4
玦ジュェ2
玧メン2
玨ジュェ2
玩ワン2
玪ジィェン1
玫メイ2
玬ダン3
玭ピン2
玮ウェイ3
环ファン2
现シィェン4
玱チィァン1
玲リン2
玳ダイ4
玴イー4
玵アン2
玶ピン2
玷ディェン4
玸フー2
玹シュェン2
玺シー3
玻ブォ1
玼ツー3
玽ゴウ3
玾ジャ3
玿シャオ2
珀ポォ4
珁ツー2
珂クェ゛ァ1
珃ラン3
珄シォン1
珅シェン1
珆イー2
珇ズー3
珈ジャ1
珉ミン2
珊シャン1
珋リィゥ3
珌ビー4
珍ヂェン1
珎ヂェン1
珏ジュェ2
珐ファ4
珑ロン2
珒ジン1
珓ジャオ4
珔ジィェン4
珕リー4
珖グゥァン1
珗シィェン1
珘ヂョウ1
珙ゴン3
珚イェン1
珛シゥ4
珜ヤン2
珝シュ3
珞ルゥォ4
珟スー4
珠ヂュ1
珡チン2
珢イン2
珣シュン2
珤バオ3
珥ェ゛ァー3
珦シィァン4
珧イャォ2
珨シァ2
珩ハン2
珪グゥェイ1
珫チョン1
珬シュ4
班バン1
珮ペイ4
珯ラオ3
珰ダン1
珱イン1
珲フゥェイ1
珳ウェン2
珴ェ゛ァ2
珵チォン2
珶デ4
珷ウー3
珸ウー2
珹チォン2
珺ジュン4
珻メイ2
珼ベイ4
珽ティン3
現シィェン4
珿チュ4
琀ハン2
琁シュェン2
琂イェン2
球チィゥ2
琄シュェン4
琅ラン2
理リー3
琇シゥ4
琈フー2
琉リィゥ2
琊ヤー2
琋シー1
琌リン2
琍リー2
琎ジン4
琏リィェン3
琐スォ3
琑スォ3
琒フォン1
琓ワン2
琔ディェン4
琕ピン2
琖ヂャン3
琗スェ゛ァ4
琘ミン2
琙ユー4
琚ジュ1
琛チェン1
琜ライ2
琝ミン2
琞シォン4
琟ウェイ2
琠ティェン3
琡チュ4
琢ズゥォ2
琣ボン3
琤チォン1
琥フー3
琦チー2
琧ェ゛ァ4
琨クン1
琩chang1
琪チー2
琫ボン3
琬ワン3
琭ルー4
琮ツォン2
琯グァン3
琰イェン3
琱ディァォ1
琲ベイ4
琳リン2
琴チン2
琵ピー2
琶パー2
琷チュェ4
琸ヂュオ2
琹チン2
琺ファ4
琻ジン1
琼チォン2
琽ドゥ3
琾ジェ4
琿フン2
瑀ユー3
瑁マオ4
瑂メイ2
瑃チュン1
瑄シュェン1
瑅ティ2
瑆シン1
瑇ダイ4
瑈ロウ2
瑉ミン2
瑊ジィェン1
瑋ウェイ3
瑌ルァン3
瑍ファン4
瑎シェ2
瑏チュァン1
瑐ジィェン3
瑑ヂュァン4
瑒chang4
瑓リィェン4
瑔チュェン2
瑕シァ2
瑖ドゥァン4
瑗ユェン4
瑘ヤー2
瑙ナオ3
瑚フー2
瑛イン1
瑜ユー2
瑝フゥァン2
瑞ルェイ4
瑟スェ゛ァ4
瑠リィゥ2
瑡シー1
瑢ロン2
瑣スォ3
瑤イャォ2
瑥ウェン1
瑦ウー3
瑧ヂェン1
瑨ジン4
瑩イン2
瑪マー3
瑫タオ1
瑬リィゥ2
瑭タン2
瑮リー4
瑯ラン2
瑰グゥェイ1
瑱ヂェン4
瑲チィァン1
瑳ツゥォ1
瑴ジュェ2
瑵ヂャオ3
瑶イャォ2
瑷ェ゛ァ4
瑸ビン1
瑹シュ1
瑺chang2
瑻クン1
瑼ヂュァン1
瑽ツォン1
瑾ジン3
瑿イー1
璀ツェイ3
璁ツォン1
璂チー2
璃リー2
璄ジン3
璅スォ3
璆チィゥ2
璇シュェン2
璈アオ2
璉リィェン3
璊メン2
璋ヂャン1
璌イン2
璍イェ4
璎イン1
璏ウェイ4
璐ルー4
璑ウー2
璒ドン1
璓シゥ4
璔ゾン1
璕シュン2
璖チュ2
璗ダン4
璘リン2
璙リィァォ2
璚チォン2
璛スー4
璜フゥァン2
璝グゥェイ1
璞プー2
璟ジン3
璠ファン2
璡ジン4
璢リィゥ2
璣ジー1
璤フゥェイ4
璥ジン3
璦ェ゛ァ4
璧ビー4
璨ツァン4
璩チュ2
璪ザオ3
璫ダン1
璬ジャオ3
璭ガン4
璮タン3
璯フゥェイ4
環ファン2
璱スェ゛ァ4
璲スェイ4
璳ティェン2
璴チュ3
璵ユー2
璶ジン4
璷ルー2
璸ビン1
璹シュ2
璺ウェン4
璻ズェイ3
璼ラン2
璽シー3
璾ズー1
璿シュェン2
瓀ルァン3
瓁ウォ4
瓂ガイ4
瓃レイ2
瓄ドゥ2
瓅リー4
瓆ヂー4
瓇ロウ2
瓈リー2
瓉ザン4
瓊チォン2
瓋ティ4
瓌グゥェイ1
瓍スェイ2
瓎ラー4
瓏ロン2
瓐ルー2
瓑リー4
瓒ザン4
瓓ラン4
瓔イン1
瓕ミィ2
瓖シィァン1
瓗チォン2
瓘グァン4
瓙ダオ4
瓚ザン4
瓛ファン2
瓜グァ1
瓝ブォ2
瓞ディェ2
瓟ブォ2
瓠フー4
瓡ヂー2
瓢ピィァオ2
瓣バン4
瓤ラン2
瓥リー4
瓦ウァ3
瓨シィァン2
瓩チィェン1
瓪バン3
瓫ペン2
瓬ファン3
瓭ダン3
瓮ウォン4
瓯オウ1
瓲ウァ
瓳フー2
瓴リン2
瓵イー2
瓶ピン2
瓷ツー2
瓸バイ3
瓹ジュェン1
瓺chang2
瓻チー1
瓽ダン4
瓾モン3
瓿ブー4
甀ヂュェイ4
甁ピン2
甂ビィェン1
甃ヂョウ4
甄ヂェン1
甆ツー2
甇イン1
甈チー4
甉シィェン2
甊ロウ3
甋デ4
甌オウ1
甍モン2
甎ヂュァン1
甏ボン4
甐リン4
甑ゾン4
甒ウー3
甓ピー4
甔ダン1
甕ウォン4
甖イン1
甗イェン3
甘ガン1
甙ダイ4
甚シェン2
甛ティェン2
甜ティェン2
甝ハン2
甞chang2
生シォン1
甠チン2
甡シェン1
產チャン3
産チャン3
甤ルェイ2
甥シォン1
甦スー1
甧シェン1
用ヨン4
甩シュァイ3
甪ルー4
甫フー3
甬ヨン3
甭ボン2
甮フォン4
甯ニン2
田ティェン2
由ヨウ2
甲ジャ3
申シェン1
甴ヂャ2
电ディェン4
甶フー2
男ナン2
甸ディェン1
甹ピン1
町ティン1
画ファ4
甼ティン3
甽ヂェン4
甾ザイ1
甿モン2
畀ビー4
畁ビー4
畂リィゥ4
畃シュン2
畄リィゥ2
畅chang4
畆ムー3
畇ユン2
畈ファン4
畉フー2
畊ゴン1
畋ティェン2
界ジェ4
畍ジェ4
畎チュェン3
畏ウェイ4
畐フー2
畑ティェン2
畒ムー3
畓ドゥォ1
畔パン4
畕ジィァン1
畖ウァ1
畗ダー2
畘ナン2
留リィゥ2
畚ベン3
畛ヂェン3
畜チュ4
畝ムー3
畞ムー3
畟ツェ゛ァ4
畠ティェン2
畡ガイ1
畢ビー4
畣ダー2
畤ヂー4
略リュェ4
畦チー2
畧リュェ4
畨パン1
畩イー1
番ファン1
畫ファ4
畬シェ゛ァ1
畭ユー2
畮ムー3
畯ジュン4
異イー4
畱リィゥ2
畲シェ゛ァ1
畳ディェ2
畴チョウ2
畵ファ4
當ダン1
畷ヂュェイ4
畸ジー1
畹ワン3
畺ジィァン1
畻チォン2
畼chang4
畽トゥン3
畾レイ2
畿ジー1
疀チャ1
疁リィゥ2
疂ディェ2
疃トゥァン3
疄リン4
疅ジィァン1
疆ジィァン1
疇チョウ2
疈ピー4
疉ディェ2
疊ディェ2
疋ピー3
疌ジェ2
疍ダン4
疎シュ1
疏シュ1
疐ヂー4
疑イー2
疒ヌェ゛ァ4
疓ナイ3
疔ディン1
疕ビー3
疖ジェ1
疗リィァォ2
疘ガン1
疙グェ゛ァ1
疚ジゥ4
疛ヂョウ3
疜シァ4
疝シャン4
疞シュ1
疟ヌーェ゛ァ4
疠リー4
疡ヤン2
疢チェン4
疣ヨウ2
疤バー1
疥ジェ4
疦ジュェ2
疧チー2
疨シァ1
疩ツェイ4
疪ビー4
疫イー4
疬リー4
疭ゾン4
疮チュゥァン1
疯フォン1
疰ヂュ4
疱パオ4
疲ピー2
疳ガン1
疴クェ゛ァ1
疵ツー1
疶シュェ1
疷ヂー1
疸ダン3
疹ヂェン3
疺ファ2
疻ヂー3
疼トン2
疽ジュ1
疾ジー2
疿フェイ4
痀ジュ1
痁シャン1
痂ジャ1
痃シュェン2
痄ヂャ4
病ビン4
痆ニィェ4
症ヂォン4
痈ヨン1
痉ジン4
痊チュェン2
痋トン2
痌トン1
痍イー2
痎ジェ1
痏ウェイ3
痐フゥェイ2
痑タン1
痒ヤン3
痓チー4
痔ヂー4
痕ヘン2
痖ヤー3
痗メイ4
痘ドウ4
痙ジン4
痚シァォ1
痛トン4
痜トゥ1
痝マン2
痞ピー3
痟シァォ1
痠スゥァン1
痡フー1
痢リー4
痣ヂー4
痤ツゥォ2
痥ドゥォ2
痦ウー4
痧シャ1
痨ラオ2
痩ショウ4
痪ファン4
痫シィェン2
痬イー4
痭ボン1
痮ヂャン4
痯グァン3
痰タン2
痱フェイ4
痲マー2
痳リン2
痴チー1
痵ジー4
痶ティェン3
痷アン1
痸チー4
痹ビー4
痺ビー4
痻ミン2
痼グー4
痽ドゥェイ1
痾ェ゛ァ1
痿ウェイ3
瘀ユー1
瘁ツェイ4
瘂ヤー3
瘃ヂュ2
瘄ツゥ4
瘅ダン1
瘆シェン4
瘇ヂョン3
瘈チー4
瘉ユー4
瘊ホウ2
瘋フォン1
瘌ラー4
瘍ヤン2
瘎チェン2
瘏トゥ2
瘐ユー3
瘑グゥォ1
瘒ウェン2
瘓ファン4
瘔クー4
瘕ジャ3
瘖イン1
瘗イー4
瘘ロウ4
瘙サオ4
瘚ジュェ2
瘛チー4
瘜シー1
瘝グァン1
瘞イー4
瘟ウェン1
瘠ジー2
瘡チュゥァン1
瘢バン1
瘣フゥェイ4
瘤リィゥ2
瘥チャイ4
瘦ショウ4
瘧ヌーェ゛ァ4
瘨ディェン1
瘩ダー1
瘪ビィェ3
瘫タン1
瘬ヂャン4
瘭ビィャォ1
瘮シェン4
瘯ツゥ4
瘰ルゥォ3
瘱イー4
瘲ゾン4
瘳チョウ1
瘴ヂャン4
瘵ヂャイ4
瘶ソウ4
瘷スェ゛ァ4
瘸チュェ2
瘹ディァォ4
瘺ロウ4
瘻ロウ4
瘼ムー4
瘽チン2
瘾イン3
瘿イン3
癀フゥァン2
癁フー2
療リィァォ2
癃ロン2
癄チィァォ2
癅リィゥ2
癆ラオ2
癇シィェン2
癈フェイ4
癉ダン1
癊イン4
癋フェ゛ァ4
癌ェ゛ァ2
癍バン1
癎シィェン2
癏グァン1
癐グゥェイ4
癑ノン4
癒ユー4
癓ウェイ2
癔イー4
癕ヨン1
癖ピー3
癗レイ3
癘リー4
癙シュ3
癚ダン4
癛リン3
癜ディェン4
癝リン3
癞ライ4
癟ビィェ3
癠ジー4
癡チー1
癢ヤン3
癣シュェン3
癤ジェ1
癥ヂォン1
癦ムェ゛ァ
癧リー4
癨フォ4
癩ライ4
癪ジー1
癫ディェン1
癬シュェン3
癭イン3
癮イン3
癯チュ2
癰ヨン1
癱タン1
癲ディェン1
癳ルゥォ3
癴ルゥァン2
癵ルゥァン2
癶ブォ1
癷ブォ1
癸グゥェイ3
癹バー2
発ファ1
登ドン1
發ファ1
白バイ2
百バイ3
癿チィェ2
皀ジー2
皁ザオ4
皂ザオ4
皃マオ4
的ディ
皅パー1
皆ジェ1
皇フゥァン2
皈グゥェイ1
皉ツー3
皊リン2
皋ガオ1
皌ムー4
皍ジー2
皎ジャオ3
皏ポン3
皐ガオ1
皑ェ゛ァ2
皒ェ゛ァ2
皓ハオ4
皔ハン4
皕ビー4
皖ワン3
皗チョウ2
皘チィェン4
皙シー1
皚ェ゛ァ2
皛シァォ3
皜ハオ4
皝フゥァン4
皞ハオ4
皟ゼェ゛ァ2
皠ツェイ3
皡ハオ4
皢シァォ3
皣イェ4
皤ポォ2
皥ハオ4
皦ジャオ3
皧ェ゛ァ4
皨シン1
皩フゥァン4
皪リー4
皫ピィァオ3
皬フェ゛ァ2
皭ジャオ4
皮ピー2
皯ガン3
皰パオ4
皱ヂョウ4
皲ジュン1
皳チィゥ2
皴ツン1
皵チュェ4
皶ヂャ1
皷グー3
皸ジュン1
皹ジュン1
皺ヂョウ4
皻ヂャ1
皼グー3
皽ヂャオ1
皾ドゥ2
皿ミン3
盀チー3
盁イン2
盂ユー2
盃ベイ1
盄ヂャオ1
盅ヂョン1
盆ペン2
盇フェ゛ァ2
盈イン2
盉フェ゛ァ2
益イー4
盋ブォ1
盌ワン3
盍フェ゛ァ2
盎アン4
盏ヂャン3
盐イェン2
监ジィェン1
盒フェ゛ァ2
盓ユー1
盔クゥェイ1
盕ファン4
盖ガイ4
盗ダオ4
盘パン2
盙フー3
盚チィゥ2
盛シォン4
盜ダオ4
盝ルー4
盞ヂャン3
盟モン2
盠リー2
盡ジン3
盢シュ4
監ジィェン1
盤パン2
盥グァン4
盦アン1
盧ルー2
盨シュ3
盩ヂョウ1
盪ダン4
盫アン1
盬グー3
盭リー4
目ムー4
盯ディン1
盰ガン4
盱シュ1
盲マン2
盳ワン4
直ヂー2
盵チー4
盶ユェン3
盷ティェン2
相シィァン1
盹ドゥン3
盺シン1
盻シー4
盼パン4
盽フォン1
盾ドゥン4
盿ミン2
眀ミン2
省シォン3
眂シー4
眃ユン2
眄ミィェン3
眅パン1
眆ファン3
眇ミィァォ3
眈ダン1
眉メイ2
眊マオ4
看カン4
県シィェン4
眍コウ1
眎シー4
眏ヤン1
眐ヂォン1
眑イャォ3
眒シェン1
眓フォ4
眔ダー4
眕ヂェン3
眖クゥァン4
眗ジュ1
眘シェン4
眙イー2
眚シォン3
眛メイ4
眜ムー4
眝ヂュ4
眞ヂェン1
真ヂェン1
眠ミィェン2
眡シー4
眢ユェン1
眣ディェ2
眤ニー4
眥ズー4
眦ズー4
眧チャオ3
眨ヂャ3
眩シュェン4
眪ビン3
眫ミィ3
眬ロン2
眭スェイ1
眮トン2
眯ミィ1
眰ディェ4
眱デ4
眲ヌェ゛ァ4
眳ミン2
眴シュェン4
眵チー1
眶クゥァン4
眷ジュェン4
眸モウ2
眹ヂェン4
眺ティァォ4
眻ヤン2
眼イェン3
眽ムー4
眾ヂョン4
眿ムー4
着ヂェ゛ァ
睁ヂォン1
睂メイ2
睃スォ1
睄シャオ4
睅ハン4
睆ファン4
睇デ4
睈チォン3
睉ツゥォ2
睊ジュェン4
睋ェ゛ァ2
睌マン3
睍シィェン4
睎シー1
睏クン4
睐ライ4
睑ジィェン3
睒シャン3
睓ティェン3
睔ガン4
睕ワン3
睖ラン4
睗シー4
睘チォン2
睙リィェ4
睚ヤー2
睛ジン1
睜ヂォン1
睝リー2
睞ライ4
睟スェイ4
睠ジュェン4
睡シュェイ4
睢スェイ1
督ドゥ1
睤ビー4
睥ピー4
睦ムー4
睧フン1
睨ニー4
睩ルー4
睪イー4
睫ジェ2
睬ツァィ3
睭ヂョウ3
睮ユー2
睯フン1
睰マー4
睱シァ4
睲シン3
睳フゥェイ1
睴ガン4
睵ザイ1
睶チュン3
睷ジィェン1
睸メイ4
睹ドゥ3
睺ホウ2
睻シュェン1
睼ティェン4
睽クゥェイ2
睾ガオ1
睿ルェイ4
瞀マオ4
瞁シュ4
瞂ファ2
瞃ウォ4
瞄ミィァォ2
瞅チョウ3
瞆クゥェイ4
瞇ミィ1
瞈ウォン3
瞉コウ4
瞊ダン4
瞋チェン1
瞌クェ゛ァ1
瞍ソウ3
瞎シァ1
瞏チォン2
瞐ムー4
瞑ミン2
瞒マン2
瞓シュェイ4
瞔ゼェ゛ァ2
瞕ヂャン4
瞖イー4
瞗ディァォ1
瞘コウ1
瞙ムー4
瞚シュン4
瞛ツォン1
瞜ロウ1
瞝チー1
瞞マン2
瞟ピィァオ3
瞠チォン1
瞡グゥェイ1
瞢モン2
瞣ワン4
瞤ルン2
瞥ピェ1
瞦シー1
瞧チィァォ2
瞨プー2
瞩ヂュ3
瞪ドン4
瞫シェン3
瞬シュン4
瞭リィァォ3
瞮チェ゛ァ4
瞯シィェン2
瞰カン4
瞱イェ4
瞲シュ4
瞳トン2
瞴モウ2
瞵リン2
瞶グゥェイ4
瞷ジィェン4
瞸イェ4
瞹ェ゛ァ4
瞺フゥェイ4
瞻ヂャン1
瞼ジィェン3
瞽グー3
瞾ヂャオ4
瞿チュ2
矀メイ2
矁チョウ3
矂サオ4
矃ニン3
矄シュン1
矅イャォ4
矆フォ4
矇モン2
矈ミィェン2
矉ピン2
矊ミィェン2
矋レイ3
矌クゥァン4
矍ジュェ2
矎シュェン1
矏ミィェン2
矐フォ4
矑ルー2
矒モン2
矓ロン2
矔グァン4
矕マン3
矖シー3
矗チュ4
矘タン3
矙カン4
矚ヂュ3
矛マオ2
矜ジン1
矝ジン1
矞ユー4
矟シュォ4
矠ゼェ゛ァ2
矡ジュェ2
矢シー3
矣イー3
矤シェン3
知ヂー1
矦ホウ2
矧シェン3
矨イン3
矩ジュ3
矪ヂョウ1
矫ジャオ3
矬ツゥォ2
短ドゥァン3
矮ェ゛ァ3
矯ジャオ3
矰ゾン1
矱ユェ1
矲バー4
石シー2
矴ディン4
矵チー4
矶ジー1
矷ズー3
矸ガン1
矹ウー4
矺ヂェ゛ァ2
矻クー1
矼ガン1
矽シー4
矾ファン2
矿クゥァン4
砀ダン4
码マー3
砂シャ1
砃ダン1
砄ジュェ2
砅リー4
砆フー1
砇ミン2
砈ェ゛ァ3
砉フォ4
砊カン1
砋ヂー3
砌チー4
砍カン3
砎ジェ4
砏ビン1
砐ェ゛ァ4
砑ヤー4
砒ピー1
砓ヂェ゛ァ2
研イェン2
砕スェイ4
砖ヂュァン1
砗チェ゛ァ1
砘ドゥン4
砙ウァ3
砚イェン4
砛ジン1
砜フォン1
砝ファ2
砞ムー4
砟ヂャ3
砠ジュ1
砡ユー4
砢クェ゛ァ1
砣トゥォ2
砤トゥォ2
砥デ3
砦ヂャイ4
砧ヂェン1
砨ェ゛ァ4
砩フー2
砪ムー3
砫ヂュ4
砬ラー2
砭ビィェン1
砮ヌー3
砯ピン1
砰ポン1
砱リン2
砲パオ4
砳ラ4
破ポォ4
砵ブォ1
砶ポォ4
砷シェン1
砸ザー2
砹ェ゛ァ4
砺リー4
砻ロン2
砼トン2
砽ヨン4
砾リー4
砿クゥァン4
础チュ3
硁コン1
硂チュェン2
硃ヂュ1
硄クゥァン1
硅グゥェイ1
硆ェ゛ァ4
硇ナオ2
硈チィァ4
硉ルー4
硊ウェイ3
硋ェ゛ァ4
硌グェ゛ァ4
硍シィェン4
硎シン2
硏イェン2
硐ドン4
硑ポン1
硒シー1
硓ラオ3
硔ホン2
硕シュォ4
硖シァ2
硗チィァォ1
硘チン
硙ウェイ2
硚チィァォ2
硛イー4
硜コン1
硝シァォ1
硞チュェ4
硟チャン4
硠ラン2
硡ホン1
硢ユー2
硣シァォ1
硤シァ2
硥マン3
硦ルゥォ4
硧ヨン3
硨チェ゛ァ1
硩チェ゛ァ4
硪ウォ4
硫リィゥ2
硬イン4
硭マン2
确チュェ4
硯イェン4
硰シャ1
硱クン3
硲ユー4
硳チー4
硴ファ1
硵ルー3
硶チェン3
硷ジィェン3
硸ヌーェ゛ァ4
硹ソン1
硺ヂュオ2
硻コン1
硼ポン2
硽イェン1
硾ヂュェイ4
硿コン1
碀チォン2
碁チー2
碂ゾン4
碃チン4
碄リン2
碅ジュン1
碆ブォ1
碇ディン4
碈ミン2
碉ディァォ1
碊ジィェン1
碋フェ゛ァ4
碌ルー4
碍ェ゛ァ4
碎スェイ4
碏チュェ4
碐ラン2
碑ベイ1
碒イン2
碓ドゥェイ4
碔ウー3
碕チー2
碖ルゥン3
碗ワン3
碘ディェン3
碙ナオ2
碚ベイ4
碛チー4
碜チェン3
碝ルァン3
碞イェン2
碟ディェ2
碠ディン4
碡ドゥ2
碢トゥォ2
碣ジェ2
碤イン1
碥ビィェン3
碦クェ゛ァ4
碧ビー4
碨ウェイ4
碩シュォ4
碪ヂェン1
碫ドゥァン4
碬シァ2
碭ダン4
碮ティ2
碯ナオ3
碰ポン4
碱ジィェン3
碲デ4
碳タン4
碴チャ2
碵ティェン2
碶チー4
碷ドゥン4
碸フォン1
碹シュェン4
確チュェ4
碻チュェ4
碼マー3
碽ゴン1
碾ニィェン3
碿スー4
磀ェ゛ァ2
磁ツー2
磂リィゥ2
磃スー1
磄タン2
磅バン4
磆ファ2
磇ピー1
磈ウェイ3
磉サン3
磊レイ3
磋ツゥォ1
磌ティェン2
磍シァ2
磎シー1
磏リィェン2
磐パン2
磑ウェイ2
磒ユン3
磓ドゥェイ1
磔ヂェ゛ァ2
磕クェ゛ァ1
磖ラー2
磗ヂュァン1
磘イャォ2
磙ガン3
磚ヂュァン1
磛チャン2
磜チー4
磝アオ2
磞ポン1
磟リィゥ4
磠ルー3
磡カン4
磢チュゥァン3
磣チェン3
磤イン3
磥レイ3
磦ビィャォ1
磧チー4
磨ムー2
磩チー4
磪ツェイ1
磫ゾン1
磬チン4
磭チュォ4
磮ルゥン2
磯ジー1
磰シャン4
磱ラオ2
磲チュ2
磳ゾン1
磴ドン4
磵ジィェン4
磶シー4
磷リン2
磸ディン4
磹タン2
磺フゥァン2
磻パン2
磼ザー2
磽チィァォ1
磾デ1
磿リー4
礀ジィェン4
礁ジャオ1
礂シー1
礃ヂャン3
礄チィァォ2
礅ドゥン1
礆ジィェン3
礇ユー4
礈ヂュェイ4
礉フェ゛ァ2
礊クェ゛ァ4
礋ゼェ゛ァ2
礌レイ2
礍ジェ2
礎チュ3
礏イェ4
礐チュェ4
礑ダン4
礒イー3
礓ジィァン1
礔ピー1
礕ピー1
礖ユー4
礗ピン1
礘ェ゛ァ4
礙ェ゛ァ4
礚クェ゛ァ1
礛ジィェン1
礜ユー4
礝ルァン3
礞モン2
礟パオ4
礠ツー2
礡ブォ2
礢ヤン3
礣マー4
礤ツァ3
礥シィェン2
礦クゥァン4
礧レイ2
礨レイ3
礩ヂー4
礪リー4
礫リー4
礬ファン2
礭チュェ4
礮パオ4
礯イン1
礰リー4
礱ロン2
礲ロン2
礳ムー4
礴ブォ2
礵シュゥァン1
礶グァン4
礷ラン2
礸ツァ3
礹イェン2
示シー4
礻シー4
礼リー3
礽ロン2
社シェ゛ァ4
礿ユェ4
祀スー4
祁チー2
祂ター1
祃マー4
祄シェ4
祅イャォ1
祆シィェン1
祇チー2
祈チー2
祉ヂー3
祊ボン1
祋ドゥェイ4
祌ヂョン4
祍レン4
祎イー1
祏シー2
祐ヨウ4
祑ヂー4
祒ティァォ2
祓フー2
祔フー4
祕ミィ4
祖ズー3
祗ヂー1
祘スゥァン4
祙メイ4
祚ズゥォ4
祛チュ1
祜フー4
祝ヂュ4
神シェン2
祟スェイ4
祠ツー2
祡チャイ2
祢ミィ2
祣リュ3
祤ユー3
祥シィァン2
祦ウー2
祧ティァォ1
票ピィァオ4
祩ヂュ4
祪グゥェイ3
祫シァ2
祬ヂー1
祭ジー4
祮ガオ4
祯ヂェン1
祰ガオ4
祱シュェイ4
祲ジン4
祳シェン4
祴ガイ1
祵クン3
祶デ4
祷ダオ3
祸フォ4
祹タオ2
祺チー2
祻グー4
祼グァン4
祽ズェイ4
祾リン2
祿ルー4
禀ビン3
禁ジン4
禂ダオ3
禃ヂー2
禄ルー4
禅チャン2
禆ビー4
禇ヂェ゛ァ3
禈フゥェイ1
禉ヨウ3
禊シー4
禋イン1
禌ズー1
禍フォ4
禎ヂェン1
福フー2
禐ユェン4
禑ウー2
禒シィェン3
禓ヤン2
禔ヂー1
禕イー1
禖メイ2
禗スー1
禘デ4
禙ベイ4
禚ヂュオ2
禛ヂェン1
禜ヨン3
禝ジー4
禞ガオ4
禟タン2
禠スー1
禡マー4
禢ター4
禣フー4
禤シュェン1
禥チー2
禦ユー4
禧シー3
禨ジー1
禩スー4
禪チャン2
禫ダン4
禬グゥェイ4
禭スェイ4
禮リー3
禯ノン2
禰ミィ2
禱ダオ3
禲リー4
禳ラン2
禴ユェ4
禵ティ2
禶ザン4
禷レイ4
禸ロウ2
禹ユー3
禺ユー2
离リー2
禼シェ4
禽チン2
禾フェ゛ァ2
禿トゥ1
秀シゥ4
私スー1
秂レン2
秃トゥ1
秄ズー3
秅チャ2
秆ガン3
秇イー4
秈シィェン1
秉ビン3
秊ニィェン2
秋チィゥ1
秌チィゥ1
种ヂョン3
秎フェン4
秏ハオ4
秐ユン2
科クェ゛ァ1
秒ミィァォ3
秓ヂー1
秔ジン1
秕ビー3
秖ヂー1
秗ユー4
秘ミィ4
秙クー4
秚バン4
秛ピー1
秜ニー2
秝リー4
秞ヨウ2
租ズー1
秠ピー1
秡ブォ2
秢リン2
秣ムー4
秤チォン4
秥ニィェン2
秦チン2
秧ヤン1
秨ズゥォ2
秩ヂー4
秪ヂー1
秫シュ2
秬ジュ4
秭ズー3
秮フォ2
积ジー1
称チォン1
秱トン2
秲ヂー4
秳フォ2
秴フェ゛ァ2
秵イン1
秶ズー1
秷ヂー4
秸ジェ1
秹レン3
秺ドゥ4
移イー2
秼ヂュ1
秽フゥェイ4
秾ノン2
秿フー4
稀シー1
稁ガオ3
稂ラン2
稃フー1
稄シュン4
稅シュェイ4
稆リュ3
稇クン3
稈ガン3
稉ジン1
稊ティ2
程チォン2
稌トゥ2
稍シャオ1
税シュェイ4
稏ヤー4
稐ルゥン3
稑ルー4
稒グー4
稓ズゥォ2
稔レン3
稕ヂュン4
稖バン4
稗バイ4
稘ジー1
稙ヂー1
稚ヂー4
稛クン3
稜ラン2
稝ポン2
稞クェ゛ァ1
稟ビン3
稠チョウ2
稡ズェイ4
稢ユー4
稣スー1
稤リュェ4
稥シィァン1
稦イー1
稧シー4
稨ビィェン3
稩ジー4
稪フー2
稫ピー4
稬ヌオ4
稭ジェ1
種ヂョン3
稯ゾン1
稰シュ3
稱チォン1
稲ダオ4
稳ウェン3
稴シィェン2
稵ズー1
稶ユー4
稷ジー4
稸シュ4
稹ヂェン3
稺ヂー4
稻ダオ4
稼ジャ4
稽ジー1
稾ガオ3
稿ガオ3
穀グー3
穁ロン2
穂スェイ4
穃ロン
穄ジー4
穅カン1
穆ムー4
穇ツァン3
穈メイ2
穉ヂー4
穊ジー4
穋ルー4
穌スー1
積ジー1
穎イン3
穏ウェン3
穐チィゥ1
穑スェ゛ァ4
穒フェ゛ァ4
穓イー4
穔フゥァン2
穕チィェ4
穖ジー3
穗スェイ4
穘シァォ1
穙プー2
穚ジャオ1
穛ヂュオ1
穜ヂョン3
穝ズェイ
穞リュ3
穟スェイ4
穠ノン2
穡スェ゛ァ4
穢フゥェイ4
穣ラン2
穤ヌオ4
穥ユー4
穦ピン1
穧ジー4
穨トゥェイ2
穩ウェン3
穪チォン1
穫フォ4
穬クゥァン4
穭リュ3
穮ビィャォ1
穯スェ゛ァ4
穰ラン2
穱ヂュオ1
穲リー2
穳ツァン2
穴シュェ2
穵ウァ1
究ジゥ1
穷チォン2
穸シー1
穹チォン2
空コン1
穻ユー1
穼シェン1
穽ジン3
穾イャォ4
穿チュァン1
窀ヂュン1
突トゥ1
窂ラオ2
窃チィェ4
窄ヂャイ3
窅イャォ3
窆ビィェン3
窇バオ2
窈イャォ3
窉ビン3
窊ウァ1
窋ヂュ2
窌ジャオ4
窍チィァォ4
窎ディァォ4
窏ウー1
窐グゥェイ1
窑イャォ2
窒ヂー4
窓チュゥァン1
窔イャォ4
窕ティァォ3
窖ジャオ4
窗チュゥァン1
窘ジィォン3
窙シァォ1
窚チォン2
窛コウ4
窜ツァン4
窝ウォ1
窞ダン4
窟クー1
窠クェ゛ァ1
窡ヂュオ2
窢シュ1
窣スー1
窤グァン1
窥クゥェイ1
窦ドウ4
窧ヂュオ
窨シュン1
窩ウォ1
窪ウァ1
窫ヤー4
窬ユー2
窭ジュ4
窮チォン2
窯イャォ2
窰イャォ2
窱ティァォ3
窲チャオ2
窳ユー3
窴ティェン2
窵ディァォ4
窶ジュ4
窷リィァォ4
窸シー1
窹ウー4
窺クゥェイ1
窻チュゥァン1
窼ヂャオ1
窽クァン3
窾クァン3
窿ロン2
竀チォン1
竁ツェイ4
竂リィァォ2
竃ザオ4
竄ツァン4
竅チィァォ4
竆チォン2
竇ドウ4
竈ザオ4
竉ロン3
竊チィェ4
立リー4
竌チュ4
竍シー2
竎フー4
竏チィェン1
竐チュ4
竑ホン2
竒チー2
竓ハオ2
竔シォン1
竕フェン1
竖シュ4
竗ミィァォ4
竘チュ3
站ヂャン4
竚ヂュ4
竛リン2
竜ロン2
竝ビン4
竞ジン4
竟ジン4
章ヂャン1
竡バイ3
竢スー4
竣ジュン4
竤ホン2
童トン2
竦ソン3
竧ジン4
竨ディァォ4
竩イー4
竪シュ4
竫ジン4
竬チュ3
竭ジェ2
竮ピン1
端ドゥァン1
竰リー2
竱ヂュァン3
竲ツォン2
竳ドン1
竴ツン1
竵ワィ1
競ジン4
竷カン3
竸ジン4
竹ヂュ2
竺ヂュ2
竻ラ4
竼ポン2
竽ユー2
竾チー2
竿ガン1
笀マン2
笁ヂュ2
笂ワン2
笃ドゥ3
笄ジー1
笅ジャオ3
笆バー1
笇スゥァン4
笈ジー2
笉チン3
笊ヂャオ4
笋スン3
笌ヤー2
笍ヂュェイ4
笎ユェン2
笏フー4
笐ハン2
笑シァォ4
笒cen2
笓ビー4
笔ビー3
笕ジィェン3
笖イー3
笗ドン1
笘シャン1
笙シォン1
笚ダー1
笛デ2
笜ヂュ2
笝ナー4
笞チー1
笟グー1
笠リー4
笡チィェ4
笢ミン3
笣バオ1
笤ティァォ2
笥スー4
符フー2
笧ツェ゛ァ4
笨ベン4
笩ファ2
笪ダー2
笫ズー3
第デ4
笭リン2
笮ゼェ゛ァ2
笯ヌー2
笰フー2
笱ゴウ3
笲ファン2
笳ジャ1
笴ガン3
笵ファン4
笶シー3
笷マオ3
笸ポォ3
笹ティ
笺ジィェン1
笻チォン2
笼ロン2
笽ミン3
笾ビィェン1
笿ルゥォ4
筀グゥェイ4
筁チュ1
筂チー2
筃イン1
筄イャォ4
筅シィェン3
筆ビー3
筇チォン2
筈クォ4
等ドン3
筊シァォ2
筋ジン1
筌チュェン2
筍スン3
筎ルー2
筏ファ2
筐クゥァン1
筑ヂュ4
筒トン3
筓ジー1
答ダー2
筕ハン2
策ツェ゛ァ4
筗ヂョン4
筘コウ4
筙ライ2
筚ビー4
筛シャイ1
筜ダン1
筝ヂォン1
筞ツェ゛ァ4
筟フー1
筠ユン2
筡トゥ2
筢パー2
筣リー2
筤ラン2
筥ジュ3
筦グァン3
筧ジィェン3
筨ハン2
筩トン2
筪シァ2
筫ヂー4
筬チォン2
筭スゥァン4
筮シー4
筯ヂュ4
筰ズゥォ2
筱シァォ3
筲シャオ1
筳ティン2
筴ツェ゛ァ4
筵イェン2
筶ガオ4
筷クァィ4
筸ガン1
筹チョウ2
筺クゥァン1
筻ガン4
筼ユン2
筽ゥオ
签チィェン1
筿シァォ3
简ジィェン3
箁ポウ2
箂ライ2
箃ゾウ1
箄ビー3
箅ビー4
箆ビー4
箇グェ゛ァ4
箈タイ2
箉グゥァイ3
箊ユー1
箋ジィェン1
箌ダオ4
箍グー1
箎チー2
箏ヂォン1
箐チン4
箑シャ4
箒ヂョウ3
箓ルー4
箔ブォ2
箕ジー1
箖リン2
算スゥァン4
箘ジュン4
箙フー2
箚ヂャ2
箛グー1
箜コン1
箝チィェン2
箞チィェン1
箟ジュン4
箠チュェイ2
管グァン3
箢ユェン1
箣ツェ゛ァ4
箤ズー2
箥ブォ3
箦ゼェ゛ァ2
箧チィェ4
箨トゥォ4
箩ルゥォ2
箪ダン1
箫シァォ1
箬ルォ4
箭ジィェン4
箮シュェン1
箯ビィェン1
箰スン3
箱シィァン1
箲シィェン3
箳ピン2
箴ヂェン1
箵シン1
箶フー2
箷イー2
箸ヂュ4
箹ユェ1
箺チュン1
箻リュ4
箼ウー1
箽ドン3
箾シュォ4
箿ジー2
節ジェ2
篁フゥァン2
篂シン1
篃メイ4
範ファン4
篅チュァン2
篆ヂュァン4
篇ピィェン1
篈フォン1
築ヂュ4
篊フゥァン2
篋チィェ4
篌ホウ2
篍チィゥ1
篎ミィァォ3
篏チィェン4
篐グー1
篑クゥェイ4
篒シー
篓ロウ3
篔ユン2
篕フェ゛ァ2
篖タン2
篗ユェ4
篘チョウ1
篙ガオ1
篚フェイ3
篛ルォ4
篜ヂォン1
篝ゴウ1
篞ニィェ4
篟チィェン4
篠シァォ3
篡ツァン4
篢ロン3
篣ポン2
篤ドゥ3
篥リー4
篦ビー4
篧ヂュオ2
篨チュ2
篩シャイ1
篪チー2
篫ヂュ4
篬チィァン1
篭ロン2
篮ラン2
篯ジィェン1
篰ブー4
篱リー2
篲フゥェイ4
篳ビー4
篴デ2
篵ツォン1
篶イェン1
篷ポン2
篸ツァン3
篹ヂュァン4
篺ピー2
篻ピィァオ3
篼ドウ1
篽ユー4
篾ミィェ4
篿トゥァン2
簀ゼェ゛ァ2
簁シャイ1
簂グゥェイ4
簃イー2
簄フー4
簅チャン3
簆コウ4
簇ツゥ4
簈ピン2
簉ザオ4
簊ジー1
簋グゥェイ3
簌スー4
簍ロウ3
簎ツェ゛ァ4
簏ルー4
簐ニィェン3
簑スォ1
簒ツァン4
簓ディァォ1
簔スォ1
簕ラ4
簖ドゥァン4
簗ヂュ4
簘シァォ1
簙ブォ2
簚ミィ4
簛シャイ1
簜ダン4
簝リィァォ2
簞ダン1
簟ディェン4
簠フー3
簡ジィェン3
簢ミン3
簣クゥェイ4
簤ダイ4
簥ジャオ1
簦ドン1
簧フゥァン2
簨スン3
簩ラオ2
簪ザン1
簫シァォ1
簬ルー4
簭シー4
簮ザン1
簯チー
簰パイ2
簱チー2
簲パイ2
簳ガン3
簴ジュ4
簵ルー4
簶ルー4
簷イェン2
簸ブォ3
簹ダン1
簺サイ4
簻ヂュア1
簼ゴウ1
簽チィェン1
簾リィェン2
簿ブー4
籀ヂョウ4
籁ライ4
籂シー
籃ラン2
籄クゥェイ4
籅ユー2
籆ユェ4
籇ハオ2
籈ヂェン1
籉タイ2
籊ティ4
籋ニィェ4
籌チョウ2
籍ジー2
籎イー2
籏チー2
籐トン2
籑ヂュァン4
籒ヂョウ4
籓ファン1
籔ソウ3
籕ヂョウ4
籖チィェン
籗ヂュオ2
籘トン2
籙ルー4
籚ルー2
籛ジィェン3
籜トゥォ4
籝イン2
籞ユー4
籟ライ4
籠ロン2
籡チィェ4
籢リィェン2
籣ラン2
籤チィェン1
籥ユェ4
籦ヂョン1
籧チュ2
籨リィェン2
籩ビィェン1
籪ドゥァン4
籫ズァン3
籬リー2
籭スー1
籮ルゥォ2
籯イン2
籰ユェ4
籱ヂュオ2
籲ユー4
米ミィ3
籴デ2
籵ファン2
籶シェン1
籷ヂェ゛ァ2
籸シェン1
籹ニュ3
籺フェ゛ァ2
类レイ4
籼シィェン1
籽ズー3
籾ニー2
籿ツン4
粀ヂャン4
粁チィェン1
粂ヂャイ1
粃ビー3
粄バン3
粅ウー4
粆シャ1
粇カン1
粈ロウ2
粉フェン3
粊ビー4
粋ツェイ4
粌イン
粍ヂェ゛ァ2
粎ミィ3
粏タイ
粐フー4
粑バー1
粒リー4
粓ガン1
粔ジュ4
粕ポォ4
粖ムー4
粗ツゥ1
粘ヂャン1
粙ヂョウ4
粚チー1
粛スー4
粜ティァォ4
粝リー4
粞シー1
粟スー4
粠ホン2
粡トン2
粢ズー1
粣ツェ゛ァ4
粤ユェ4
粥ヂョウ1
粦リン2
粧ヂュゥァン1
粨バイ3
粩ラオ1
粪フェン4
粫ェ゛ァー2
粬チュ1
粭フェ゛ァ2
粮リィァン2
粯シィェン4
粰フー2
粱リィァン2
粲ツァン4
粳ジン1
粴リー3
粵ユェ4
粶ルー4
粷ジュ2
粸チー2
粹ツェイ4
粺バイ4
粻ヂャン1
粼リン2
粽ゾン4
精ジン1
粿グゥォ3
糀ファ1
糁サン3
糂サン3
糃タン2
糄ビィェン3
糅ロウ2
糆ミィェン4
糇ホウ2
糈シュ3
糉ゾン4
糊フー2
糋ジィェン4
糌ザン1
糍ツー2
糎リー2
糏シェ4
糐フー1
糑ヌオ4
糒ベイ4
糓グー3
糔シゥ3
糕ガオ1
糖タン2
糗チィゥ3
糘ジャ1
糙ツァォ1
糚ヂュゥァン1
糛タン2
糜ミィ2
糝サン3
糞フェン4
糟ザオ1
糠カン1
糡ジィァン4
糢ムー2
糣サン3
糤サン3
糥ヌオ4
糦シー1
糧リィァン2
糨ジィァン4
糩クァィ4
糪ブォ4
糫ファン2
糬シュ3
糭ゾン4
糮シィェン4
糯ヌオ4
糰トゥァン2
糱ニィェ4
糲リー4
糳ズゥォ4
糴デ2
糵ニィェ4
糶ティァォ4
糷ラン4
糸ミィ4
糹スー1
糺ジゥ1
系シー4
糼ゴン1
糽ヂォン3
糾ジゥ1
糿ヨウ4
紀ジー4
紁チャ4
紂ヂョウ4
紃シュン2
約ユェ1
紅ホン2
紆ユー1
紇フェ゛ァ2
紈ワン2
紉レン4
紊ウェン3
紋ウェン2
紌チィゥ2
納ナー4
紎ズー1
紏トウ3
紐ニィゥ3
紑フォウ2
紒ジー4
紓シュ1
純チュン2
紕ピー1
紖ヂェン4
紗シャ1
紘ホン2
紙ヂー3
級ジー2
紛フェン1
紜ユン2
紝レン4
紞ダン3
紟ジン1
素スー4
紡ファン3
索スォ3
紣ツェイ4
紤ジゥ3
紥ザー1
紦バー
紧ジン3
紨フー1
紩ヂー4
紪チー1
紫ズー3
紬チョウ2
紭ホン2
紮ザー1
累レイ4
細シー4
紱フー2
紲シェ4
紳シェン1
紴ブォ1
紵ヂュ4
紶チュ1
紷リン2
紸ヂュ4
紹シャオ4
紺ガン4
紻ヤン3
紼フー2
紽トゥォ2
紾ヂェン3
紿ダイ4
絀チュ4
絁シー1
終ヂョン1
絃シィェン2
組ズー3
絅ジィォン1
絆バン4
絇チュ2
絈ムー4
絉シュ4
絊ズェイ4
絋クゥァン4
経ジン1
絍レン4
絎ハン2
絏シェ4
結ジェ2
絑ヂュ1
絒チョウ2
絓グァ4
絔バイ3
絕ジュェ2
絖クゥァン4
絗フー2
絘ツー4
絙ファン2
絚ゴン1
絛タオ1
絜ジェ2
絝クー4
絞ジャオ3
絟チュェン2
絠ガイ3
絡ルゥォ4
絢シュェン4
絣ボン1
絤シィェン4
絥フー2
給ゲイ3
絧ドン4
絨ロン2
絩ティァォ4
絪イン1
絫レイ3
絬シェ4
絭ジュェン4
絮シュ4
絯ガイ1
絰ディェ2
統トン3
絲スー1
絳ジィァン4
絴シィァン2
絵フゥェイ4
絶ジュェ2
絷ヂー2
絸ジィェン3
絹ジュェン4
絺チー1
絻ミィェン3
絼ヂェン4
絽リュ3
絾チォン2
絿チィゥ2
綀シュ1
綁バン3
綂トン3
綃シァォ1
綄ファン2
綅チン1
綆ゴン3
綇シゥ3
綈ティ2
綉トウ4
綊シェ2
綋ホン2
綌シー4
綍フー2
綎ティン1
綏スェイ1
綐ドゥェイ4
綑クン3
綒フー1
經ジン1
綔フー4
綕ヂー1
綖イェン2
綗ジィォン3
綘フォン2
継ジー4
続シュ4
綛レン3
綜ゾン1
綝チェン1
綞ドゥォ3
綟リー4
綠リュ4
綡リィァン2
綢チョウ2
綣チュェン3
綤シャオ4
綥チー2
綦チー2
綧ヂュン3
綨チー2
綩ワン3
綪チィェン4
綫シィェン4
綬ショウ4
維ウェイ2
綮チー3
綯タオ2
綰ワン3
綱ガン1
網ワン3
綳ボン1
綴ヂュェイ4
綵ツァィ3
綶グゥォ3
綷ツェイ4
綸ルゥン2
綹リィゥ3
綺チー3
綻ヂャン4
綼ビー4
綽チュォ4
綾リン2
綿ミィェン2
緀チー1
緁チィェ4
緂ティェン2
緃ゾン1
緄ガン3
緅ゾウ1
緆シー1
緇ズー1
緈シン4
緉リィァン3
緊ジン3
緋フェイ1
緌ルェイ2
緍ミン2
緎ユー4
総ゾン3
緐ファン2
緑リュ4
緒シュ4
緓イン1
緔シャン4
緕チー
緖シュ4
緗シィァン1
緘ジィェン1
緙クェ゛ァ4
線シィェン4
緛ルァン3
緜ミィェン2
緝ジー1
緞ドゥァン4
緟チョン2
締デ4
緡ミン2
緢ミィァォ2
緣ユェン2
緤シェ4
緥バオ3
緦スー1
緧チィゥ1
編ビィェン1
緩ファン3
緪ゴン1
緫ツォン1
緬ミィェン3
緭ウェイ4
緮フー4
緯ウェイ3
緰トウ2
緱ゴウ1
緲ミィァォ3
緳シェ2
練リィェン4
緵ゾン1
緶ビィェン4
緷ユン4
緸イン1
緹ティ2
緺グァ1
緻ヂー4
緼ユン4
緽チォン1
緾チャン2
緿ダイ4
縀シァ2
縁ユェン2
縂ゾン3
縃シュ1
縄シォン2
縅ウェイ1
縆ゴン1
縇シュェン1
縈イン2
縉ジン4
縊イー4
縋ヂュェイ4
縌ニー4
縍バン1
縎グー3
縏パン2
縐ヂョウ4
縑ジィェン1
縒ツー1
縓チュェン2
縔シュゥァン3
縕ユン4
縖シァ2
縗ツェイ1
縘シー1
縙ロン2
縚タオ1
縛フー4
縜ユン2
縝チェン1
縞ガオ3
縟ルー4
縠フー2
縡ザイ4
縢トン2
縣シィェン4
縤スー4
縥ヂェン3
縦ゾン4
縧タオ1
縨フゥァン3
縩ツァィ4
縪ビー4
縫フォン4
縬ツゥ4
縭リー2
縮スォ1
縯イェン3
縰シー3
縱ゾン4
縲レイ2
縳ジュェン4
縴チィェン4
縵マン4
縶ヂー2
縷リュ3
縸ムー4
縹ピィァオ3
縺リィェン2
縻ミィ2
縼シュェン4
總ゾン3
績ジー1
縿シャン1
繀スェイ4
繁ファン2
繂リュ4
繃ボン3
繄イー1
繅サオ1
繆モウ2
繇イャォ2
繈チィァン3
繉フン2
繊シィェン1
繋ジー4
繌シャ
繍シゥ4
繎ラン2
繏シュェン4
繐スェイ4
繑チィァォ1
繒ゾン1
繓ズゥォ3
織ヂー1
繕シャン4
繖サン3
繗リン2
繘ユー4
繙ファン1
繚リィァォ2
繛チュォ4
繜ズン1
繝ジィェン4
繞ラオ4
繟チャン3
繠ルェイ3
繡シゥ4
繢フゥェイ4
繣ファ4
繤ズァン3
繥シー1
繦チィァン3
繧ユン
繨ダー
繩シォン2
繪フゥェイ4
繫シー4
繬スェ゛ァ4
繭ジィェン3
繮ジィァン1
繯ファン2
繰ザオ3
繱ツォン1
繲シェ4
繳ジャオ3
繴ビー4
繵ダン4
繶イー4
繷ノン3
繸スェイ4
繹イー4
繺シャイ3
繻シュ1
繼ジー4
繽ビン1
繾チィェン3
繿ラン2
纀プー2
纁シュン1
纂ズァン3
纃チー2
纄ポン2
纅イャォ4
纆ムー4
纇レイ4
纈シェ2
纉ズァン3
纊クゥァン4
纋ヨウ1
續シュ4
纍レイ2
纎シィェン1
纏チャン2
纐ジャオ3
纑ルー2
纒チャン2
纓イン1
纔ツァィ2
纕ラン3
纖シィェン1
纗ズェイ1
纘ズァン3
纙ルゥォ4
纚リー2
纛ダオ4
纜ラン3
纝レイ2
纞リィェン4
纟スー1
纠ジゥ1
纡ユー1
红ホン2
纣ヂョウ4
纤シィェン1
纥グェ゛ァ1
约ユェ1
级ジー2
纨ワン2
纩クゥァン4
纪ジー4
纫レン4
纬ウェイ3
纭ユン2
纮ホン2
纯チュン2
纰ピー1
纱シャ1
纲ガン1
纳ナー4
纴レン4
纵ゾン4
纶ルゥン2
纷フェン1
纸ヂー3
纹ウェン2
纺ファン3
纻ヂュ4
纼ヂェン4
纽ニィゥ3
纾シュ1
线シィェン4
绀ガン4
绁シェ4
绂フー2
练リィェン4
组ズー3
绅シェン1
细シー4
织ヂー1
终ヂョン1
绉ヂョウ4
绊バン4
绋フー2
绌チュ4
绍シャオ4
绎イー4
经ジン1
绐ダイ4
绑バン3
绒ロン2
结ジェ2
绔クー4
绕ラオ4
绖ディェ2
绗ハン2
绘フゥェイ4
给ゲイ3
绚シュェン4
绛ジィァン4
络ルゥォ4
绝ジュェ2
绞ジャオ3
统トン3
绠ゴン3
绡シァォ1
绢ジュェン4
绣シゥ4
绤シー4
绥スェイ2
绦タオ1
继ジー4
绨ティ2
绩ジー4
绪シュ4
绫リン2
绬イン1
续シュ4
绮チー3
绯フェイ1
绰チュォ4
绱シャン4
绲ガン3
绳シォン2
维ウェイ2
绵ミィェン2
绶ショウ4
绷ボン1
绸チョウ2
绹タオ2
绺リィゥ3
绻チュェン3
综ゾン1
绽ヂャン4
绾ワン3
绿リュ4
缀ヂュェイ4
缁ズー1
缂クェ゛ァ4
缃シィァン1
缄ジィェン1
缅ミィェン3
缆ラン3
缇ティ2
缈ミィァォ3
缉ジー1
缊ユン1
缋フゥェイ4
缌スー1
缍ドゥォ3
缎ドゥァン4
缏ビィェン4
缐シィェン4
缑ゴウ1
缒ヂュェイ4
缓ファン3
缔デ4
缕リュ3
编ビィェン1
缗ミン2
缘ユェン2
缙ジン4
缚フー4
缛ルー4
缜ヂェン3
缝フォン4
缞ツェイ1
缟ガオ3
缠チャン2
缡リー2
缢イー4
缣ジィェン1
缤ビン1
缥ピィァオ1
缦マン4
缧レイ2
缨イン1
缩スォ1
缪モウ2
缫サオ1
缬シェ2
缭リィァォ2
缮シャン4
缯ゾン1
缰ジィァン1
缱チィェン3
缲チィァォ1
缳ファン2
缴ジャオ3
缵ズァン3
缶フォウ3
缷シェ4
缸ガン1
缹フォウ3
缺チュェ1
缻フォウ3
缼チー
缽ブォ1
缾ピン2
缿シィァン4
罀ヂャオ
罁ガン1
罂イン1
罃イン1
罄チン4
罅シァ4
罆グァン4
罇ズン1
罈タン2
罉チォン1
罊チー4
罋ウォン4
罌イン1
罍レイ2
罎タン2
罏ルー2
罐グァン4
网ワン3
罒ワン3
罓ガン1
罔ワン3
罕ハン3
罖ルゥォ2
罗ルゥォ2
罘フー2
罙シェン1
罚ファ2
罛グー1
罜ヂュ3
罝ジュ1
罞マオ2
罟グー3
罠ミン2
罡ガン1
罢バー4
罣グァ4
罤ティ2
罥ジュェン4
罦フー2
罧シェン4
罨イェン3
罩ヂャオ4
罪ズェイ4
罫グァ4
罬ヂュオ2
罭ユー4
置ヂー4
罯アン3
罰ファ2
罱ラン3
署シュ3
罳スー1
罴ピー2
罵マー4
罶リィゥ3
罷バー4
罸ファ2
罹リー2
罺チャオ2
罻ウェイ4
罼ビー4
罽ジー4
罾ゾン1
罿チョン1
羀リィゥ3
羁ジー1
羂ジュェン4
羃ミィ4
羄ヂャオ4
羅ルゥォ2
羆ピー2
羇ジー1
羈ジー1
羉ルゥァン2
羊ヤン2
羋ミィ3
羌チィァン1
羍ダー2
美メイ3
羏ヤン2
羐ヨウ3
羑ヨウ3
羒フェン2
羓バー1
羔ガオ1
羕ヤン4
羖グー3
羗チィァン1
羘ザン1
羙ガオ1
羚リン2
羛イー4
羜ヂュ4
羝デ1
羞シゥ1
羟チィァン3
羠イー2
羡シィェン4
羢ロン2
羣チュン2
群チュン2
羥チィァン3
羦ファン2
羧スォ1
羨シィェン4
義イー4
羪ヤン
羫チィァン1
羬チィェン2
羭ユー2
羮ゴン1
羯ジェ2
羰タン1
羱ユェン2
羲シー1
羳ファン2
羴シャン1
羵フェン2
羶シャン1
羷リィェン3
羸レイ2
羹ゴン1
羺ノウ2
羻チィァン4
羼チャン4
羽ユー3
羾ゴン4
羿イー4
翀チョン1
翁ウォン1
翂フェン1
翃ホン2
翄チー4
翅チー4
翆ツェイ4
翇フー2
翈シァ2
翉ベン3
翊イー4
翋ラー1
翌イー4
翍ピー1
翎リン2
翏リィゥ4
翐ヂー4
翑チュ2
習シー2
翓シェ2
翔シィァン2
翕シー1
翖シー1
翗クェ゛ァ2
翘チィァォ4
翙フゥェイ4
翚フゥェイ1
翛シァォ1
翜シャ4
翝ホン2
翞ジィァン1
翟デ2
翠ツェイ4
翡フェイ3
翢ダオ4
翣シャ4
翤チー4
翥ヂュ4
翦ジィェン3
翧シュェン1
翨チー4
翩ピィェン1
翪ゾン1
翫ワン2
翬フゥェイ1
翭ホウ2
翮フェ゛ァ2
翯フェ゛ァ4
翰ハン4
翱アオ2
翲ピィァオ1
翳イー4
翴リィェン2
翵ホウ2
翶アオ2
翷リン2
翸ペン3
翹チィァォ4
翺アオ2
翻ファン1
翼イー4
翽フゥェイ4
翾シュェン1
翿ダオ4
耀イャォ4
老ラオ3
耂ラオ3
考カオ3
耄マオ4
者ヂェ゛ァ3
耆チー2
耇ゴウ3
耈ゴウ3
耉ゴウ3
耊ディェ2
耋ディェ2
而ェ゛ァー2
耍シュァ3
耎ルァン3
耏ナイ4
耐ナイ4
耑ドゥァン1
耒レイ3
耓ティン1
耔ズー3
耕ゴン1
耖チャオ4
耗ハオ4
耘ユン2
耙バー4
耚ピー1
耛イー2
耜スー4
耝チュ4
耞ジャ1
耟ジュ4
耠フォ1
耡チュ2
耢ラオ4
耣ルゥン3
耤ジー2
耥タン1
耦オウ3
耧ロウ2
耨ノウ4
耩ジィァン3
耪パン3
耫ヂャ2
耬ロウ2
耭ジー1
耮ラオ4
耯フォ4
耰ヨウ1
耱ムー4
耲フゥァイ2
耳ェ゛ァー3
耴イー4
耵ディン1
耶イェ2
耷ダー1
耸ソン3
耹チン2
耺ユン2
耻チー3
耼ダン1
耽ダン1
耾ホン2
耿ゴン3
聀ヂー2
聁パン4
聂ニィェ4
聃ダン1
聄ヂェン3
聅チェ゛ァ4
聆リン2
聇ヂォン1
聈ヨウ3
聉ウァ4
聊リィァォ2
聋ロン2
职ヂー2
聍ニン2
聎ティァォ1
聏ェ゛ァー2
聐ヤー4
聑ティェ1
聒グァ1
聓シュ4
联リィェン2
聕ハオ4
聖シォン4
聗リィェ4
聘ピン4
聙ジン1
聚ジュ4
聛ビー3
聜デ3
聝グゥォ2
聞ウェン2
聟シュ4
聠ピン1
聡ツォン1
聢ディン4
聣ニー2
聤ティン2
聥ジュ3
聦ツォン1
聧クゥェイ1
聨リィェン2
聩クゥェイ4
聪ツォン1
聫リィェン2
聬ウォン3
聭クゥェイ4
聮リィェン2
聯リィェン2
聰ツォン1
聱アオ2
聲シォン1
聳ソン3
聴ティン1
聵クゥェイ4
聶ニィェ4
職ヂー2
聸ダン1
聹ニン2
聺チィェ2
聻ニー3
聼ティン1
聽ティン1
聾ロン2
聿ユー4
肀ユー4
肁ヂャオ4
肂スー4
肃スー4
肄イー4
肅スー4
肆スー4
肇ヂャオ4
肈ヂャオ4
肉ロウ4
肊イー4
肋ラ1
肌ジー1
肍チィゥ2
肎ケン3
肏ツァォ4
肐グェ゛ァ1
肑ブォ2
肒ファン4
肓フゥァン1
肔チー3
肕レン4
肖シァォ4
肗ルー3
肘ヂョウ3
肙ユェン4
肚ドゥ4
肛ガン1
肜ロン2
肝ガン1
肞チャ1
肟ウォ4
肠chang2
股グー3
肢ヂー1
肣ハン2
肤フー1
肥フェイ2
肦フェン2
肧ペイ1
肨パン4
肩ジィェン1
肪ファン2
肫ヂュン1
肬ヨウ2
肭ナー4
肮アン1
肯ケン3
肰ラン2
肱ゴン1
育ユー4
肳ウェン3
肴イャォ2
肵チー2
肶ピー2
肷チィェン3
肸シー1
肹シー1
肺フェイ4
肻ケン3
肼ジン3
肽タイ4
肾シェン4
肿ヂョン3
胀ヂャン4
胁シェ2
胂シェン4
胃ウェイ4
胄ヂョウ4
胅ディェ2
胆ダン3
胇フェイ4
胈バー2
胉ブォ2
胊チュ2
胋ティェン2
背ベイ4
胍グァ1
胎タイ1
胏ズー3
胐フェイ3
胑ヂー1
胒ニー4
胓ピン2
胔ズー4
胕フー3
胖パン4
胗ヂェン1
胘シィェン2
胙ズゥォ4
胚ペイ1
胛ジャ3
胜シォン4
胝ヂー1
胞バオ1
胟ムー3
胠チュ1
胡フー2
胢クェ゛ァ1
胣チー3
胤イン4
胥シュ1
胦ヤン1
胧ロン2
胨ドン4
胩カー3
胪ルー2
胫ジン4
胬ヌー3
胭イェン1
胮パン1
胯クァ4
胰イー2
胱グゥァン1
胲ファン3
胳グェ゛ァ1
胴ドン4
胵チー1
胶ジャオ1
胷シィォン1
胸シィォン1
胹ェ゛ァー2
胺アン4
胻ホン2
胼ピィェン2
能ノン2
胾ズー4
胿グゥェイ1
脀チォン2
脁ティァォ3
脂ヂー1
脃ツェイ4
脄メイ2
脅シェ2
脆ツェイ4
脇シェ2
脈マイ4
脉マイ4
脊ジー2
脋シェ2
脌ニン
脍クァィ4
脎サー4
脏ザン4
脐チー2
脑ナオ3
脒ミィ3
脓ノン2
脔ルゥァン2
脕ワン4
脖ブォ2
脗ウェン3
脘ワン3
脙シゥ1
脚ジャオ3
脛ジン4
脜ヨウ3
脝ホン1
脞ツゥォ3
脟リィェ4
脠シャン1
脡ティン3
脢メイ2
脣チュン2
脤シェン4
脥チィェン3
脦ディ
脧ジュェン1
脨ツゥ4
脩シゥ1
脪シン4
脫トゥォ1
脬パオ1
脭チォン2
脮ネイ3
脯プー2
脰ドウ4
脱トゥォ1
脲ニィァォ4
脳ナオ3
脴ピー3
脵グー3
脶ルゥォ2
脷リー4
脸リィェン3
脹ヂャン4
脺ツェイ4
脻ジェ1
脼リィァン3
脽シュェイ2
脾ピー2
脿ビィャォ1
腀ルゥン2
腁ピィェン2
腂レイ3
腃クゥェイ4
腄チュェイ2
腅ダン4
腆ティェン3
腇ネイ3
腈ジン1
腉ナイ2
腊ラー4
腋イェ4
腌イェン1
腍レン4
腎シェン4
腏チュォ4
腐フー3
腑フー3
腒ジュ1
腓フェイ2
腔チィァン1
腕ワン4
腖ドン4
腗ピー2
腘グゥォ2
腙ゾン1
腚ディン4
腛ウォ4
腜メイ2
腝ニー2
腞ヂュァン4
腟チー4
腠コゥ4
腡ルゥォ2
腢オウ3
腣デ4
腤アン1
腥シン1
腦ナオ3
腧シュ4
腨シュァン4
腩ナン3
腪ユン4
腫ヂョン3
腬ロウ2
腭ェ゛ァ4
腮サイ1
腯トゥ2
腰イャォ1
腱ジィェン4
腲ウェイ3
腳ジャオ3
腴ユー2
腵ジャ1
腶ドゥァン4
腷ビー4
腸chang2
腹フー4
腺シィェン4
腻ニー4
腼ミィェン3
腽ウァ4
腾トン2
腿トゥェイ3
膀バン3
膁チィェン3
膂リュ3
膃ウァ4
膄ショウ4
膅タン2
膆スー4
膇ヂュェイ4
膈グェ゛ァ2
膉イー4
膊ブォ2
膋リィァォ2
膌ジー2
膍ピー2
膎シェ2
膏ガオ1
膐リュ3
膑ビン4
膒オウ1
膓chang2
膔ルー4
膕グゥォ2
膖パン1
膗チュァイ2
膘ビィャォ1
膙ジィァン3
膚フー1
膛タン2
膜ムー2
膝シー1
膞ヂュァン1
膟リュ4
膠ジャオ1
膡イン4
膢リュ2
膣ヂー4
膤シュェ3
膥ツン1
膦リン4
膧トン2
膨ポン2
膩ニー4
膪チュァイ4
膫リィァォ2
膬ツェイ4
膭グゥェイ1
膮シァォ1
膯トン1
膰ファン2
膱ヂー2
膲ジャオ1
膳シャン4
膴フー1
膵ツェイ4
膶ルン4
膷シィァン1
膸スェイ3
膹フェン4
膺イン1
膻シャン1
膼ヂュア1
膽ダン3
膾クァィ4
膿ノン2
臀トゥン2
臁リィェン2
臂ビー4
臃ヨン1
臄ジュェ2
臅チュ4
臆イー4
臇ジュェン3
臈ラー4
臉リィェン3
臊サオ1
臋トゥン2
臌グー3
臍チー2
臎ツェイ4
臏ビン4
臐シュン1
臑ナオ4
臒ウォ4
臓ザン4
臔シィェン4
臕ビィャォ1
臖シン4
臗クァン1
臘ラー4
臙イェン1
臚ルー2
臛フォ4
臜ザー1
臝ルゥォ3
臞チュ2
臟ザン4
臠ルゥァン2
臡ニー2
臢ザー1
臣チェン2
臤チィェン1
臥ウォ4
臦グゥァン4
臧ザン1
臨リン2
臩グゥァン3
自ズー4
臫ジャオ3
臬ニィェ4
臭チョウ4
臮ジー4
臯ガオ1
臰チョウ4
臱ミィェン2
臲ニィェ4
至ヂー4
致ヂー4
臵グェ゛ァ2
臶ジィェン4
臷ディェ2
臸ヂー1
臹シゥ1
臺タイ2
臻ヂェン1
臼ジゥ4
臽シィェン4
臾ユー2
臿チャ1
舀イャォ3
舁ユー2
舂チョン1
舃シー4
舄シー4
舅ジゥ4
舆ユー2
與ユー3
興シン4
舉ジュ3
舊ジゥ4
舋シン4
舌シェ゛ァ2
舍シェ゛ァ3
舎シェ゛ァ4
舏ジゥ3
舐シー4
舑タン1
舒シュ1
舓シー4
舔ティェン3
舕タン4
舖プー4
舗プー4
舘グァン3
舙ファ4
舚ティェン4
舛チュァン3
舜シュン4
舝シァ2
舞ウー3
舟ヂョウ1
舠ダオ1
舡チュァン2
舢シャン1
舣イー3
舤ファン2
舥パー1
舦タイ4
舧ファン2
舨バン3
舩チュァン2
航ハン2
舫ファン3
般バン1
舭ビー3
舮ルー2
舯ヂョン1
舰ジィェン4
舱ツァン1
舲リン2
舳ヂュ2
舴ゼェ゛ァ2
舵ドゥォ4
舶ブォ2
舷シィェン2
舸グェ゛ァ3
船チュァン2
舺シァ2
舻ルー2
舼チォン2
舽パン2
舾シー1
舿クァ1
艀フー2
艁ザオ4
艂フォン2
艃リー2
艄シャオ1
艅ユー2
艆ラン2
艇ティン3
艈ユー4
艉ウェイ3
艊ブォ2
艋モン3
艌ニィェン4
艍ジュ1
艎フゥァン2
艏ショウ3
艐クェ゛ァ4
艑ビィェン4
艒ムー4
艓ディェ2
艔ダオ4
艕バン4
艖チャ1
艗イー4
艘ソウ1
艙ツァン1
艚ツァォ2
艛ロウ2
艜ダイ4
艝シュェ3
艞イャォ4
艟チョン1
艠ドン1
艡ダン1
艢チィァン2
艣ルー3
艤イー3
艥ジー2
艦ジィェン4
艧フォ4
艨モン2
艩チー2
艪ルー3
艫ルー2
艬チャン2
艭シュゥァン1
艮ゲン3
良リィァン2
艰ジィェン1
艱ジィェン1
色スェ゛ァ4
艳イェン4
艴フー2
艵ピン1
艶イェン4
艷イェン4
艸ツァォ3
艹ツァォ3
艺イー4
艻ラ4
艼ティン1
艽ジャオ1
艾ェ゛ァ4
艿ナイ3
芀ティァォ2
芁ジャオ1
节ジェ2
芃ポン2
芄ワン2
芅イー4
芆チャイ1
芇ミィェン2
芈ミィ3
芉ガン1
芊チィェン1
芋ユー4
芌ユー4
芍シャオ2
芎チォン1
芏ドゥ4
芐フー4
芑チー3
芒マン2
芓ズー4
芔フゥェイ4
芕スェイ1
芖ヂー4
芗シィァン1
芘ピー2
芙フー2
芚トゥン2
芛ウェイ3
芜ウー2
芝ヂー1
芞チー4
芟シャン1
芠ウェン2
芡チィェン4
芢レン2
芣フー2
芤コウ1
芥ジェ4
芦ルー2
芧シュ4
芨ジー1
芩チン2
芪チー2
芫イェン2
芬フェン1
芭バー1
芮ルェイ4
芯シン1
芰ジー4
花ファ1
芲ファ1
芳ファン1
芴ウー4
芵ジュェ2
芶ゴウ3
芷ヂー3
芸ユン2
芹チン2
芺アオ3
芻チュ2
芼マオ4
芽ヤー2
芾フェイ4
芿ロン4
苀ハン2
苁ツォン1
苂イン2
苃ヨウ3
苄ビィェン4
苅イー4
苆チィェ1
苇ウェイ3
苈リー4
苉ピー3
苊ェ゛ァ4
苋シィェン4
苌chang2
苍ツァン1
苎ヂュ4
苏スー1
苐ティ2
苑ユェン4
苒ラン3
苓リン2
苔タイ2
苕シャオ2
苖デ2
苗ミィァォ2
苘チン3
苙リー4
苚ヨン4
苛クェ゛ァ1
苜ムー4
苝ベイ4
苞バオ1
苟ゴウ3
苠ミン2
苡イー3
苢イー3
苣ジュ4
苤ピェ3
若ルォ4
苦クー3
苧ニン2
苨ニー3
苩ブォ2
苪ビン3
苫シャン1
苬シゥ2
苭イャォ3
苮シィェン1
苯ベン3
苰ホン2
英イン1
苲ヂャ3
苳ドン1
苴ジュ1
苵ディェ2
苶ニィェ2
苷ガン1
苸フー1
苹ピン2
苺メイ2
苻フー2
苼シォン1
苽グー1
苾ビー4
苿ウェイ4
茀フー2
茁ヂュオ2
茂マオ4
范ファン4
茄ジャ1
茅マオ2
茆マオ2
茇バー2
茈ツー2
茉ムー4
茊ズー1
茋ヂー3
茌チー2
茍ジー4
茎ジン1
茏ロン2
茐ツォン1
茑ニィァォ3
茒ユェン2
茓シュェ2
茔イン2
茕チォン2
茖グェ゛ァ2
茗ミン2
茘リー4
茙ロン2
茚イン4
茛ゲン4
茜チィェン4
茝チャイ3
茞チェン2
茟ユー4
茠ハオ1
茡ズー4
茢リィェ4
茣ウー2
茤ジー4
茥グゥェイ1
茦ツー4
茧ジィェン3
茨ツー2
茩ゴウ4
茪グゥァン1
茫マン2
茬チャ2
茭ジャオ1
茮ジャオ1
茯フー2
茰ユー2
茱ヂュ1
茲ズー1
茳ジィァン1
茴フゥェイ2
茵イン1
茶チャ2
茷ファ2
茸ロン1
茹ルー2
茺チョン1
茻マン3
茼トン2
茽ヂョン4
茾チィェン1
茿ヂュ2
荀シュン2
荁ファン2
荂フー1
荃チュェン2
荄ガイ1
荅ダー1
荆ジン1
荇シン4
荈チュァン3
草ツァォ3
荊ジン1
荋ェ゛ァー2
荌アン4
荍チィァォ2
荎チー2
荏レン3
荐ジィェン4
荑ティ2
荒フゥァン1
荓ピン2
荔リー4
荕ジン1
荖ラオ3
荗シュ4
荘ヂュゥァン1
荙ダー2
荚ジャ2
荛ラオ2
荜ビー4
荝ツェ゛ァ4
荞チィァォ2
荟フゥェイ4
荠ジー4
荡ダン4
荢ズー4
荣ロン2
荤フン1
荥シン2
荦ルゥォ4
荧イン2
荨シュン2
荩ジン4
荪スン1
荫イン1
荬マイ3
荭ホン2
荮ヂョウ4
药イャォ4
荰ドゥ4
荱ウェイ3
荲リー2
荳ドウ4
荴フー1
荵レン3
荶イン2
荷フェ゛ァ2
荸ビー2
荹ブー4
荺ユン3
荻デ2
荼トゥ2
荽スェイ1
荾スェイ1
荿チォン2
莀チェン2
莁ウー2
莂ビィェ2
莃シー1
莄ゴン3
莅リー4
莆プー2
莇ヂュ4
莈ムー4
莉リー4
莊ヂュゥァン1
莋ズゥォ2
莌トゥォ1
莍チィゥ2
莎シャ1
莏スォ1
莐チェン2
莑ポン2
莒ジュ3
莓メイ2
莔モン2
莕シン4
莖ジン1
莗チェ゛ァ1
莘シェン1
莙ジュン1
莚イェン2
莛ティン2
莜ヨウ2
莝ツゥォ4
莞グァン3
莟ハン4
莠ヨウ3
莡ツゥォ4
莢ジャ2
莣ワン2
莤スー4
莥ニィゥ3
莦シャオ1
莧シィェン4
莨ラン4
莩フー2
莪ェ゛ァ2
莫ムー4
莬ウェン4
莭ジェ2
莮ナン2
莯ムー4
莰カン3
莱ライ2
莲リィェン2
莳シー2
莴ウォ1
莵トゥ4
莶シィェン1
获フォ4
莸ヨウ2
莹イン2
莺イン1
莻ゴン4
莼チュン2
莽マン3
莾マン3
莿ツー4
菀ワン3
菁ジン1
菂デ4
菃チュ2
菄ドン1
菅ジィェン1
菆ゾウ1
菇グー1
菈ラー1
菉ルー4
菊ジュ2
菋ウェイ4
菌ジュン1
菍ニィェ4
菎クン1
菏フェ゛ァ2
菐プー2
菑ザイ1
菒ガオ3
菓グゥォ3
菔フー2
菕ルゥン2
菖chang1
菗チョウ2
菘ソン1
菙チュェイ2
菚ヂャン4
菛メン2
菜ツァィ4
菝バー2
菞リー2
菟トゥ2
菠ブォ1
菡ハン4
菢バオ4
菣チン4
菤ジュェン3
菥シー1
菦チン2
菧デ3
菨ジェ1
菩プー2
菪ダン4
菫ジン3
菬チィァォ2
菭タイ2
菮ゴン1
華ファ2
菰グー1
菱リン2
菲フェイ1
菳チン2
菴アン1
菵ワン3
菶ボン3
菷ヂョウ3
菸イェン1
菹ジュ1
菺ジィェン1
菻リン3
菼タン3
菽シュ1
菾ティェン2
菿ダオ4
萀フー3
萁チー2
萂フェ゛ァ2
萃ツェイ4
萄タオ2
萅チュン1
萆ビー4
萇chang2
萈ファン2
萉フェイ4
萊ライ2
萋チー1
萌モン2
萍ピン2
萎ウェイ1
萏ダン4
萐シャ4
萑ファン2
萒イェン3
萓イー2
萔ティァォ2
萕チー2
萖ワン3
萗ツェ゛ァ4
萘ナイ4
萙ヂェン3
萚トゥォ4
萛ジゥ1
萜ティェ1
萝ルゥォ2
萞ビー4
萟イー4
萠パン1
萡ブォ
萢パオ1
萣ディン4
萤イン2
营イン2
萦イン2
萧シァォ1
萨サー4
萩チィゥ1
萪クェ゛ァ1
萫シィァン4
萬ワン4
萭ユー3
萮ユー2
萯フー4
萰リィェン4
萱シュェン1
萲シュェン1
萳ナン3
萴ツェ゛ァ4
萵ウォ1
萶チュン3
萷シァォ1
萸ユー2
萹ビィェン3
萺マオ4
萻アン1
萼ェ゛ァ4
落ルゥォ4
萾イン2
萿クォ4
葀クォ4
葁ジィァン1
葂ミィェン3
葃ズゥォ4
葄ズゥォ4
葅ズー1
葆バオ3
葇ロウ2
葈シー3
葉イェ4
葊アン1
葋チュ2
葌ジィェン1
葍フー2
葎リュ4
葏ジン1
葐ペン2
葑フォン1
葒ホン2
葓ホン2
葔ホウ2
葕イェン4
葖トゥ1
著ヂュ4
葘ズー1
葙シィァン1
葚レン4
葛グェ゛ァ2
葜チィァ1
葝チン2
葞ミィ3
葟フゥァン2
葠シェン1
葡プー2
葢ガイ4
董ドン3
葤ヂョウ4
葥ジィェン4
葦ウェイ3
葧ブォ2
葨ウェイ1
葩パー1
葪ジー4
葫フー2
葬ザン4
葭ジャ1
葮ドゥァン4
葯イャォ4
葰スェイ1
葱ツォン1
葲チュェン2
葳ウェイ1
葴ヂェン1
葵クゥェイ2
葶ティン2
葷フン1
葸シー3
葹シー1
葺チー4
葻ラン2
葼ゾン1
葽イャォ1
葾ユェン1
葿メイ2
蒀ユン1
蒁シュ4
蒂デ4
蒃ヂュァン4
蒄グァン1
蒅ラン3
蒆シュェ1
蒇チャン3
蒈カイ3
蒉クゥェイ4
蒊ファ1
蒋ジィァン3
蒌ロウ2
蒍ウェイ3
蒎パイ4
蒏ヨウ
蒐ソウ1
蒑イン1
蒒シー1
蒓チュン2
蒔シー2
蒕ユン1
蒖ヂェン1
蒗ラン4
蒘ルー2
蒙モン2
蒚リー4
蒛チュェ1
蒜スゥァン4
蒝ユェン2
蒞リー4
蒟ジュ3
蒠シー1
蒡バン4
蒢チュ2
蒣シュ2
蒤トゥ2
蒥リィゥ2
蒦フォ4
蒧ディェン3
蒨チィェン4
蒩ズー1
蒪ポォ4
蒫ツゥォ2
蒬ユェン1
蒭チュ2
蒮ユー4
蒯クァィ3
蒰パン2
蒱プー2
蒲プー2
蒳ナー4
蒴シュォ4
蒵シー2
蒶フェン2
蒷ユン2
蒸ヂォン1
蒹ジィェン1
蒺ジー2
蒻ルォ4
蒼ツァン1
蒽エン1
蒾ミィ2
蒿ハオ1
蓀スン1
蓁ヂェン1
蓂ミン2
蓃ソウ1
蓄シュ4
蓅リィゥ2
蓆シー2
蓇グー3
蓈ラン2
蓉ロン2
蓊ウォン3
蓋ガイ4
蓌ツゥォ4
蓍シー1
蓎タン2
蓏ルゥォ3
蓐ルー4
蓑スォ1
蓒シュェン1
蓓ベイ4
蓔イャォ3
蓕グゥェイ4
蓖ビー4
蓗ゾン3
蓘ガン3
蓙ズゥォ4
蓚ティァォ2
蓛ツェ゛ァ4
蓜ペイ4
蓝ラン2
蓞ダン4
蓟ジー4
蓠リー2
蓡シェン1
蓢ラン3
蓣ユー4
蓤リン2
蓥イン2
蓦ムー4
蓧ディァォ4
蓨ティァォ2
蓩マオ3
蓪トン1
蓫チュ4
蓬ポン2
蓭アン1
蓮リィェン2
蓯ツォン1
蓰シー3
蓱ピン2
蓲チィゥ1
蓳ジン3
蓴チュン2
蓵ジェ2
蓶ウェイ2
蓷トゥェイ1
蓸ツァォ2
蓹ユー4
蓺イー4
蓻ズー2
蓼リィァォ3
蓽ビー4
蓾ルー3
蓿シュ
蔀ブー4
蔁ヂャン1
蔂レイ2
蔃チィァン2
蔄マン4
蔅イェン2
蔆リン2
蔇ジー4
蔈ビィャォ1
蔉ガン3
蔊ハン3
蔋デ2
蔌スー4
蔍ルー4
蔎シェ゛ァ4
蔏シャン1
蔐デ2
蔑ミィェ4
蔒シュン1
蔓マン4
蔔ブォ2
蔕デ4
蔖ツゥォ2
蔗ヂェ゛ァ4
蔘シェン1
蔙シュェン4
蔚ウェイ4
蔛フー2
蔜アオ2
蔝ミィ3
蔞ロウ2
蔟ツゥ4
蔠ヂョン1
蔡ツァィ4
蔢ポォ2
蔣ジィァン3
蔤ミィ4
蔥ツォン1
蔦ニィァォ3
蔧フゥェイ4
蔨ジュェン4
蔩イン2
蔪ジィェン4
蔫ニィェン1
蔬シュ1
蔭イン1
蔮グゥォ2
蔯チェン2
蔰フー4
蔱シャ1
蔲コウ4
蔳チィェン4
蔴マー2
蔵ザン1
蔶ゼェ゛ァ2
蔷チィァン2
蔸ドウ1
蔹リィェン3
蔺リン4
蔻コウ4
蔼ェ゛ァ3
蔽ビー4
蔾リー2
蔿ウェイ3
蕀ジー2
蕁チィェン2
蕂シォン4
蕃ファン1
蕄モン2
蕅オウ3
蕆チャン3
蕇ディェン3
蕈シュン4
蕉ジャオ1
蕊ルェイ3
蕋ルェイ3
蕌レイ3
蕍ユー2
蕎チィァォ2
蕏チュ2
蕐ファ2
蕑ジィェン1
蕒マイ3
蕓ユン2
蕔バオ1
蕕ヨウ2
蕖チュ2
蕗ルー4
蕘ラオ2
蕙フゥェイ4
蕚ェ゛ァ4
蕛ティ2
蕜フェイ3
蕝ジュェ2
蕞ズェイ4
蕟ファ4
蕠ルー2
蕡フェン2
蕢クゥェイ4
蕣シュン4
蕤ルェイ2
蕥ヤー3
蕦シュ1
蕧フー4
蕨ジュェ2
蕩ダン4
蕪ウー2
蕫ドン3
蕬スー1
蕭シァォ1
蕮シー4
蕯ロン2
蕰ウェン1
蕱シャオ1
蕲チー2
蕳ジィェン1
蕴ユン4
蕵スン1
蕶リン2
蕷ユー4
蕸シァ2
蕹ウォン4
蕺ジー2
蕻ホン2
蕼スー4
蕽ノン2
蕾レイ3
蕿シュェン1
薀ユン4
薁ユー4
薂シー2
薃ハオ4
薄バオ2
薅ハオ1
薆ェ゛ァ4
薇ウェイ1
薈フゥェイ4
薉フゥェイ4
薊ジー4
薋ツー2
薌シィァン1
薍ワン4
薎ミィェ4
薏イー4
薐ラン2
薑ジィァン1
薒ツァン4
薓シェン1
薔チィァン2
薕リィェン2
薖クェ゛ァ1
薗ユェン2
薘ダー2
薙ティ4
薚タン1
薛シュェ1
薜ビー4
薝ヂャン1
薞スン1
薟シィェン1
薠ファン2
薡ディン3
薢シェ4
薣グー3
薤シェ4
薥シュ3
薦ジィェン4
薧ハオ1
薨ホン1
薩サー4
薪シン1
薫シュン1
薬イャォ4
薭バイ4
薮ソウ3
薯シュ3
薰シュン1
薱ドゥェイ4
薲ピン2
薳ウェイ3
薴ニン2
薵チョウ2
薶マイ2
薷ルー2
薸ピィァオ2
薹タイ2
薺ジー4
薻ザオ3
薼チェン2
薽ヂェン1
薾ェ゛ァー3
薿ニー3
藀イン2
藁ガオ3
藂ツォン2
藃シァォ1
藄チー2
藅ファ2
藆ジィェン3
藇シュ4
藈クゥェイ2
藉ジー2
藊ビィェン3
藋ディァォ4
藌ミィ4
藍ラン2
藎ジン4
藏ツァン2
藐ミィァォ3
藑チォン2
藒チィェ4
藓シィェン3
藔リィァォ2
藕オウ3
藖シィェン2
藗スー4
藘リュ2
藙イー4
藚シュ4
藛シェ3
藜リー2
藝イー4
藞ラー3
藟レイ3
藠ジャオ4
藡デ2
藢ヂー3
藣ベイ1
藤トン2
藥イャォ4
藦ムー4
藧ファン4
藨ビィャォ1
藩ファン1
藪ソウ3
藫タン2
藬トゥェイ1
藭チォン2
藮チィァォ2
藯ウェイ4
藰リィゥ2
藱フゥェイ4
藲オウ1
藳ガオ3
藴ユン4
藵バオ3
藶リー4
藷シュ3
藸チュ2
藹ェ゛ァ3
藺リン4
藻ザオ3
藼シュェン1
藽チン4
藾ライ4
藿フォ4
蘀トゥォ4
蘁ウー4
蘂ルェイ3
蘃ルェイ3
蘄チー2
蘅ホン2
蘆ルー2
蘇スー1
蘈トゥェイ2
蘉モン2
蘊ユン4
蘋ピン2
蘌ユー3
蘍シュン1
蘎ジー4
蘏ジィォン1
蘐シュェン1
蘑ムー2
蘒チィゥ1
蘓スー1
蘔ジィォン1
蘕ポン2
蘖ニィェ4
蘗ブォ4
蘘ラン2
蘙イー4
蘚シィェン3
蘛ユー2
蘜ジュ2
蘝リィェン3
蘞リィェン3
蘟イン3
蘠チィァン2
蘡イン1
蘢ロン2
蘣トウ3
蘤ファ1
蘥ユェ4
蘦リン2
蘧チュ2
蘨イャォ2
蘩ファン2
蘪メイ2
蘫ハン4
蘬クゥェイ1
蘭ラン2
蘮ジー4
蘯ダン4
蘰マン4
蘱レイ4
蘲レイ2
蘳フゥェイ1
蘴フォン1
蘵ヂー1
蘶ウェイ4
蘷クゥェイ2
蘸ヂャン4
蘹フゥァイ2
蘺リー2
蘻ジー4
蘼ミィ2
蘽レイ3
蘾フゥァイ4
蘿ルゥォ2
虀ジー1
虁クゥェイ2
虂ルー4
虃ジィェン1
虄サー4
虅トン2
虆レイ2
虇チュェン3
虈シァォ1
虉イー4
虊ルゥァン2
虋メン2
虌ビィェ1
虍フー1
虎フー3
虏ルー3
虐ヌーェ゛ァ4
虑リュ4
虒スー1
虓シァォ1
虔チィェン2
處チュ4
虖フー1
虗シュ1
虘ツゥォ2
虙フー2
虚シュ1
虛シュ1
虜ルー3
虝フー3
虞ユー2
號ハオ4
虠ジャオ1
虡ジュ4
虢グゥォ2
虣バオ4
虤イェン2
虥ヂャン4
虦ヂャン4
虧クゥェイ1
虨ビン1
虩シー4
虪シュ4
虫チョン2
虬チィゥ2
虭ディァォ1
虮ジー3
虯チィゥ2
虰ディン1
虱シー1
虲シァ1
虳ジュェ2
虴ヂェ゛ァ2
虵シェ゛ァ2
虶ユー1
虷ハン2
虸ズー3
虹ホン2
虺フゥェイ1
虻モン2
虼グェ゛ァ4
虽スェイ1
虾シァ1
虿チャイ4
蚀シー2
蚁イー3
蚂マー3
蚃シィァン3
蚄ファン1
蚅ェ゛ァ4
蚆バー1
蚇チー3
蚈チィェン1
蚉ウェン2
蚊ウェン2
蚋ルェイ4
蚌バン4
蚍ピー2
蚎ユェ4
蚏ユェ4
蚐ジュン1
蚑チー2
蚒トン2
蚓イン3
蚔チー2
蚕ツァン2
蚖ユェン2
蚗ジュェ2
蚘フゥェイ2
蚙チン2
蚚チー2
蚛ヂョン4
蚜ヤー2
蚝ハオ2
蚞ムー4
蚟ワン2
蚠フェン2
蚡フェン2
蚢ハン2
蚣ゴン1
蚤ザオ3
蚥フー4
蚦ラン2
蚧ジェ4
蚨フー2
蚩チー1
蚪ドウ3
蚫バオ4
蚬シィェン3
蚭ニー2
蚮ダイ4
蚯チィゥ1
蚰ヨウ2
蚱ヂャ4
蚲ピン2
蚳チー2
蚴ヨウ4
蚵フェ゛ァ2
蚶ハン1
蚷ジュ4
蚸リー4
蚹フー4
蚺ラン2
蚻ヂャ2
蚼ゴウ3
蚽ピー2
蚾ピー2
蚿シィェン2
蛀ヂュ4
蛁ディァォ1
蛂ビィェ2
蛃ビン3
蛄グー1
蛅ヂャン1
蛆チュ1
蛇シェ゛ァ2
蛈ティェ3
蛉リン2
蛊グー3
蛋ダン4
蛌グー3
蛍イン2
蛎リー4
蛏チォン1
蛐チュ1
蛑モウ2
蛒グェ゛ァ2
蛓ツー4
蛔フゥェイ2
蛕フゥェイ2
蛖マン2
蛗フー4
蛘ヤン2
蛙ウァ1
蛚リィェ4
蛛ヂュ1
蛜イー1
蛝シィェン2
蛞クォ4
蛟ジャオ1
蛠リー4
蛡イー4
蛢ピン2
蛣チー1
蛤ハー2
蛥シェ゛ァ2
蛦イー2
蛧ワン3
蛨ムー4
蛩チォン2
蛪チィェ4
蛫グゥェイ3
蛬チォン2
蛭ヂー4
蛮マン2
蛯ラオ3
蛰ヂェ゛ァ2
蛱ジャ2
蛲ナオ2
蛳スー1
蛴チー2
蛵シン1
蛶ジェ4
蛷チィゥ2
蛸シャオ1
蛹ヨン3
蛺ジャ2
蛻トゥェイ4
蛼チェ゛ァ1
蛽ベイ4
蛾ェ゛ァ2
蛿ハン4
蜀シュ3
蜁シュェン2
蜂フォン1
蜃シェン4
蜄シェン4
蜅フー3
蜆シィェン4
蜇ヂェ゛ァ1
蜈ウー2
蜉フー2
蜊リー2
蜋ラン2
蜌ビー4
蜍チュ2
蜎ユェン1
蜏ヨウ3
蜐ジェ2
蜑ダン4
蜒イェン2
蜓ティン2
蜔ディェン4
蜕トゥェイ4
蜖フゥェイ2
蜗ウォ1
蜘ヂー1
蜙ソン1
蜚フェイ1
蜛ジュ1
蜜ミィ4
蜝チー2
蜞チー2
蜟ユー4
蜠ジュン4
蜡ラー4
蜢モン3
蜣チィァン1
蜤スー1
蜥シー1
蜦ルゥン2
蜧リー4
蜨ディェ2
蜩ティァォ2
蜪タオ2
蜫クン1
蜬ハン2
蜭ハン4
蜮ユー4
蜯バン4
蜰フェイ2
蜱ピー2
蜲ウェイ1
蜳ドゥン1
蜴イー4
蜵ユェン1
蜶スォ4
蜷チュェン2
蜸チィェン3
蜹ルェイ4
蜺ニー2
蜻チン1
蜼ウェイ4
蜽リィァン3
蜾グゥォ3
蜿ワン1
蝀ドン1
蝁ェ゛ァ4
蝂バン3
蝃デ4
蝄ワン3
蝅ツァン2
蝆ヤン3
蝇イン2
蝈グゥォ1
蝉チャン2
蝊ディン4
蝋ラー4
蝌クェ゛ァ1
蝍ジェ2
蝎シェ1
蝏ティン2
蝐マオ4
蝑シュ1
蝒ミィェン2
蝓ユー2
蝔ジェ1
蝕シー2
蝖シュェン1
蝗フゥァン2
蝘イェン3
蝙ビィェン1
蝚ロウ2
蝛ウェイ1
蝜フー4
蝝ユェン2
蝞メイ4
蝟ウェイ4
蝠フー2
蝡ルー2
蝢シェ2
蝣ヨウ2
蝤チィゥ2
蝥マオ2
蝦シァ1
蝧イン1
蝨シー1
蝩チョン2
蝪タン1
蝫ヂュ1
蝬ゾン1
蝭ティ2
蝮フー4
蝯ユェン2
蝰クゥェイ2
蝱モン2
蝲ラー4
蝳ドゥ2
蝴フー2
蝵チィゥ1
蝶ディェ2
蝷リー4
蝸ウォ1
蝹ユン1
蝺チュ3
蝻ナン3
蝼ロウ2
蝽チュン1
蝾ロン2
蝿イン2
螀ジィァン1
螁バン
螂ラン2
螃パン2
螄スー1
螅シー1
螆ツー4
螇シー1
螈ユェン2
螉ウォン1
螊リィェン2
螋ソウ1
螌バン1
融ロン2
螎ロン2
螏ジー2
螐ウー1
螑シゥ4
螒ハン4
螓チン2
螔イー2
螕ビー1
螖ファ2
螗タン2
螘イー3
螙ドゥ4
螚ナイ4
螛フェ゛ァ2
螜フー2
螝グゥェイ1
螞マー3
螟ミン2
螠イー4
螡ウェン2
螢イン2
螣テェ゛ァ4
螤ヂョン1
螥ツァン1
螦サオ1
螧チー2
螨マン3
螩ティァォ
螪シャン1
螫シー4
螬ツァォ2
螭チー1
螮デ4
螯アオ2
螰ルー4
螱ウェイ4
螲ヂー4
螳タン2
螴チェン2
螵ピィァオ1
螶チュ2
螷ピー2
螸ユー2
螹ジィェン4
螺ルゥォ2
螻ロウ2
螼チン3
螽ヂョン1
螾イン3
螿ジィァン1
蟀シュァイ4
蟁ウェン2
蟂シァォ1
蟃ワン4
蟄ヂェ゛ァ2
蟅ヂェ゛ァ4
蟆マー2
蟇マー2
蟈グゥォ1
蟉リィゥ2
蟊マオ2
蟋シー1
蟌ツォン1
蟍リー2
蟎マン3
蟏シァォ1
蟐chang
蟑ヂャン1
蟒マン3
蟓シィァン4
蟔ムー4
蟕ズェイ1
蟖スー1
蟗チィゥ1
蟘テェ゛ァ4
蟙ヂー2
蟚ポン2
蟛ポン2
蟜ジャオ3
蟝チュ2
蟞ビィェ1
蟟リィァォ2
蟠パン2
蟡グゥェイ3
蟢シー3
蟣ジー3
蟤ヂュァン1
蟥フゥァン2
蟦フェイ2
蟧ラオ2
蟨ジュェ2
蟩ジュェ2
蟪フゥェイ4
蟫イン2
蟬チャン2
蟭ジャオ1
蟮シャン4
蟯ナオ2
蟰シァォ1
蟱ウー2
蟲チョン2
蟳シュン2
蟴スー1
蟵チュ2
蟶チォン1
蟷ダン1
蟸リー3
蟹シェ4
蟺シャン4
蟻イー3
蟼ジン3
蟽ダー2
蟾チャン2
蟿チー4
蠀ツー1
蠁シィァン3
蠂シェ゛ァ4
蠃ルゥォ3
蠄チン2
蠅イン2
蠆チャイ4
蠇リー4
蠈ゼイ2
蠉シュェン1
蠊リィェン2
蠋ヂュ2
蠌ゼェ゛ァ2
蠍シェ1
蠎マン3
蠏シェ4
蠐チー2
蠑ロン2
蠒ジィェン3
蠓モン3
蠔ハオ2
蠕ルー2
蠖フォ4
蠗ヂュオ2
蠘ジェ2
蠙ピン2
蠚フェ゛ァ1
蠛ミィェ4
蠜ファン2
蠝レイ3
蠞ジェ2
蠟ラー4
蠠ミン3
蠡リー2
蠢チュン3
蠣リー4
蠤チィゥ1
蠥ニィェ4
蠦ルー2
蠧ドゥ4
蠨シァォ1
蠩ヂュ1
蠪ロン2
蠫リー2
蠬ロン2
蠭フォン1
蠮イェ1
蠯ピー2
蠰ナン2
蠱グー3
蠲ジュェン1
蠳イン1
蠴シュ3
蠵シー1
蠶ツァン2
蠷チュ2
蠸チュェン2
蠹ドゥ4
蠺ツァン2
蠻マン2
蠼チュ2
蠽ジェ2
蠾ヂュ2
蠿ヂュオ1
血シュェ4
衁フゥァン1
衂ニュ4
衃ペイ1
衄ニュ4
衅シン4
衆ヂョン4
衇マイ4
衈ェ゛ァー4
衉カー1
衊ミィェ4
衋シー4
行シン2
衍イェン3
衎カン4
衏ユェン4
衐チュ2
衑リン2
衒シュェン4
術シュ4
衔シィェン2
衕トン4
衖シィァン4
街ジェ1
衘シィェン2
衙ヤー2
衚フー2
衛ウェイ4
衜ダオ4
衝チョン1
衞ウェイ4
衟ダオ4
衠ヂュン1
衡ホン2
衢チュ2
衣イー1
衤イー1
补ブー3
衦ガン3
衧ユー2
表ビィャォ3
衩チャ3
衪イー2
衫シャン1
衬チェン4
衭フー1
衮ガン3
衯フェン1
衰シュァイ1
衱ジェ2
衲ナー4
衳ヂョン1
衴ダン3
衵イー4
衶ヂョン4
衷ヂョン1
衸ジェ4
衹ヂー3
衺シェ2
衻ラン2
衼ヂー1
衽レン4
衾チン1
衿ジン1
袀ジュン1
袁ユェン2
袂メイ4
袃チャイ4
袄アオ3
袅ニィァォ3
袆フゥェイ1
袇ラン2
袈ジャ1
袉トゥォ2
袊リン3
袋ダイ4
袌バオ4
袍パオ2
袎イャォ4
袏ズゥォ4
袐ビー4
袑シャオ4
袒タン3
袓ジュ4
袔フェ゛ァ4
袕シュェ2
袖シゥ4
袗ヂェン3
袘イー2
袙パー4
袚ブォ1
袛デ1
袜ウァ4
袝フー4
袞ガン3
袟ヂー4
袠ヂー4
袡ラン2
袢パン4
袣イー4
袤マオ4
袥トゥォ1
袦ナー4
袧ゴウ1
袨シュェン4
袩ヂェ゛ァ2
袪チュ1
被ベイ4
袬ユー4
袭シー2
袮ミィ2
袯ブォ2
袰ブォ1
袱フー2
袲チー3
袳チー3
袴クー4
袵レン4
袶ジィァン4
袷ジャ2
袸ジィェン4
袹ブォ2
袺ジェ2
袻ェ゛ァー2
袼グェ゛ァ1
袽ルー2
袾ヂュ1
袿グゥェイ1
裀イン1
裁ツァィ2
裂リィェ4
裃カー3
裄シン
装ヂュゥァン1
裆ダン1
裇シュ1
裈クン1
裉ケン4
裊ニィァォ3
裋シュ4
裌ジャ2
裍クン3
裎チォン2
裏リー3
裐ジュェン1
裑シェン1
裒ポウ2
裓グェ゛ァ2
裔イー4
裕ユー4
裖ヂェン3
裗リィゥ2
裘チィゥ2
裙チュン2
裚ジー4
裛イー4
補ブー3
裝ヂュゥァン1
裞シュェイ4
裟シャ1
裠チュン2
裡リー3
裢リィェン2
裣リィェン3
裤クー4
裥ジィェン3
裦フォウ2
裧チャン1
裨ビー4
裩クン1
裪タオ2
裫ユェン4
裬リン2
裭チー3
裮chang1
裯チョウ2
裰ドゥォ1
裱ビィャォ3
裲リィァン3
裳シャン
裴ペイ2
裵ペイ2
裶フェイ1
裷ユェン1
裸ルゥォ3
裹グゥォ3
裺イェン3
裻ドゥ2
裼ティ4
製ヂー4
裾ジュ1
裿イー3
褀チー2
褁グゥォ3
褂グァ4
褃ケン4
褄チー1
褅ティ4
褆ティ2
複フー4
褈チョン2
褉シェ4
褊ビィェン3
褋ディェ2
褌クン1
褍ドゥァン1
褎シゥ4
褏シゥ4
褐フェ゛ァ4
褑ユェン4
褒バオ1
褓バオ3
褔フー4
褕ユー2
褖トゥァン4
褗イェン3
褘フゥェイ1
褙ベイ4
褚チュ3
褛リュ3
褜パオ2
褝ダン1
褞ユン3
褟ター1
褠ゴウ1
褡ダー1
褢フゥァイ2
褣ロン2
褤ユェン4
褥ルー4
褦ナイ4
褧ジィォン3
褨スォ3
褩バン1
褪トゥェイ4
褫チー3
褬サン3
褭ニィァォ3
褮イン1
褯ジェ4
褰チィェン1
褱フゥァイ2
褲クー4
褳リィェン2
褴ラン2
褵リー2
褶ヂェ゛ァ3
褷シー1
褸リュ3
褹イー4
褺ディェ1
褻シェ4
褼シィェン1
褽ウェイ4
褾ビィャォ3
褿ツァォ2
襀ジー1
襁チィァン3
襂セン1
襃バオ1
襄シィァン1
襅ビー4
襆フー2
襇ジィェン3
襈ヂュァン4
襉ジィェン3
襊ツェイ4
襋ジー2
襌ダン1
襍ザー2
襎ファン2
襏ブォ2
襐シィァン4
襑シン2
襒ビィェ2
襓ラオ2
襔マン3
襕ラン2
襖アオ3
襗ゼェ゛ァ2
襘グゥェイ4
襙ツァォ4
襚スェイ4
襛ノン2
襜チャン1
襝リィェン3
襞ビー4
襟ジン1
襠ダン1
襡シュ3
襢タン3
襣ビー4
襤ラン2
襥フー2
襦ルー2
襧ヂー3
襨ドゥェイ4
襩シュ3
襪ウァ4
襫シー4
襬バイ3
襭シェ2
襮ブォ2
襯チェン4
襰ライ4
襱ロン2
襲シー2
襳シィェン1
襴ラン2
襵ヂェ゛ァ3
襶ダイ4
襷ジュ3
襸ザン4
襹シー1
襺ジィェン3
襻パン4
襼イー4
襽ラン2
襾ヤー4
西シー1
覀シー1
要イャォ4
覂フォン3
覃タン2
覄フー4
覅fiao4
覆フー4
覇バー4
覈フェ゛ァ2
覉ジー1
覊ジー1
見ジィェン4
覌グァン1
覍ビィェン4
覎イェン4
規グゥェイ1
覐ジュェ2
覑ピィェン3
覒マオ4
覓ミィ4
覔ミィ4
覕ミィェ4
視シー4
覗スー4
覘チャン1
覙ルゥォ2
覚ジュェ2
覛ミィ4
覜ティァォ4
覝リィェン2
覞イャォ4
覟ヂー4
覠ジュン1
覡シー2
覢シャン3
覣ウェイ1
覤シー4
覥ティェン3
覦ユー2
覧ラン3
覨ェ゛ァ4
覩ドゥ3
親チン1
覫パン3
覬ジー4
覭ミン2
覮イン2
覯ゴウ4
覰チュ1
覱ヂャン4
覲ジン4
観グァン1
覴ドン1
覵ジィェン4
覶ルゥォ2
覷チュ4
覸ジィェン1
覹ウェイ2
覺ジュェ2
覻チュ1
覼ルゥォ2
覽ラン3
覾シェン3
覿デ2
觀グァン1
见ジィェン4
观グァン1
觃イェン4
规グゥェイ1
觅ミィ4
视シー4
觇チャン1
览ラン3
觉ジュェ2
觊ジー4
觋シー2
觌デ2
觍ティェン3
觎ユー2
觏ゴウ4
觐ジン4
觑チュ4
角ジャオ3
觓チィゥ2
觔ジン1
觕ツゥ1
觖ジュェ2
觗ヂー4
觘チャオ4
觙ジー2
觚グー1
觛ダン4
觜ズー1
觝デ3
觞シャン1
觟ファ4
觠チュェン2
觡グェ゛ァ2
觢シー4
解ジェ3
觤グゥェイ3
觥ゴン1
触チュ4
觧ジェ3
觨フン4
觩チィゥ2
觪シン1
觫スー4
觬ニー2
觭ジー1
觮ルー4
觯ヂー4
觰ヂャ1
觱ビー4
觲シン1
觳フー2
觴シャン1
觵ゴン1
觶ヂー4
觷シュェ2
觸チュ4
觹シー1
觺イー2
觻リー4
觼ジュェ2
觽シー1
觾イェン4
觿シー1
言イェン2
訁イェン2
訂ディン4
訃フー4
訄チィゥ2
訅チィゥ2
訆ジャオ4
訇ホン1
計ジー4
訉ファン4
訊シュン4
訋ディァォ4
訌ホン4
訍チャイ4
討タオ3
訏シュ1
訐ジェ2
訑イー2
訒レン4
訓シュン4
訔イン2
訕シャン4
訖チー4
託トゥォ1
記ジー4
訙シュン4
訚イン2
訛ェ゛ァ2
訜フェン1
訝ヤー4
訞イャォ1
訟ソン4
訠シェン3
訡イン2
訢シン1
訣ジュェ2
訤シァォ2
訥ヌェ゛ァ4
訦チェン2
訧ヨウ2
訨ヂー3
訩シィォン1
訪ファン3
訫シン4
訬チャオ1
設シェ゛ァ4
訮イェン2
訯サー3
訰ヂュン4
許シュ3
訲イー4
訳イー4
訴スー4
訵チー1
訶フェ゛ァ1
訷シェン1
訸フェ゛ァ2
訹シュ4
診ヂェン3
註ヂュ4
証ヂォン4
訽ゴウ4
訾ズー1
訿ズー3
詀ヂャン1
詁グー3
詂フー4
詃ジィェン3
詄ディェ2
詅リン2
詆デ3
詇ヤン4
詈リー4
詉ナオ2
詊パン4
詋ヂョウ4
詌ガン4
詍イー4
詎ジュ4
詏イャォ4
詐ヂャ4
詑イー2
詒イー2
詓チュ3
詔ヂャオ4
評ピン2
詖ビー4
詗シィォン4
詘チュ1
詙バー2
詚ダー2
詛ズー3
詜タオ1
詝ヂュ3
詞ツー2
詟ヂェ゛ァ2
詠ヨン3
詡シュ3
詢シュン2
詣イー4
詤フゥァン3
詥フェ゛ァ2
試シー4
詧チャ2
詨シァォ4
詩シー1
詪ヘン3
詫チャ4
詬ゴウ4
詭グゥェイ3
詮チュェン2
詯フゥェイ4
詰ジェ2
話ファ4
該ガイ1
詳シィァン2
詴ウェイ1
詵シェン1
詶ヂョウ4
詷トン2
詸ミィ2
詹ヂャン1
詺ミン4
詻ェ゛ァ4
詼フゥェイ1
詽イェン2
詾シィォン1
詿グァ4
誀ェ゛ァー4
誁ビン4
誂ティァォ3
誃イー2
誄レイ3
誅ヂュ1
誆クゥァン1
誇クァ1
誈ウー1
誉ユー4
誊トン2
誋ジー4
誌ヂー4
認レン4
誎ツゥ4
誏ラン3
誐ェ゛ァ2
誑クゥァン2
誒ei2
誓シー4
誔ティン3
誕ダン4
誖ベイ4
誗チャン2
誘ヨウ4
誙コン1
誚チィァォ4
誛チン1
誜シュァ4
誝アン1
語ユー3
誟シァォ4
誠チォン2
誡ジェ4
誢シィェン4
誣ウー1
誤ウー4
誥ガオ4
誦ソン4
誧ブー1
誨フゥェイ4
誩ジン4
說シュォ1
誫ヂェン4
説シュォ1
読ドゥ2
誮ファ1
誯chang4
誰シュェイ2
誱ジェ2
課クェ゛ァ4
誳チュ1
誴ツォン2
誵シァォ2
誶スェイ4
誷ワン3
誸シィェン2
誹フェイ3
誺チー1
誻ター4
誼イー4
誽ニー4
誾イン2
調ディァォ4
諀ピー3
諁ヂュオ2
諂チャン3
諃チェン1
諄ヂュン1
諅ジー4
諆チー1
談タン2
諈ヂュェイ4
諉ウェイ3
諊ジュ1
請チン3
諌ドン3
諍ヂォン4
諎ゼェ゛ァ2
諏ゾウ1
諐チィェン1
諑ヂュオ2
諒リィァン4
諓ジィェン4
諔チュ4
諕ハオ2
論ルゥン4
諗シェン3
諘ビィャォ3
諙ファ4
諚ピィェン2
諛ユー2
諜ディェ2
諝シュ1
諞ピィェン3
諟シー4
諠シュェン1
諡シー4
諢フン4
諣ファ4
諤ェ゛ァ4
諥ヂョン4
諦デ4
諧シェ2
諨フー2
諩プー3
諪ティン2
諫ジィェン4
諬チー3
諭ユー4
諮ズー1
諯ヂュァン1
諰シー3
諱フゥェイ4
諲イン1
諳アン1
諴シィェン2
諵ナン2
諶チェン2
諷フォン3
諸ヂュ1
諹ヤン2
諺イェン4
諻フゥァン2
諼シュェン1
諽グェ゛ァ2
諾ヌオ4
諿チー1
謀モウ2
謁イェ4
謂ウェイ4
謃シン1
謄トン2
謅ヂョウ1
謆シャン4
謇ジィェン3
謈ポォ2
謉クゥェイ4
謊フゥァン3
謋フォ4
謌グェ゛ァ1
謍イン2
謎ミィ2
謏シァォ3
謐ミィ4
謑シー3
謒チィァン1
謓チェン1
謔シュェ4
謕ティ2
謖スー4
謗バン4
謘チー2
謙チィェン1
謚シー4
講ジィァン3
謜ユェン2
謝シェ4
謞フェ゛ァ4
謟タオ1
謠イャォ2
謡イャォ2
謢ルー1
謣ユー2
謤ビィャォ1
謥ツォン4
謦チン3
謧リー2
謨ムー2
謩ムー2
謪シャン1
謫ヂェ゛ァ2
謬ミィゥ4
謭ジィェン3
謮ゼェ゛ァ2
謯ジェ1
謰リィェン2
謱ロウ2
謲ツァン4
謳オウ1
謴ガン4
謵シー2
謶ヂュオ2
謷アオ2
謸アオ2
謹ジン3
謺ヂェ゛ァ2
謻イー2
謼フー1
謽ジィァン4
謾マン2
謿チャオ2
譀ハン4
譁ファ2
譂チャン3
譃シュ1
譄ゾン1
譅スェ゛ァ4
譆シー1
譇ヂャ1
譈ドゥェイ4
證ヂォン4
譊ナオ2
譋ラン2
譌ェ゛ァ2
譍イン1
譎ジュェ2
譏ジー1
譐ズン3
譑ジャオ3
譒ブォ4
譓フゥェイ4
譔ヂュァン4
譕ウー2
譖ゼン4
譗ヂャ2
識シー2
譙チィァォ4
譚タン2
譛ゼン4
譜プー3
譝シォン2
譞シュェン1
譟ザオ4
譠タン2
譡ダン3
譢スェイ4
譣シィェン3
譤ジー1
譥ジャオ4
警ジン3
譧ヂャン4
譨ナン2
譩イー1
譪ェ゛ァ3
譫ヂャン1
譬ピー4
譭フゥェイ3
譮ファ4
譯イー4
議イー4
譱シャン4
譲ラン4
譳ノウ4
譴チィェン3
譵ドゥェイ4
譶ター4
護フー4
譸ヂョウ1
譹ハオ2
譺ェ゛ァ4
譻イン1
譼ジィェン4
譽ユー4
譾ジィェン3
譿フゥェイ4
讀ドゥ2
讁ヂェ゛ァ2
讂シュェン4
讃ザン4
讄レイ3
讅シェン3
讆ウェイ4
讇チャン3
讈リー4
讉イー2
變ビィェン4
讋ヂェ゛ァ2
讌イェン4
讍ェ゛ァ4
讎チョウ2
讏ウェイ4
讐チョウ2
讑イャォ4
讒チャン2
讓ラン4
讔イン3
讕ラン2
讖チェン4
讗シェ2
讘ニィェ4
讙ファン1
讚ザン4
讛イー4
讜ダン3
讝ヂャン2
讞イェン4
讟ドゥ2
讠イェン2
计ジー4
订ディン4
讣フー4
认レン4
讥ジー1
讦ジェ2
讧ホン4
讨タオ3
让ラン4
讪シャン4
讫チー4
讬トゥォ1
训シュン4
议イー4
讯シュン4
记ジー4
讱レン4
讲ジィァン3
讳フゥェイ4
讴オウ1
讵ジュ4
讶ヤー4
讷ヌェ゛ァ4
许シュ3
讹ェ゛ァ2
论ルゥン4
讻シィォン1
讼ソン4
讽フォン3
设シェ゛ァ4
访ファン3
诀ジュェ2
证ヂォン4
诂グー3
诃フェ゛ァ1
评ピン2
诅ズー3
识シー2
诇シィォン4
诈ヂャ4
诉スー4
诊ヂェン3
诋デ3
诌ヂョウ1
词ツー2
诎チュ1
诏ヂャオ4
诐ビー4
译イー4
诒イー2
诓クゥァン1
诔レイ3
试シー4
诖グァ4
诗シー1
诘ジー2
诙フゥェイ1
诚チォン2
诛ヂュ1
诜シェン1
话ファ4
诞ダン4
诟ゴウ4
诠チュェン2
诡グゥェイ3
询シュン2
诣イー4
诤ヂォン4
该ガイ1
详シィァン2
诧チャ4
诨フン4
诩シュ3
诪ヂョウ1
诫ジェ4
诬ウー1
语ユー3
诮チィァォ4
误ウー4
诰ガオ4
诱ヨウ4
诲フゥェイ4
诳クゥァン2
说シュォ1
诵ソン4
诶ei2
请チン3
诸ヂュ1
诹ゾウ1
诺ヌオ4
读ドゥ2
诼ヂュオ2
诽フェイ3
课クェ゛ァ4
诿ウェイ3
谀ユー2
谁shei2
谂シェン3
调ディァォ4
谄チャン3
谅リィァン4
谆ヂュン1
谇スェイ4
谈タン2
谉シェン3
谊イー4
谋モウ2
谌チェン2
谍ディェ2
谎フゥァン3
谏ジィェン4
谐シェ2
谑シュェ4
谒イェ4
谓ウェイ4
谔ェ゛ァ4
谕ユー4
谖シュェン1
谗チャン2
谘ズー1
谙アン1
谚イェン4
谛デ4
谜ミィ2
谝ピィェン2
谞シュ1
谟ムー2
谠ダン3
谡スー4
谢シェ4
谣イャォ2
谤バン4
谥シー4
谦チィェン1
谧ミィ4
谨ジン3
谩マン2
谪ヂェ゛ァ2
谫ジィェン3
谬ミィゥ4
谭タン2
谮ゼン4
谯チィァォ2
谰ラン2
谱プー3
谲ジュェ2
谳イェン4
谴チィェン3
谵ヂャン1
谶チェン4
谷グー3
谸チィェン1
谹ホン2
谺シァ1
谻ジー2
谼ホン2
谽ハン1
谾ホン1
谿シー1
豀シー1
豁フォ1
豂リィァォ2
豃ハン3
豄ドゥ2
豅ロン2
豆ドウ4
豇ジィァン1
豈チー3
豉シー4
豊リー3
豋ドン1
豌ワン1
豍ビー1
豎シュ4
豏シィェン4
豐フォン1
豑ヂー4
豒ヂー4
豓イェン4
豔イェン4
豕シー3
豖チュ4
豗フゥェイ1
豘トゥン2
豙イー4
豚トゥン2
豛イー4
豜ジィェン1
豝バー1
豞ホウ4
豟ェ゛ァ4
豠チュ2
象シィァン4
豢ファン4
豣ジィェン1
豤ケン3
豥ガイ1
豦ジュ4
豧フー1
豨シー1
豩ビン1
豪ハオ2
豫ユー4
豬ヂュ1
豭ジャ1
豮フェン2
豯シー1
豰ブォ2
豱ウェン1
豲ファン2
豳ビン1
豴デ2
豵ゾン1
豶フェン2
豷イー4
豸ヂー4
豹バオ4
豺チャイ2
豻アン4
豼ピー2
豽ナー4
豾ピー1
豿ゴウ3
貀ナー4
貁ヨウ4
貂ディァォ1
貃ムー4
貄スー4
貅シゥ1
貆ファン2
貇クン1
貈フェ゛ァ2
貉ハオ2
貊ムー4
貋アン4
貌マオ4
貍リー2
貎ニー2
貏ビー3
貐ユー3
貑ジャ1
貒トゥァン1
貓マオ1
貔ピー2
貕シー1
貖イー4
貗ジュ4
貘ムー4
貙チュ1
貚タン2
貛ファン1
貜ジュェ2
貝ベイ4
貞ヂェン1
貟ユェン2
負フー4
財ツァィ2
貢ゴン4
貣テェ゛ァ4
貤イー2
貥ハン2
貦ワン2
貧ピン2
貨フォ4
販ファン4
貪タン1
貫グァン4
責ゼェ゛ァ2
貭ヂー4
貮ェ゛ァー4
貯ヂュ4
貰シー4
貱ビー4
貲ズー1
貳ェ゛ァー4
貴グゥェイ4
貵ピィェン3
貶ビィェン3
買マイ3
貸ダイ4
貹シォン4
貺クゥァン4
費フェイ4
貼ティェ1
貽イー2
貾チー2
貿マオ4
賀フェ゛ァ4
賁ビー4
賂ルー4
賃リン4
賄フゥェイ4
賅ガイ1
賆ピィェン2
資ズー1
賈ジャ3
賉シュ4
賊ゼイ2
賋ジャオ3
賌ガイ1
賍ザン1
賎ジィェン4
賏イン1
賐シュン4
賑ヂェン4
賒シェ゛ァ1
賓ビン1
賔ビン1
賕チィゥ2
賖シェ゛ァ1
賗チュァン4
賘ザン1
賙ヂョウ1
賚ライ4
賛ザン4
賜ツー4
賝チェン1
賞シャン3
賟ティェン3
賠ペイ2
賡ゴン1
賢シィェン2
賣マイ4
賤ジィェン4
賥スェイ4
賦フー4
賧タン4
賨ツォン2
賩ツォン2
質ヂー4
賫ジー1
賬ヂャン4
賭ドゥ3
賮ジン4
賯シィォン1
賰チュン3
賱ユン3
賲バオ3
賳ザイ1
賴ライ4
賵フォン4
賶ツァン4
賷ジー1
賸シォン4
賹イー4
賺ヂュァン4
賻フー4
購ゴウ4
賽サイ4
賾ゼェ゛ァ2
賿リィァォ2
贀イー4
贁バイ4
贂チェン3
贃ワン4
贄ヂー4
贅ヂュェイ4
贆ビィャォ1
贇ユン1
贈ゾン4
贉ダン4
贊ザン4
贋イェン4
贌プー2
贍シャン4
贎ワン4
贏イン2
贐ジン4
贑ガン4
贒シィェン2
贓ザン1
贔ビー4
贕ドゥ2
贖シュ2
贗イェン4
贘シャン3
贙シュェン4
贚ロン4
贛ガン4
贜ザン1
贝ベイ4
贞ヂェン1
负フー4
贠ユェン2
贡ゴン4
财ツァィ2
责ゼェ゛ァ2
贤シィェン2
败バイ4
账ヂャン4
货フォ4
质ヂー4
贩ファン4
贪タン1
贫ピン2
贬ビィェン3
购ゴウ4
贮ヂュ4
贯グァン4
贰ェ゛ァー4
贱ジィェン4
贲ベン1
贳シー4
贴ティェ1
贵グゥェイ4
贶クゥァン4
贷ダイ4
贸マオ4
费フェイ4
贺フェ゛ァ4
贻イー2
贼ゼイ2
贽ヂー4
贾ジャ3
贿フゥェイ4
赀ズー1
赁リン4
赂ルー4
赃ザン1
资ズー1
赅ガイ1
赆ジン4
赇チィゥ2
赈ヂェン4
赉ライ4
赊シェ゛ァ1
赋フー4
赌ドゥ3
赍ジー1
赎シュ2
赏シャン3
赐ツー4
赑ビー4
赒ヂョウ1
赓ゴン1
赔ペイ2
赕ダン3
赖ライ4
赗フォン4
赘ヂュェイ4
赙フー4
赚ヂュァン4
赛サイ4
赜ゼェ゛ァ2
赝イェン4
赞ザン4
赟ユン1
赠ゾン4
赡シャン4
赢イン2
赣ガン4
赤チー4
赥シー1
赦シェ゛ァ4
赧ナン3
赨トン2
赩シー4
赪チォン1
赫フェ゛ァ4
赬チォン1
赭ヂェ゛ァ3
赮シァ2
赯タン2
走ゾウ3
赱ゾウ3
赲リー4
赳ジゥ1
赴フー4
赵ヂャオ4
赶ガン3
起チー3
赸シャン4
赹チォン2
赺イン3
赻シィェン3
赼ズー1
赽ジュェ2
赾チン3
赿チー2
趀ツー1
趁チェン4
趂チェン4
趃ディェ2
趄ジュ1
超チャオ1
趆デ1
趇シー4
趈ヂャン1
趉ジュェ2
越ユェ4
趋チュ1
趌ジー2
趍チー2
趎チュ2
趏グァ1
趐シュェ4
趑ズー1
趒ティァォ2
趓ドゥォ3
趔リィェ4
趕ガン3
趖スォ1
趗ツゥ4
趘シー2
趙ヂャオ4
趚スー4
趛イン3
趜ジュ2
趝ジィェン4
趞チュェ4
趟タン4
趠チュォ4
趡ツェイ3
趢ルー4
趣チュ4
趤ダン4
趥チィゥ1
趦ズー1
趧ティ2
趨チュ1
趩チー4
趪フゥァン2
趫チィァォ2
趬チィァォ1
趭ジャオ4
趮ザオ4
趯ティ4
趰ェ゛ァー3
趱ザン3
趲ザン3
足ズー2
趴パー1
趵バオ4
趶クー4
趷クェ゛ァ1
趸ドゥン3
趹ジュェ2
趺フー1
趻チェン3
趼ジィェン3
趽ファン4
趾ヂー3
趿ター1
跀ユェ4
跁バー4
跂チー2
跃ユェ4
跄チィァン1
跅トゥォ4
跆タイ2
跇イー4
跈ニィェン3
跉リン2
跊メイ4
跋バー2
跌ディェ1
跍クー1
跎トゥォ2
跏ジャ1
跐ツー1
跑パオ3
跒チィァ3
跓ヂュ4
跔ジュ1
跕ディェン3
跖ヂー2
跗フー1
跘パン2
跙ジュ4
跚シャン1
跛ブォ3
跜ニー2
距ジュ4
跞リー4
跟ゲン1
跠イー2
跡ジー1
跢ドゥォ4
跣シィェン3
跤ジャオ1
跥ドゥォ4
跦ヂュ1
跧チュェン2
跨クァ4
跩ヂュァイ3
跪グゥェイ4
跫チォン2
跬クゥェイ3
跭シィァン2
跮チー4
路ルー4
跰ピィェン2
跱ヂー4
跲ジャ2
跳ティァォ4
跴ツァィ3
践ジィェン4
跶ダー2
跷チィァォ1
跸ビー4
跹シィェン1
跺ドゥォ4
跻ジー1
跼ジュ2
跽ジー4
跾シュ1
跿トゥ2
踀チュ4
踁ジン4
踂ニィェ4
踃シァォ1
踄ブー4
踅シュェ2
踆ツン1
踇ムー3
踈シュ1
踉リィァン2
踊ヨン3
踋ジャオ3
踌チョウ2
踍チィァォ1
踎モウ2
踏ター4
踐ジィェン4
踑チー2
踒ウォ1
踓ウェイ3
踔チュォ1
踕ジェ2
踖ジー2
踗ニィェ4
踘ジュ1
踙ニィェ4
踚ルゥン2
踛ルー4
踜ラン4
踝フゥァイ2
踞ジュ4
踟チー2
踠ワン3
踡チュェン2
踢ティ1
踣ブォ2
踤ズー2
踥チィェ4
踦イー3
踧ツゥ4
踨ゾン1
踩ツァィ3
踪ゾン1
踫ポン4
踬ヂー4
踭ヂォン1
踮ディェン3
踯ヂー2
踰ユー2
踱ドゥォ2
踲ドゥン4
踳チュァン3
踴ヨン3
踵ヂョン3
踶デ4
踷ヂャ3
踸チェン3
踹チュァイ4
踺ジィェン4
踻グァ1
踼タン2
踽ジュ3
踾フー2
踿ズー2
蹀ディェ2
蹁ピィェン2
蹂ロウ2
蹃ヌオ4
蹄ティ2
蹅チャ3
蹆トゥェイ3
蹇ジィェン3
蹈ダオ3
蹉ツゥォ1
蹊チー1
蹋ター4
蹌チィァン1
蹍ニィェン3
蹎ディェン1
蹏ティ2
蹐ジー2
蹑ニィェ4
蹒パン2
蹓リィゥ1
蹔ザン4
蹕ビー4
蹖チョン1
蹗ルー4
蹘リィァォ2
蹙ツゥ4
蹚タン1
蹛ダイ4
蹜スー4
蹝シー3
蹞クゥェイ3
蹟ジー1
蹠ヂー2
蹡チィァン1
蹢デ2
蹣パン2
蹤ゾン1
蹥リィェン2
蹦ボン4
蹧ザオ1
蹨ニィェン3
蹩ビィェ2
蹪トゥェイ2
蹫ジュ2
蹬ドン1
蹭ツォン4
蹮シィェン1
蹯ファン2
蹰チュ2
蹱ヂョン1
蹲ドゥン1
蹳ブォ1
蹴ツゥ4
蹵ツゥ4
蹶ジュェ2
蹷ジュェ2
蹸リン4
蹹ター2
蹺チィァォ1
蹻ジュェ1
蹼プー3
蹽リィァォ1
蹾ドゥン1
蹿ツァン1
躀グァン4
躁ザオ4
躂ダー2
躃ビー4
躄ビー4
躅ヂュ2
躆ジュ4
躇チュ2
躈チィァォ4
躉ドゥン3
躊チョウ2
躋ジー1
躌ウー3
躍ユェ4
躎ニィェン3
躏リン4
躐リィェ4
躑ヂー2
躒リー4
躓ヂー4
躔チャン2
躕チュ2
躖ドゥァン4
躗ウェイ4
躘ロン2
躙リン4
躚シィェン1
躛ウェイ4
躜ズァン1
躝ラン2
躞シェ4
躟ラン2
躠サー3
躡ニィェ4
躢ター4
躣チュ2
躤ジー2
躥ツァン1
躦ツゥォ2
躧シー3
躨クゥェイ2
躩ジュェ2
躪リン4
身シェン1
躬ゴン1
躭ダン1
躮フェン1
躯チュ1
躰ティ3
躱ドゥォ3
躲ドゥォ3
躳ゴン1
躴ラン2
躵レン3
躶ルゥォ3
躷ェ゛ァ3
躸ジー1
躹ジュ2
躺タン3
躻コン1
躼ラオ4
躽イェン3
躾メイ3
躿カン1
軀チュ1
軁ロウ2
軂ラオ4
軃ドゥォ3
軄ヂー2
軅イェン4
軆ティ3
軇ダオ4
軈イン1
軉ユー4
車チェ゛ァ1
軋ヤー4
軌グゥェイ3
軍ジュン1
軎ウェイ4
軏ユェ4
軐シン4
軑ダイ4
軒シュェン1
軓ファン4
軔レン4
軕シャン1
軖クゥァン2
軗シュ1
軘トゥン2
軙チェン2
軚ダイ4
軛ェ゛ァ4
軜ナー4
軝チー2
軞マオ2
軟ルァン3
軠クゥァン2
軡チィェン2
転ヂュァン3
軣ホン1
軤フー1
軥チュ2
軦クゥァン4
軧デ3
軨リン2
軩ダイ4
軪アオ1
軫ヂェン3
軬ファン4
軭クゥァン1
軮ヤン3
軯ポン1
軰ベイ4
軱グー1
軲グー1
軳パオ2
軴ヂュ4
軵ロン3
軶ェ゛ァ4
軷バー2
軸ヂョウ2
軹ヂー3
軺イャォ2
軻クェ゛ァ1
軼イー4
軽ヂー4
軾シー4
軿ピン2
輀ェ゛ァー2
輁ゴン3
輂ジュ2
較ジャオ4
輄グゥァン1
輅フェ゛ァ2
輆カイ3
輇チュェン2
輈ヂョウ1
載ザイ4
輊ヂー4
輋シェ゛ァ1
輌リィァン4
輍ユー4
輎シャオ1
輏ヨウ2
輐ワン4
輑イン3
輒ヂェ゛ァ2
輓ワン3
輔フー3
輕チン1
輖ヂョウ1
輗ニー2
輘ラン2
輙ヂェ゛ァ2
輚ヂャン4
輛リィァン4
輜ズー1
輝フゥェイ1
輞ワン3
輟チュォ4
輠グゥォ3
輡カン3
輢イー3
輣ポン2
輤チィェン4
輥ガン3
輦ニィェン3
輧ピン2
輨グァン3
輩ベイ4
輪ルゥン2
輫パイ2
輬リィァン2
輭ルァン3
輮ロウ2
輯ジー2
輰ヤン2
輱シィェン2
輲チュァン2
輳コゥ4
輴チュン1
輵グェ゛ァ2
輶ヨウ2
輷ホン1
輸シュ1
輹フー4
輺ズー1
輻フー2
輼ウェン1
輽ベン4
輾ヂャン3
輿ユー2
轀ウェン1
轁タオ1
轂グー3
轃ヂェン1
轄シァ2
轅ユェン2
轆ルー4
轇ジャオ1
轈チャオ2
轉ヂュァン3
轊ウェイ4
轋フン2
轌シュェ3
轍ヂェ゛ァ2
轎ジャオ4
轏ヂャン4
轐ブー2
轑ラオ3
轒フェン2
轓ファン1
轔リン2
轕グェ゛ァ2
轖スェ゛ァ4
轗カン3
轘ファン2
轙イー3
轚ジー2
轛ヂュェイ4
轜ェ゛ァー2
轝ユー4
轞ジィェン4
轟ホン1
轠レイ2
轡ペイ4
轢リー4
轣リー4
轤ルー2
轥リン4
车チェ゛ァ1
轧ヤー4
轨グゥェイ3
轩シュェン1
轪ダイ4
轫レン4
转ヂュァン3
轭ェ゛ァ4
轮ルゥン2
软ルァン3
轰ホン1
轱グー1
轲クェ゛ァ1
轳ルー2
轴ヂョウ2
轵ヂー3
轶イー4
轷フー1
轸ヂェン3
轹リー4
轺イャォ2
轻チン1
轼シー4
载ザイ4
轾ヂー4
轿ジャオ4
辀ヂョウ1
辁チュェン2
辂ルー4
较ジャオ4
辄ヂェ゛ァ2
辅フー3
辆リィァン4
辇ニィェン3
辈ベイ4
辉フゥェイ1
辊ガン3
辋ワン3
辌リィァン2
辍チュォ4
辎ズー1
辏コゥ4
辐フー2
辑ジー2
辒ウェン1
输シュ1
辔ペイ4
辕ユェン2
辖シァ2
辗ニィェン3
辘ルー4
辙ヂェ゛ァ2
辚リン2
辛シン1
辜グー1
辝ツー2
辞ツー2
辟ピー4
辠ズェイ4
辡ビィェン4
辢ラー4
辣ラー4
辤ツー2
辥シュェ1
辦バン4
辧ビィェン4
辨ビィェン4
辩ビィェン4
辪シュェ1
辫ビィェン4
辬バン1
辭ツー2
辮ビィェン4
辯ビィェン4
辰チェン2
辱ルー3
農ノン2
辳ノン2
辴チャン3
辵チュォ4
辶チュォ4
辷イー1
辸ロン2
边ビィェン1
辺ビィェン1
辻シー2
込ユー1
辽リィァォ2
达ダー2
辿チャン1
迀ガン1
迁チィェン1
迂ユー1
迃ユー1
迄チー4
迅シュン4
迆イー2
过グゥォ4
迈マイ4
迉チー1
迊ザー1
迋ワン4
迌トゥ4
迍ヂュン1
迎イン2
迏ダー2
运ユン4
近ジン4
迒ハン2
迓ヤー4
返ファン3
迕ウー4
迖ダー2
迗ェ゛ァ2
还ファン2
这ヂェ゛ァ4
迚ダー2
进ジン4
远ユェン3
违ウェイ2
连リィェン2
迟チー2
迠チェ゛ァ4
迡ニー4
迢ティァォ2
迣ヂー4
迤イー2
迥ジィォン3
迦ジャ1
迧チェン2
迨ダイ4
迩ェ゛ァー3
迪デ2
迫ポォ4
迬ヂュ4
迭ディェ2
迮ゼェ゛ァ2
迯タオ2
述シュ4
迱トゥォ2
迲チュ
迳ジン4
迴フゥェイ2
迵ドン4
迶ヨウ4
迷ミィ2
迸ボン4
迹ジー4
迺ナイ3
迻イー2
迼ジェ2
追ヂュェイ1
迾リィェ4
迿シュン4
退トゥェイ4
送ソン4
适シー4
逃タオ2
逄パン2
逅ホウ4
逆ニー4
逇ドゥン4
逈ジィォン3
选シュェン3
逊シュン4
逋ブー1
逌ヨウ1
逍シァォ1
逎チィゥ2
透トウ4
逐ヂュ2
逑チィゥ2
递デ4
逓デ4
途トゥ2
逕ジン4
逖ティ4
逗ドウ4
逘イー3
這ヂェ゛ァ4
通トン1
逛グゥァン4
逜ウー4
逝シー4
逞チォン3
速スー4
造ザオ4
逡チュン1
逢フォン2
連リィェン2
逤スォ4
逥フゥェイ2
逦リー3
逧グー3
逨ライ2
逩ベン4
逪ツゥォ4
逫ジュェ2
逬ボン4
逭ファン4
逮ダイ3
逯ルー4
逰ヨウ2
週ヂョウ1
進ジン4
逳ユー4
逴チュォ1
逵クゥェイ2
逶ウェイ1
逷ティ4
逸イー4
逹ダー2
逺ユェン3
逻ルゥォ2
逼ビー1
逽ヌオ4
逾ユー2
逿ダン4
遀スェイ2
遁ドゥン4
遂スェイ4
遃イェン3
遄チュァン2
遅チー2
遆ティ2
遇ユー4
遈シー2
遉ヂェン1
遊ヨウ2
運ユン4
遌ェ゛ァ4
遍ビィェン4
過グゥォ4
遏ェ゛ァ4
遐シァ2
遑フゥァン2
遒チィゥ2
道ダオ4
達ダー2
違ウェイ2
遖ナン2
遗イー2
遘ゴウ4
遙イャォ2
遚チョウ4
遛リィゥ2
遜シュン4
遝ター4
遞デ4
遟チー2
遠ユェン3
遡スー4
遢ター4
遣チィェン3
遤マー3
遥イャォ2
遦グァン4
遧ヂャン1
遨アオ2
適シー4
遪ツァ4
遫チー4
遬スー4
遭ザオ1
遮ヂェ゛ァ1
遯ドゥン4
遰デ4
遱ロウ2
遲チー2
遳ツゥォ1
遴リン2
遵ズン1
遶ラオ4
遷チィェン1
選シュェン3
遹ユー4
遺イー2
遻ェ゛ァ4
遼リィァォ2
遽ジュ4
遾シー4
避ビー4
邀イャォ1
邁マイ4
邂シェ4
邃スェイ4
還ファン2
邅ヂャン1
邆トン2
邇ェ゛ァー3
邈ミィァォ3
邉ビィェン1
邊ビィェン1
邋ラー1
邌リー2
邍ユェン2
邎イャォ2
邏ルゥォ2
邐リー3
邑イー4
邒ティン2
邓ドン4
邔チー3
邕ヨン1
邖シャン1
邗ハン2
邘ユー2
邙マン2
邚ルー2
邛チォン2
邜シー1
邝クゥァン4
邞フー1
邟カン4
邠ビン1
邡ファン1
邢シン2
那ナー4
邤シン1
邥シェン3
邦バン1
邧ユェン2
邨ツン1
邩フォ3
邪シェ2
邫バン1
邬ウー1
邭ジュ4
邮ヨウ2
邯ハン2
邰タイ2
邱チィゥ1
邲ビー4
邳ピー1
邴ビン3
邵シャオ4
邶ベイ4
邷ウァ3
邸デ3
邹ゾウ1
邺イェ4
邻リン2
邼クゥァン1
邽グゥェイ1
邾ヂュ1
邿シー1
郀クー1
郁ユー4
郂ガイ1
郃フェ゛ァ2
郄チィェ4
郅ヂー4
郆ジー2
郇ファン2
郈ホウ4
郉シン2
郊ジャオ1
郋シー2
郌グゥェイ1
郍ヌオ2
郎ラン2
郏ジャ2
郐クァィ4
郑ヂォン4
郒ラン2
郓ユン4
郔イェン2
郕チォン2
郖ドウ4
郗シー1
郘リュ3
郙フー3
郚ウー2
郛フー2
郜ガオ4
郝ハオ3
郞ラン2
郟ジャ2
郠ゴン3
郡ジュン4
郢イン3
郣ブォ2
郤シー4
郥ベイ4
郦リー4
郧ユン2
部ブー4
郩シァォ2
郪チー1
郫ピー2
郬チン1
郭グゥォ1
郮ヂョウ1
郯タン2
郰ゾウ1
郱ピン2
郲ライ2
郳ニー2
郴チェン1
郵ヨウ2
郶ブー4
郷シィァン1
郸ダン1
郹ジュ2
郺ヨン1
郻チィァォ1
郼イー1
都ドウ1
郾イェン3
郿メイ2
鄀ルォ4
鄁ベイ4
鄂ェ゛ァ4
鄃シュ1
鄄ジュェン4
鄅ユー3
鄆ユン4
鄇ホウ2
鄈クゥェイ2
鄉シィァン1
鄊シィァン1
鄋ソウ1
鄌タン2
鄍ミン2
鄎シー1
鄏ルー3
鄐チュ4
鄑ズー1
鄒ゾウ1
鄓イェ4
鄔ウー1
鄕シィァン1
鄖ユン2
鄗ハオ4
鄘ヨン1
鄙ビー3
鄚マオ4
鄛チャオ2
鄜フー1
鄝リィァォ3
鄞イン2
鄟ヂュァン1
鄠フー4
鄡チィァォ1
鄢イェン1
鄣ヂャン1
鄤マン4
鄥チィァォ1
鄦シュ3
鄧ドン4
鄨ビー4
鄩シュン2
鄪ビー4
鄫ゾン1
鄬ウェイ2
鄭ヂォン4
鄮マオ4
鄯シャン4
鄰リン2
鄱ポォ2
鄲ダン1
鄳モン2
鄴イェ4
鄵ツァォ4
鄶クァィ4
鄷フォン1
鄸モン2
鄹ゾウ1
鄺クゥァン4
鄻リィェン3
鄼ザン4
鄽チャン2
鄾ヨウ1
鄿ジー1
酀イェン4
酁チャン2
酂ツゥォ2
酃リン2
酄ファン1
酅シー1
酆フォン1
酇ザン4
酈リー4
酉ヨウ3
酊ディン1
酋チィゥ2
酌ヂュオ2
配ペイ4
酎ヂョウ4
酏イー3
酐ガン1
酑ユー2
酒ジゥ3
酓イェン3
酔ズェイ4
酕マオ2
酖ヂェン4
酗シュ4
酘ドウ4
酙ヂェン1
酚フェン1
酛ユェン2
酜フー
酝ユン4
酞タイ4
酟ティェン1
酠チィァ3
酡トゥォ2
酢ツゥ4
酣ハン1
酤グー1
酥スー1
酦ポォ4
酧チョウ2
酨ザイ4
酩ミン3
酪ラオ4
酫チュォ4
酬チョウ2
酭ヨウ4
酮トン2
酯ヂー3
酰シィェン1
酱ジィァン4
酲チォン2
酳イン4
酴トゥ2
酵ジャオ4
酶メイ2
酷クー4
酸スゥァン1
酹レイ4
酺プー2
酻ズェイ4
酼ファン3
酽イェン4
酾シャイ1
酿ニィァン4
醀ウェイ2
醁ルー4
醂ラン3
醃イェン1
醄タオ2
醅ペイ1
醆ヂャン3
醇チュン2
醈タン2
醉ズェイ4
醊ヂュェイ4
醋ツゥ4
醌クン1
醍ティ2
醎シィェン2
醏ドゥ1
醐フー2
醑シュ3
醒シン3
醓タン3
醔チィゥ2
醕チュン2
醖ユン4
醗ポォ4
醘クェ゛ァ1
醙ソウ1
醚ミィ2
醛チュェン2
醜チョウ3
醝ツゥォ1
醞ユン4
醟ヨン4
醠アン4
醡ヂャ4
醢ファン3
醣タン2
醤ジィァン4
醥ピィァオ3
醦チェン3
醧ユー4
醨リー2
醩ザオ1
醪ラオ2
醫イー1
醬ジィァン4
醭ブー2
醮ジャオ4
醯シー1
醰タン2
醱ファ1
醲ノン2
醳イー4
醴リー3
醵ジュ4
醶イェン4
醷イー4
醸ニィァン4
醹ルー2
醺シュン1
醻チョウ2
醼イェン4
醽リン2
醾ミィ2
醿ミィ2
釀ニィァン4
釁シン4
釂ジャオ4
釃シャイ1
釄ミィ2
釅イェン4
釆ビィェン4
采ツァィ3
釈シー4
釉ヨウ4
释シー4
釋シー4
里リー3
重ヂョン4
野イェ3
量リィァン4
釐リー2
金ジン1
釒ジン1
釓チィゥ2
釔イー3
釕リィァォ3
釖ダオ1
釗ヂャオ1
釘ディン1
釙ポォ4
釚チィゥ2
釛バー1
釜フー3
針ヂェン1
釞ヂー2
釟バー1
釠ルゥァン4
釡フー3
釢ナイ3
釣ディァォ4
釤シャン4
釥チィァォ3
釦コウ4
釧チュァン4
釨ズー3
釩ファン3
釪ファ2
釫ファ2
釬ハン4
釭ガン1
釮チー2
釯マン2
釰リ゛ー4
釱デ4
釲スー4
釳シー4
釴イー4
釵チャイ1
釶シー1
釷トゥ3
釸シー1
釹ニュ3
釺チィェン1
釻チィゥ2
釼ジィェン4
釽ピー4
釾イェ2
釿ジン1
鈀バー3
鈁ファン1
鈂チェン2
鈃シン2
鈄ドウ3
鈅ユェ4
鈆チィェン1
鈇フー1
鈈ピー1
鈉ナー4
鈊シン1
鈋ェ゛ァ2
鈌ジュェ2
鈍ドゥン4
鈎ゴウ1
鈏イン3
鈐チィェン2
鈑バン3
鈒サー4
鈓レン2
鈔チャオ1
鈕ニィゥ3
鈖フェン1
鈗ユン3
鈘イー3
鈙チン2
鈚ピー1
鈛グゥォ1
鈜ホン2
鈝イン2
鈞ジュン1
鈟ディァォ4
鈠イー4
鈡ヂョン1
鈢シー3
鈣ガイ4
鈤リ゛ー4
鈥フォ3
鈦タイ4
鈧カン4
鈨ユェン2
鈩ルー2
鈪ェ゛ァ4
鈫チン2
鈬ドゥォ2
鈭ズー1
鈮ニー3
鈯トゥ2
鈰シー4
鈱ミン2
鈲グー1
鈳クェ゛ァ1
鈴リン2
鈵ビン3
鈶スー4
鈷グー3
鈸ブォ2
鈹ピー1
鈺ユー4
鈻スー4
鈼ズゥォ2
鈽ブー1
鈾ヨウ2
鈿ティェン2
鉀ジャ3
鉁ヂェン1
鉂シー3
鉃シー4
鉄ヂー2
鉅ジュ4
鉆チャン1
鉇シー1
鉈シー1
鉉シュェン4
鉊ヂャオ1
鉋バオ4
鉌フェ゛ァ2
鉍ビー4
鉎シォン1
鉏チュ2
鉐シー2
鉑ブォ2
鉒ヂュ4
鉓チー4
鉔ザー1
鉕ポォ1
鉖トン2
鉗チィェン2
鉘フー2
鉙ヂャイ3
鉚リィゥ3
鉛チィェン1
鉜フー2
鉝リー4
鉞ユェ4
鉟ピー1
鉠ヤン1
鉡バン4
鉢ブォ1
鉣ジェ2
鉤ゴウ1
鉥シュ4
鉦ヂォン1
鉧ムー3
鉨シー3
鉩シー3
鉪デ4
鉫ジャ1
鉬ムー4
鉭タン3
鉮ファン2
鉯イー3
鉰スー1
鉱クゥァン4
鉲カー3
鉳ベイ3
鉴ジィェン4
鉵トン2
鉶シン2
鉷ホン2
鉸ジャオ3
鉹チー3
鉺ェ゛ァー4
鉻ルゥォ4
鉼ビン3
鉽シー4
鉾モウ2
鉿ジャ1
銀イン2
銁ジュン1
銂ヂョウ1
銃チョン4
銄シィァン3
銅トン2
銆ムー4
銇レイ4
銈ジー1
銉ユー4
銊シュ4
銋レン2
銌ズン4
銍ヂー4
銎チォン2
銏シャン4
銐チー4
銑シィェン3
銒シン2
銓チュェン2
銔ピー1
銕ティェ3
銖ヂュ1
銗シィァン4
銘ミン2
銙クァ3
銚イャォ2
銛シィェン1
銜シィェン2
銝シゥ1
銞ジュン1
銟チャ1
銠ラオ3
銡ジー2
銢ピー3
銣ルー2
銤ミィ3
銥イー1
銦イン1
銧グゥァン1
銨アン3
銩ディゥ1
銪ヨウ3
銫スェ゛ァ4
銬カオ4
銭チィェン2
銮ルゥァン2
銯スー1
銰ェ゛ァ1
銱ディァォ4
銲ハン4
銳ルェイ4
銴シー4
銵コン1
銶チィゥ2
銷シァォ1
銸ヂェ゛ァ2
銹シゥ4
銺ザン4
銻ティ2
銼ツゥォ4
銽グァ1
銾ホン4
銿ヂョン1
鋀トウ1
鋁リュ3
鋂メイ2
鋃ラン2
鋄ワン4
鋅シン1
鋆ユン2
鋇ベイ4
鋈ウー4
鋉スー4
鋊ユー4
鋋チャン2
鋌ディン4
鋍ブォ2
鋎ハン4
鋏ジャ2
鋐ホン2
鋑ツァン1
鋒フォン1
鋓チャン1
鋔ワン3
鋕ヂー4
鋖スー1
鋗シュェン1
鋘ファ2
鋙ユー3
鋚ティァォ2
鋛クゥァン4
鋜ヂュオ2
鋝リュェ4
鋞シン2
鋟チン3
鋠シェン4
鋡ハン2
鋢リュェ4
鋣イェ2
鋤チュ2
鋥ゾン4
鋦ジュ1
鋧シィェン4
鋨ティェ3
鋩マン2
鋪プー4
鋫リー2
鋬パン4
鋭ルェイ4
鋮チォン2
鋯ガオ4
鋰リー3
鋱テェ゛ァ4
鋲ビン1
鋳ヂュ4
鋴ヂェン4
鋵トゥ1
鋶リィゥ3
鋷ズェイ4
鋸ジュ4
鋹chang3
鋺ユェン3
鋻ジィェン4
鋼ガン1
鋽ディァォ4
鋾タオ2
鋿chang2
錀ルゥン2
錁グゥォ3
錂リン2
錃ピー1
錄ルー4
錅リー2
錆チィァン1
錇ポウ2
錈ジュェン3
錉ミン2
錊ズェイ4
錋ポン2
錌アン4
錍ピー1
錎シィェン4
錏ヤー1
錐ヂュェイ1
錑レイ4
錒クェ゛ァ1
錓コン1
錔ター4
錕クン1
錖ドゥ2
錗ネイ4
錘チュェイ2
錙ズー1
錚ヂォン1
錛ベン1
錜ニィェ4
錝ゾン4
錞チュン2
錟タン2
錠ディン4
錡チー2
錢チィェン2
錣ヂュェイ4
錤ジー1
錥ユー4
錦ジン3
錧グァン3
錨マオ2
錩chang1
錪ティェン3
錫シー1
錬リィェン4
錭タオ2
錮グー4
錯ツゥォ4
錰シュ4
錱ヂェン1
録ルー4
錳モン3
錴ルー4
錵ファ1
錶ビィャォ3
錷チィェ2
錸ライ2
錹ケン3
錺ファン1
錻ウー
錼ナイ4
錽ワン4
錾ザン4
錿フー3
鍀ディ2
鍁シィェン1
鍂ピィェン1
鍃フォ1
鍄リィァン4
鍅ファ3
鍆メン2
鍇カイ3
鍈イン1
鍉デ1
鍊リィェン4
鍋グゥォ1
鍌シィェン3
鍍ドゥ4
鍎トゥ2
鍏ウェイ2
鍐ゾン1
鍑フー4
鍒ロウ2
鍓ジー2
鍔ェ゛ァ4
鍕ジュン1
鍖チェン3
鍗ティ2
鍘ヂャ2
鍙フー4
鍚ヤン2
鍛ドゥァン4
鍜シァ2
鍝ユー2
鍞コン1
鍟シォン1
鍠フゥァン2
鍡ウェイ3
鍢フー4
鍣ヂャオ1
鍤チャ1
鍥チィェ4
鍦シー1
鍧ホン1
鍨クゥェイ2
鍩ティェン3
鍪モウ2
鍫チィァォ1
鍬チィァォ1
鍭ホウ2
鍮トウ1
鍯ツォン1
鍰ファン2
鍱イェ4
鍲ミン2
鍳ジィェン4
鍴ドゥァン1
鍵ジィェン4
鍶ソン1
鍷クゥェイ2
鍸フー2
鍹シュェン1
鍺ドゥォ3
鍻ジェ2
鍼ヂェン1
鍽ビィェン1
鍾ヂョン1
鍿ズー1
鎀シゥ1
鎁イェ2
鎂メイ3
鎃パイ4
鎄ェ゛ァ1
鎅ジェ4
鎆チィェン
鎇メイ2
鎈スォ3
鎉ダー2
鎊バン4
鎋シァ2
鎌リィェン2
鎍スォ3
鎎カイ4
鎏リィゥ2
鎐イャォ2
鎑イェ4
鎒ノウ4
鎓ウォン1
鎔ロン2
鎕タン2
鎖スォ3
鎗チィァン1
鎘リー4
鎙シュォ4
鎚チュェイ2
鎛ブォ2
鎜パン2
鎝ダー1
鎞ビー1
鎟サン3
鎠ガン1
鎡ズー1
鎢ウー1
鎣イン2
鎤フゥァン4
鎥ティァォ2
鎦リィゥ2
鎧カイ3
鎨スン3
鎩シャ1
鎪ソウ1
鎫ワン4
鎬ハオ4
鎭ヂェン4
鎮ヂェン4
鎯ラン2
鎰イー4
鎱ユェン2
鎲タン3
鎳ニィェ4
鎴シー2
鎵ジャ1
鎶グェ゛ァ1
鎷マー3
鎸ジュェン1
鎹ソン4
鎺ズー3
鎻スォ3
鎼シァ4
鎽フォン1
鎾ウェン1
鎿ナー2
鏀ルー3
鏁スォ3
鏂オウ1
鏃ズー2
鏄トゥァン2
鏅シゥ1
鏆グァン4
鏇シュェン4
鏈リィェン4
鏉ショウ4
鏊アオ4
鏋マン3
鏌ムー4
鏍ルゥォ2
鏎ビー4
鏏ウェイ4
鏐リィゥ2
鏑デ2
鏒サン3
鏓ゾン3
鏔イー2
鏕ルー4
鏖アオ2
鏗コン1
鏘チィァン1
鏙ツェイ1
鏚チー1
鏛chang2
鏜タン1
鏝マン4
鏞ヨン1
鏟チャン3
鏠フォン1
鏡ジン4
鏢ビィャォ1
鏣シュ4
鏤ロウ4
鏥シゥ4
鏦ツォン1
鏧ロン2
鏨ザン4
鏩ジィェン4
鏪ツァォ2
鏫リー2
鏬シァ4
鏭シー1
鏮カン1
鏯シュゥァン3
鏰ボン4
鏱ヂャン
鏲チィェン
鏳チォン1
鏴ルー4
鏵ファ2
鏶ジー2
鏷プー2
鏸フゥェイ4
鏹チィァン3
鏺ポォ1
鏻リン2
鏼スェ゛ァ4
鏽シゥ4
鏾サン3
鏿チォン1
鐀クゥェイ4
鐁スー1
鐂リィゥ2
鐃ナオ2
鐄フゥァン2
鐅ピェ3
鐆スェイ4
鐇ファン2
鐈チィァォ2
鐉チュェン1
鐊ヤン2
鐋タン1
鐌シィァン4
鐍ジュェ2
鐎ジャオ1
鐏ズン1
鐐リィァォ2
鐑チィェ4
鐒ラオ2
鐓ドゥェイ4
鐔シン2
鐕ザン1
鐖ジー1
鐗ジィェン3
鐘ヂョン1
鐙ドン4
鐚ヤー1
鐛イン3
鐜ドゥェイ1
鐝ジュェ2
鐞ノウ4
鐟ザン1
鐠プー3
鐡ティェ3
鐢ファン2
鐣チォン1
鐤ディン3
鐥シャン4
鐦カイ1
鐧ジィェン1
鐨フェイ4
鐩スェイ4
鐪ルー3
鐫ジュェン1
鐬フゥェイ4
鐭ユー4
鐮リィェン2
鐯ヂュオ2
鐰チィァォ1
鐱ジィェン4
鐲ヂュオ2
鐳レイ2
鐴ビー4
鐵ティェ3
鐶ファン2
鐷イェ4
鐸ドゥォ2
鐹グゥォ3
鐺ダン1
鐻ジュ4
鐼フェン2
鐽ダー2
鐾ベイ4
鐿イー4
鑀ェ゛ァ4
鑁ゾン1
鑂シュン4
鑃ディァォ4
鑄ヂュ4
鑅ホン2
鑆ヂュェイ4
鑇ジー1
鑈ニィェ4
鑉フェ゛ァ2
鑊フォ4
鑋チン1
鑌ビン1
鑍イン1
鑎クゥェイ4
鑏ニン2
鑐シュ1
鑑ジィェン4
鑒ジィェン4
鑓チィェン3
鑔チャ3
鑕ヂー4
鑖ミィェ4
鑗リー2
鑘レイ2
鑙ジー1
鑚ズァン4
鑛クゥァン4
鑜シャン3
鑝ポン2
鑞ラー4
鑟ドゥ2
鑠シュォ4
鑡チュォ4
鑢リュ4
鑣ビィャォ1
鑤バオ4
鑥ルー3
鑦シィェン
鑧クァン1
鑨ロン2
鑩ェ゛ァ4
鑪ルー2
鑫シン1
鑬ジィェン4
鑭ラン4
鑮ブォ2
鑯ジィェン1
鑰イャォ4
鑱チャン2
鑲シィァン1
鑳ジィェン4
鑴シー1
鑵グァン4
鑶ツァン2
鑷ニィェ4
鑸レイ3
鑹ツァン1
鑺チュ2
鑻パン4
鑼ルゥォ2
鑽ズァン1
鑾ルゥァン2
鑿ザオ2
钀ニィェ4
钁ジュェ2
钂タン3
钃ヂュ2
钄ラン2
钅ジン1
钆チィェ2
钇イー3
针ヂェン1
钉ディン1
钊ヂャオ1
钋ポォ1
钌リィァォ3
钍トゥ3
钎チィェン1
钏チュァン4
钐シャン1
钑サー4
钒ファン2
钓ディァォ4
钔メン2
钕ニュ3
钖ヤン2
钗チャイ1
钘シン2
钙ガイ4
钚ブー4
钛タイ4
钜ジュ4
钝ドゥン4
钞チャオ1
钟ヂョン1
钠ナー4
钡ベイ4
钢ガン1
钣バン3
钤チィェン2
钥イャォ4
钦チン1
钧ジュン1
钨ウー1
钩ゴウ1
钪カン4
钫ファン1
钬フォ3
钭トウ3
钮ニィゥ3
钯バー3
钰ユー4
钱チィェン2
钲ヂォン1
钳チィェン2
钴グー3
钵ブォ1
钶クェ゛ァ1
钷ポォ3
钸ブー1
钹ブォ2
钺ユェ4
钻ズァン1
钼ムー4
钽タン3
钾ジャ3
钿ディェン4
铀ヨウ2
铁ティェ3
铂ブォ2
铃リン2
铄シュォ4
铅チィェン1
铆マオ3
铇バオ4
铈シー4
铉シュェン4
铊ター1
铋ビー4
铌ニー2
铍ピー1
铎ドゥォ2
铏シン2
铐カオ4
铑ラオ3
铒ェ゛ァー3
铓マン2
铔ヤー1
铕ヨウ3
铖チォン2
铗ジャ2
铘イェ2
铙ナオ2
铚ヂー4
铛ダン1
铜トン2
铝リュ3
铞ディァォ4
铟イン1
铠カイ3
铡ヂャ2
铢ヂュ1
铣シー3
铤ディン4
铥ディゥ1
铦シィェン1
铧ファ2
铨チュェン2
铩シャ1
铪ハー1
铫ディァォ4
铬グェ゛ァ4
铭ミン2
铮ヂォン1
铯スェ゛ァ4
铰ジャオ3
铱イー1
铲チャン3
铳チョン4
铴タン1
铵アン3
银イン2
铷ルー2
铸ヂュ4
铹ラオ2
铺プー4
铻ウー2
铼ライ2
铽テェ゛ァ4
链リィェン4
铿コン1
销シァォ1
锁スォ3
锂リー3
锃ゾン4
锄チュ2
锅グゥォ1
锆ガオ4
锇ェ゛ァ2
锈シゥ4
锉ツゥォ4
锊リュェ4
锋フォン1
锌シン1
锍リィゥ3
锎カイ1
锏ジィェン3
锐ルェイ4
锑ティ1
锒ラン2
锓チン3
锔ジュ1
锕アー1
锖チィァン1
锗ヂェ゛ァ3
锘ヌオ4
错ツゥォ4
锚マオ2
锛ベン1
锜チー2
锝ディ2
锞クェ゛ァ4
锟クン1
锠chang1
锡シー1
锢グー4
锣ルゥォ2
锤チュェイ2
锥ヂュェイ1
锦ジン3
锧ヂー4
锨シィェン1
锩ジュェン3
锪フォ1
锫ペイ2
锬タン2
锭ディン4
键ジィェン4
锯ジュ4
锰モン3
锱ズー1
锲チィェ4
锳イン1
锴カイ3
锵チィァン1
锶スー1
锷ェ゛ァ4
锸チャ1
锹チィァォ1
锺ヂョン1
锻ドゥァン4
锼ソウ1
锽フゥァン2
锾ファン2
锿ェ゛ァ1
镀ドゥ4
镁メイ3
镂ロウ4
镃ズー1
镄フェイ4
镅メイ2
镆ムー4
镇ヂェン4
镈ブォ2
镉グェ゛ァ2
镊ニィェ4
镋タン3
镌ジュェン1
镍ニィェ4
镎ナー2
镏リィゥ2
镐ガオ3
镑バン4
镒イー4
镓ジャ1
镔ビン1
镕ロン2
镖ビィャォ1
镗タン1
镘マン4
镙ルゥォ2
镚ボン4
镛ヨン1
镜ジン4
镝デ1
镞ズー2
镟シュェン4
镠リィゥ2
镡チャン2
镢ジュェ2
镣リィァォ4
镤プー2
镥ルー3
镦ドゥェイ4
镧ラン2
镨プー3
镩ツァン1
镪チィァン1
镫ドン4
镬フォ4
镭レイ2
镮ファン2
镯ヂュオ2
镰リィェン2
镱イー4
镲チャ3
镳ビィャォ1
镴ラー4
镵チャン2
镶シィァン1
長ヂャン3
镸chang2
镹ジゥ3
镺アオ3
镻ディェ2
镼チュ1
镽リィァォ3
镾ミィ2
长ヂャン3
門メン2
閁マー4
閂シュァン1
閃シャン3
閄フォ4
閅メン2
閆イェン2
閇ビー4
閈ハン4
閉ビー4
閊シャン1
開カイ1
閌カン4
閍ボン1
閎ホン2
閏ルン4
閐サン4
閑シィェン2
閒シィェン2
間ジィェン1
閔ミン3
閕シァ1
閖シュェイ
閗ドウ4
閘ヂャ2
閙ナオ4
閚ヂャン1
閛ポン1
閜シァ3
閝リン2
閞ビィェン4
閟ビー4
閠ルン4
閡ェ゛ァ4
関グァン1
閣グェ゛ァ2
閤グェ゛ァ2
閥ファ2
閦チュ4
閧ホン4
閨グゥェイ1
閩ミン3
閪スェ゛ァ1
閫クン3
閬ラン4
閭リュ2
閮ティン2
閯シャ4
閰ジュ2
閱ユェ4
閲ユェ4
閳チャン3
閴チュ4
閵リン4
閶chang1
閷シャイ4
閸クン3
閹イェン1
閺ウェン2
閻イェン2
閼ェ゛ァ4
閽フン1
閾ユー4
閿ウェン2
闀ホン4
闁バオ1
闂ホン4
闃チュ4
闄イャォ3
闅ウェン2
闆バン3
闇アン4
闈ウェイ2
闉イン1
闊クォ4
闋チュェ4
闌ラン2
闍ドゥ1
闎チュェン2
闏フォン1
闐ティェン2
闑ニィェ4
闒ター4
闓カイ3
闔フェ゛ァ2
闕チュェ4
闖チュゥァン3
闗グァン1
闘ドウ4
闙チー3
闚クゥェイ1
闛タン2
關グァン1
闝ピィァオ2
闞カン4
闟シー4
闠フゥェイ4
闡チャン3
闢ピー4
闣ダン4
闤ファン2
闥ター4
闦ウェン2
闧ター1
门メン2
闩シュァン1
闪シャン3
闫イェン2
闬ハン4
闭ビー4
问ウェン4
闯チュゥァン3
闰ルン4
闱ウェイ2
闲シィェン2
闳ホン2
间ジィェン1
闵ミン3
闶カン1
闷メン4
闸ヂャ2
闹ナオ4
闺グゥェイ1
闻ウェン2
闼ター4
闽ミン3
闾リュ2
闿カイ3
阀ファ2
阁グェ゛ァ2
阂フェ゛ァ2
阃クン3
阄ジゥ1
阅ユェ4
阆ラン2
阇ドゥ1
阈ユー4
阉イェン1
阊chang1
阋シー4
阌ウェン2
阍フン1
阎イェン2
阏ェ゛ァ4
阐チャン3
阑ラン2
阒チュ4
阓フゥェイ4
阔クォ4
阕チュェ4
阖フェ゛ァ2
阗ティェン2
阘ダー2
阙チュェ1
阚ハン3
阛ファン2
阜フー4
阝フー4
阞ラ4
队ドゥェイ4
阠シン4
阡チィェン1
阢ウー4
阣ガイ4
阤ヂー4
阥イン1
阦ヤン2
阧ドウ3
阨ェ゛ァ4
阩シォン1
阪バン3
阫ペイ2
阬コン1
阭ユン3
阮ルァン3
阯ヂー3
阰ピー2
阱ジン3
防ファン2
阳ヤン2
阴イン1
阵ヂェン4
阶ジェ1
阷チォン1
阸ェ゛ァ4
阹チュ1
阺デ3
阻ズー3
阼ズゥォ4
阽ディェン4
阾リン3
阿アー1
陀トゥォ2
陁トゥォ2
陂ベイ1
陃ビン3
附フー4
际ジー4
陆ルー4
陇ロン3
陈チェン2
陉シン2
陊ドゥォ4
陋ロウ4
陌ムー4
降ジィァン4
陎シュ1
陏ドゥォ4
限シィェン4
陑ェ゛ァー2
陒グゥェイ3
陓ユー1
陔ガイ1
陕シャン3
陖ジュン4
陗チィァォ4
陘シン2
陙チュン2
陚フー4
陛ビー4
陜シァ2
陝シャン3
陞シォン1
陟ヂー4
陠プー1
陡ドウ3
院ユェン4
陣ヂェン4
除チュ2
陥シィェン4
陦ダオ3
陧ニィェ4
陨ユン3
险シィェン3
陪ペイ2
陫フェイ4
陬ゾウ1
陭イー4
陮ドゥェイ4
陯ルゥン2
陰イン1
陱ジュ1
陲チュェイ2
陳チェン2
陴ピー2
陵リン2
陶タオ2
陷シィェン4
陸ルー4
陹シォン1
険シィェン3
陻イン1
陼ヂュ3
陽ヤン2
陾ロン2
陿シァ2
隀チョン2
隁イェン4
隂イン1
隃シュ4
隄デ1
隅ユー2
隆ロン2
隇ウェイ1
隈ウェイ1
隉ニィェ4
隊ドゥェイ4
隋スェイ2
隌アン3
隍フゥァン2
階ジェ1
随スェイ2
隐イン3
隑ガイ4
隒イェン3
隓フゥェイ1
隔グェ゛ァ2
隕ユン3
隖ウー4
隗クゥェイ2
隘ェ゛ァ4
隙シー4
隚タン2
際ジー4
障ヂャン4
隝ダオ3
隞アオ2
隟シー4
隠イン3
隡サー4
隢ラオ3
隣リン2
隤トゥェイ2
隥ドン4
隦ジャオ3
隧スェイ4
隨スェイ2
隩アオ4
險シィェン3
隫フェン2
隬ニー3
隭ェ゛ァー2
隮ジー1
隯ダオ3
隰シー2
隱イン3
隲ヂー4
隳フゥェイ1
隴ロン3
隵シー1
隶リー4
隷リー4
隸リー4
隹ヂュェイ1
隺フー2
隻ヂー1
隼スン3
隽ジュェン4
难ナン2
隿イー4
雀チュェ4
雁イェン4
雂チン2
雃チィェン1
雄シィォン2
雅ヤー3
集ジー2
雇グー4
雈ファン2
雉ヂー4
雊ゴウ4
雋ジュェン4
雌ツー2
雍ヨン1
雎ジュ1
雏チュ2
雐フー1
雑ザー2
雒ルゥォ4
雓ユー2
雔チョウ2
雕ディァォ1
雖スェイ1
雗ハン4
雘ウォ4
雙シュゥァン1
雚グァン4
雛チュ2
雜ザー2
雝ヨン1
雞ジー1
雟シー1
雠チョウ2
雡リィゥ4
離リー2
難ナン2
雤シュェ2
雥ザー2
雦ジー2
雧ジー2
雨ユー3
雩ユー2
雪シュェ3
雫ナー3
雬フォウ3
雭スェ゛ァ4
雮ムー4
雯ウェン2
雰フェン1
雱パン1
雲ユン2
雳リー4
雴チー4
雵ヤン1
零リン2
雷レイ2
雸アン2
雹バオ2
雺ウー4
電ディェン4
雼ダン4
雽フー4
雾ウー4
雿ディァォ4
需シュ1
霁ジー4
霂ムー4
霃チェン2
霄シァォ1
霅ヂャ4
霆ティン2
震ヂェン4
霈ペイ4
霉メイ2
霊リン2
霋チー1
霌ヂョウ1
霍フォ4
霎シャ4
霏フェイ1
霐ホン2
霑ヂャン1
霒イン1
霓ニー2
霔ヂュ4
霕トゥン2
霖リン2
霗リン2
霘ドン4
霙イン1
霚ウー4
霛リン2
霜シュゥァン1
霝リン2
霞シァ2
霟ホン2
霠イン1
霡マイ4
霢マイ4
霣ユン3
霤リィゥ4
霥モン4
霦ビン1
霧ウー4
霨ウェイ4
霩クォ4
霪イン2
霫シー2
霬イー4
霭ェ゛ァ3
霮ダン4
霯トン4
霰サン3
霱ユー4
露ルー4
霳ロン2
霴ダイ4
霵ジー2
霶パン1
霷ヤン2
霸バー4
霹ピー1
霺ウェイ2
霻フォン1
霼シー4
霽ジー4
霾マイ2
霿モン2
靀モン2
靁レイ2
靂リー4
靃フォ4
靄ェ゛ァ3
靅フェイ4
靆ダイ4
靇ロン2
靈リン2
靉ェ゛ァ4
靊フォン1
靋リー4
靌バオ3
靍フェ゛ァ4
靎フェ゛ァ4
靏フェ゛ァ4
靐ビン4
靑チン1
青チン1
靓ジン4
靔ティェン1
靕ヂェン1
靖ジン4
靗チォン1
靘チン4
静ジン4
靚ジン4
靛ディェン4
靜ジン4
靝ティェン1
非フェイ1
靟フェイ1
靠カオ4
靡ミィ2
面ミィェン4
靣ミィェン4
靤バオ4
靥イェ4
靦ティェン3
靧フゥェイ4
靨イェ4
革グェ゛ァ2
靪ディン1
靫チャ2
靬チィェン2
靭レン4
靮デ2
靯ドゥ4
靰ウー4
靱レン4
靲チン2
靳ジン4
靴シュェ1
靵ニィゥ3
靶バー3
靷イン3
靸サー3
靹ナー4
靺ムー4
靻ズー3
靼ダー2
靽バン4
靾イー4
靿イャォ4
鞀タオ2
鞁ベイ4
鞂ジェ1
鞃ホン2
鞄パオ2
鞅ヤン1
鞆ビン3
鞇イン1
鞈グェ゛ァ2
鞉タオ2
鞊ジェ2
鞋シェ2
鞌アン1
鞍アン1
鞎ヘン2
鞏ゴン3
鞐チィァ3
鞑ダー2
鞒チィァォ2
鞓ティン1
鞔マン2
鞕イン4
鞖スェイ1
鞗ティァォ2
鞘チィァォ4
鞙シュェン4
鞚コン4
鞛ボン3
鞜ター4
鞝シャン4
鞞ビン3
鞟クォ4
鞠ジュ1
鞡ラー
鞢シェ4
鞣ロウ2
鞤バン1
鞥-1
鞦チィゥ1
鞧チィゥ1
鞨フェ゛ァ2
鞩チィァォ4
鞪ムー4
鞫ジュ1
鞬ジィェン1
鞭ビィェン1
鞮デ1
鞯ジィェン1
鞰ウェン1
鞱タオ1
鞲ゴウ1
鞳ター4
鞴ベイ4
鞵シェ2
鞶パン2
鞷グェ゛ァ2
鞸ビー4
鞹クォ4
鞺タン1
鞻ロウ2
鞼グゥェイ4
鞽チィァォ2
鞾シュェ1
鞿ジー1
韀ジィェン1
韁ジィァン1
韂チャン4
韃ダー2
韄フー4
韅シィェン3
韆チィェン1
韇ドゥ2
韈ウァ4
韉ジィェン1
韊ラン2
韋ウェイ2
韌レン4
韍フー2
韎メイ4
韏チュェン4
韐グェ゛ァ2
韑ウェイ3
韒チィァォ4
韓ハン2
韔chang4
韕クォ4
韖ロウ3
韗ユン4
韘シェ゛ァ4
韙ウェイ3
韚グェ゛ァ2
韛バイ4
韜タオ1
韝ゴウ1
韞ユン4
韟ガオ1
韠ビー4
韡ウェイ3
韢スェイ4
韣ドゥ2
韤ウァ4
韥ドゥ2
韦ウェイ2
韧レン4
韨フー2
韩ハン2
韪ウェイ3
韫ユン4
韬タオ1
韭ジゥ3
韮ジゥ3
韯シィェン1
韰シェ4
韱シィェン1
韲ジー1
音イン1
韴ザー2
韵ユン4
韶シャオ2
韷ラ4
韸ポン2
韹フゥァン2
韺イン1
韻ユン4
韼ポン2
韽アン1
韾イン1
響シィァン3
頀フー4
頁イェ4
頂ディン3
頃チン3
頄クゥェイ2
項シィァン4
順シュン4
頇ハン1
須シュ1
頉イー2
頊シュ1
頋ェ゛ァ3
頌ソン4
頍クゥェイ3
頎チー2
頏ハン2
預ユー4
頑ワン2
頒バン1
頓ドゥン4
頔デ2
頕ダン1
頖パン4
頗ポォ1
領リン3
頙チェ゛ァ4
頚ジン3
頛レイ4
頜フェ゛ァ2
頝チィァォ1
頞ェ゛ァ4
頟ェ゛ァ2
頠ウェイ3
頡シェ2
頢クォ4
頣シェン3
頤イー2
頥イー2
頦ファン2
頧ドゥェイ3
頨ユー3
頩ピン1
頪レイ4
頫フー3
頬ジャ2
頭トウ2
頮フゥェイ4
頯クゥェイ2
頰ジャ2
頱ルゥォ1
頲ティン3
頳チォン1
頴イン3
頵ユン1
頶フー2
頷ハン4
頸ジン3
頹トゥェイ2
頺トゥェイ2
頻ピン2
頼ライ4
頽トゥェイ2
頾ズー1
頿ズー1
顀チュェイ2
顁ディン4
顂ライ4
顃タン2
顄ハン4
顅チィェン1
顆クェ゛ァ1
顇ツェイ4
顈シュェン3
顉チン1
顊イー2
顋サイ1
題ティ2
額ェ゛ァ2
顎ェ゛ァ4
顏イェン2
顐ウェン4
顑カン3
顒ヨン2
顓ヂュァン1
顔イェン2
顕シィェン3
顖シン4
顗イー3
願ユェン4
顙サン3
顚ディェン1
顛ディェン1
顜ジィァン3
顝クゥェイ1
類レイ4
顟ラオ2
顠ピィァオ3
顡ワィ4
顢マン2
顣ツゥ4
顤イャォ2
顥ハオ4
顦チィァォ2
顧グー4
顨シュン4
顩イェン3
顪フゥェイ4
顫チャン4
顬ルー2
顭モン2
顮ビン1
顯シィェン3
顰ピン2
顱ルー2
顲ラン3
顳ニィェ4
顴チュェン2
页イェ4
顶ディン3
顷チン3
顸ハン1
项シィァン4
顺シュン4
须シュ1
顼シュ1
顽ワン2
顾グー4
顿ドゥン4
颀チー2
颁バン1
颂ソン4
颃ハン2
预ユー4
颅ルー2
领リン3
颇ポォ3
颈ジン3
颉ジェ2
颊ジャ2
颋ティン3
颌フェ゛ァ2
颍イン3
颎ジィォン3
颏クェ゛ァ1
颐イー2
频ピン2
颒フゥェイ4
颓トゥェイ2
颔ハン4
颕イン3
颖イン3
颗クェ゛ァ1
题ティ2
颙ヨン2
颚ェ゛ァ4
颛ヂュァン1
颜イェン2
额ェ゛ァ2
颞ニィェ4
颟マン1
颠ディェン1
颡サン3
颢ハオ4
颣レイ4
颤チャン4
颥ルー2
颦ピン2
颧チュェン2
風フォン1
颩ビィャォ1
颪グァ1
颫フー2
颬シァ1
颭ヂャン3
颮ビィャォ1
颯サー4
颰バー2
颱タイ2
颲リィェ4
颳グァ1
颴シュェン4
颵シャオ1
颶ジュ4
颷ビィャォ1
颸スー1
颹ウェイ3
颺ヤン2
颻イャォ2
颼ソウ1
颽カイ3
颾ソウ1
颿ファン1
飀リィゥ2
飁シー2
飂リィゥ4
飃ピィァオ1
飄ピィァオ1
飅リィゥ2
飆ビィャォ1
飇ビィャォ1
飈ビィャォ1
飉リィァォ2
飊ビィャォ1
飋スェ゛ァ4
飌フォン1
飍シゥ1
风フォン1
飏ヤン2
飐ヂャン3
飑ビィャォ1
飒サー4
飓ジュ4
飔スー1
飕ソウ1
飖イャォ2
飗リィゥ2
飘ピィァオ1
飙ビィャォ1
飚ビィャォ1
飛フェイ1
飜ファン1
飝フェイ1
飞フェイ1
食シー2
飠シー2
飡ツァン1
飢ジー1
飣ディン4
飤スー4
飥トゥォ1
飦ヂャン1
飧スン1
飨シィァン3
飩トゥン2
飪レン4
飫ユー4
飬ジュェン4
飭チー4
飮イン3
飯ファン4
飰ファン4
飱スン1
飲イン3
飳トウ3
飴イー2
飵ズゥォ4
飶ビー4
飷ジェ3
飸タオ1
飹バオ3
飺ツー2
飻ティェ4
飼スー4
飽バオ3
飾シー4
飿ドゥォ4
餀ファン4
餁レン4
餂ティェン3
餃ジャオ3
餄ジャ2
餅ビン3
餆イャォ2
餇トン2
餈ツー2
餉シィァン3
養ヤン3
餋ジュェン4
餌ェ゛ァー3
餍イェン4
餎ラ
餏シー1
餐ツァン1
餑ブォ1
餒ネイ3
餓ェ゛ァ4
餔ブー4
餕ジュン4
餖ドウ4
餗スー4
餘ユー2
餙シー4
餚イャォ2
餛フン2
餜グゥォ3
餝シー4
餞ジィェン4
餟ヂュェイ4
餠ビン3
餡シィェン4
餢ブー4
餣イェ4
餤タン2
餥フェイ1
餦ヂャン1
餧ウェイ4
館グァン3
餩ェ゛ァ4
餪ヌァン3
餫ユン4
餬フー2
餭フゥァン2
餮ティェ4
餯フゥェイ4
餰ジィェン1
餱ホウ2
餲ェ゛ァ4
餳タン2
餴フェン1
餵ウェイ4
餶グー3
餷チャ1
餸ソン4
餹タン2
餺ブォ2
餻ガオ1
餼シー4
餽クゥェイ4
餾リィゥ4
餿ソウ1
饀タオ2
饁イェ4
饂ウェン1
饃ムー2
饄タン2
饅マン2
饆ビー4
饇ユー4
饈シゥ1
饉ジン3
饊サン3
饋クゥェイ4
饌ヂュァン4
饍シャン4
饎チー4
饏ダン4
饐イー4
饑ジー1
饒ラオ2
饓チォン1
饔ヨン1
饕タオ1
饖ウェイ4
饗シィァン3
饘ヂャン1
饙フェン1
饚ファン4
饛モン2
饜イェン4
饝ムー2
饞チャン2
饟シィァン3
饠ルゥォ2
饡ザン4
饢ナン2
饣シー2
饤ディン4
饥ジー1
饦トゥォ1
饧タン2
饨トゥン2
饩シー4
饪レン4
饫ユー4
饬チー4
饭ファン4
饮イン3
饯ジィェン4
饰シー4
饱バオ3
饲スー4
饳ドゥォ4
饴イー2
饵ェ゛ァー3
饶ラオ2
饷シィァン3
饸フェ゛ァ2
饹ラ
饺ジャオ3
饻シー1
饼ビン3
饽ブォ1
饾ドウ4
饿ェ゛ァ4
馀ユー2
馁ネイ3
馂ジュン4
馃グゥォ3
馄フン2
馅シィェン4
馆グァン3
馇チャ1
馈クゥェイ4
馉グー3
馊ソウ1
馋チャン2
馌イェ4
馍ムー2
馎ブォ2
馏リィゥ2
馐シゥ1
馑ジン3
馒マン2
馓サン3
馔ヂュァン4
馕ナン2
首ショウ3
馗クゥェイ2
馘グゥォ2
香シィァン1
馚フェン2
馛ブォ2
馜ニー3
馝ビー4
馞ブォ2
馟トゥ2
馠ハン1
馡フェイ1
馢ジィェン1
馣アン1
馤ェ゛ァ4
馥フー4
馦シィェン1
馧ユン1
馨シン1
馩フェン2
馪ピン1
馫シン1
馬マー3
馭ユー4
馮フォン2
馯ハン4
馰デ2
馱トゥォ2
馲ヂェ゛ァ2
馳チー2
馴シュン2
馵ヂュ4
馶ヂー1
馷ペイ4
馸シン4
馹リ゛ー4
馺サー4
馻ユン3
馼ウェン2
馽ヂー2
馾ダン4
馿リュ2
駀ヨウ2
駁ブォ2
駂バオ3
駃ジュェ2
駄トゥォ2
駅イー4
駆チュ1
駇ウェン2
駈チュ1
駉ジィォン1
駊ポォ3
駋ヂャオ1
駌ユェン1
駍ペイ2
駎ヂョウ4
駏ジュ4
駐ヂュ4
駑ヌー2
駒ジュ1
駓ピー1
駔ザン3
駕ジャ4
駖リン2
駗ヂェン3
駘タイ2
駙フー4
駚ヤン3
駛シー3
駜ビー4
駝トゥォ2
駞トゥォ2
駟スー4
駠リィゥ2
駡マー4
駢ピィェン2
駣タオ2
駤ヂー4
駥ロン2
駦トン2
駧ドン4
駨シュン1
駩チュェン1
駪シェン1
駫ジィォン1
駬ェ゛ァー3
駭ファン4
駮ブォ2
駯ヂュ1
駰イン1
駱ルゥォ4
駲ヂョウ1
駳ダン4
駴ファン4
駵リィゥ2
駶ジュ2
駷ソン3
駸チン1
駹マン2
駺ラン2
駻ハン4
駼トゥ2
駽シュェン1
駾トゥェイ4
駿ジュン4
騀ェ゛ァ3
騁チォン3
騂シン1
騃ェ゛ァ2
騄ルー4
騅ヂュェイ1
騆ヂョウ1
騇シェ゛ァ4
騈ピィェン2
騉クン1
騊タオ2
騋ライ2
騌ゾン1
騍クェ゛ァ4
騎チー2
騏チー2
騐イェン4
騑フェイ1
騒サオ1
験イェン4
騔グェ゛ァ2
騕イャォ3
騖ウー4
騗ピィェン4
騘ツォン1
騙ピィェン4
騚チィェン2
騛フェイ1
騜フゥァン2
騝チィェン2
騞フォ1
騟ユー2
騠ティ2
騡チュェン2
騢シァ2
騣ゾン1
騤クゥェイ2
騥ロウ2
騦スー1
騧グァ1
騨トゥォ2
騩グゥェイ1
騪ソウ1
騫チィェン1
騬チォン2
騭ヂー4
騮リィゥ2
騯ポン2
騰トン2
騱シー2
騲ツァォ3
騳ドゥ2
騴イェン4
騵ユェン2
騶ゾウ1
騷サオ1
騸シャン4
騹チー2
騺ヂー4
騻シュゥァン1
騼ルー4
騽シー2
騾ルゥォ2
騿ヂャン1
驀ムー4
驁アオ4
驂ツァン1
驃ビィャォ1
驄ツォン1
驅チュ1
驆ビー4
驇ヂー4
驈ユー4
驉シュ1
驊ファ2
驋ブォ1
驌スー4
驍シァォ1
驎リン2
驏ヂャン4
驐ドゥン1
驑リィゥ2
驒トゥォ2
驓ツォン2
驔ディェン4
驕ジャオ1
驖ティェ3
驗イェン4
驘ルゥォ2
驙ヂャン1
驚ジン1
驛イー4
驜イェ4
驝トゥォ1
驞ピン1
驟ヂョウ4
驠イェン4
驡ロン2
驢リュ2
驣トン2
驤シィァン1
驥ジー4
驦シュゥァン1
驧ジュ2
驨シー2
驩ファン1
驪リー2
驫ビィャォ1
马マー3
驭ユー4
驮トゥォ2
驯シュン4
驰チー2
驱チュ1
驲リ゛ー4
驳ブォ2
驴リュ2
驵ザン3
驶シー3
驷スー4
驸フー4
驹ジュ1
驺ゾウ1
驻ヂュ4
驼トゥォ2
驽ヌー2
驾ジャ4
驿イー4
骀ダイ4
骁シァォ1
骂マー4
骃イン1
骄ジャオ1
骅ファ2
骆ルゥォ4
骇ファン4
骈ピィェン2
骉ビィャォ1
骊リー2
骋チォン3
验イェン4
骍シン1
骎チン1
骏ジュン4
骐チー2
骑チー2
骒クェ゛ァ4
骓ヂュェイ1
骔ゾン1
骕スー4
骖ツァン1
骗ピィェン4
骘ヂー4
骙クゥェイ2
骚サオ1
骛ウー4
骜アオ4
骝リィゥ2
骞チィェン1
骟シャン4
骠ビィャォ1
骡ルゥォ2
骢ツォン1
骣チャン3
骤ヂョウ4
骥ジー4
骦シュゥァン1
骧シィァン1
骨グー3
骩ウェイ3
骪ウェイ3
骫ウェイ3
骬ユー2
骭ガン4
骮イー4
骯アン1
骰トウ2
骱ジェ4
骲バオ4
骳ベイ4
骴ツー1
骵ティ3
骶デ3
骷クー1
骸ファン2
骹チィァォ1
骺ホウ2
骻クァ4
骼グェ゛ァ2
骽トゥェイ3
骾ゴン3
骿ピィェン2
髀ビー4
髁クェ゛ァ1
髂チィァ4
髃ユー2
髄スェイ3
髅ロウ2
髆ブォ2
髇シァォ1
髈バン3
髉ブォ2
髊ツー1
髋クァン1
髌ビン4
髍ムー2
髎リィァォ2
髏ロウ2
髐シァォ1
髑ドゥ2
髒ザン1
髓スェイ3
體ティ3
髕ビン4
髖クァン1
髗ルー2
高ガオ1
髙ガオ1
髚チィァォ4
髛カオ1
髜チィァォ3
髝ラオ2
髞サオ4
髟ビィャォ1
髠クン1
髡クン1
髢デ2
髣ファン3
髤シゥ1
髥ラン2
髦マオ2
髧ダン4
髨クン1
髩ビン4
髪ファ4
髫ティァォ2
髬ピー1
髭ズー1
髮ファ4
髯ラン2
髰ティ4
髱バオ4
髲ビー4
髳マオ2
髴フー2
髵ェ゛ァー2
髶ロン2
髷チュ1
髸ゴン1
髹シゥ1
髺クォ4
髻ジー4
髼ポン2
髽ヂュア1
髾シャオ1
髿スォ1
鬀ティ4
鬁リー4
鬂ビン4
鬃ゾン1
鬄デ2
鬅ポン2
鬆ソン1
鬇ヂォン1
鬈チュェン2
鬉ゾン1
鬊シュン4
鬋ジィェン3
鬌トゥォ3
鬍フー2
鬎ラー4
鬏ジゥ1
鬐チー2
鬑リィェン2
鬒ヂェン3
鬓ビン4
鬔ポン2
鬕マー4
鬖サン1
鬗マン2
鬘マン2
鬙ソン1
鬚シュ1
鬛リィェ4
鬜チィェン1
鬝チィェン1
鬞ナン2
鬟ファン2
鬠クォ4
鬡ニン2
鬢ビン4
鬣リィェ4
鬤ラン2
鬥ドウ4
鬦ドウ4
鬧ナオ4
鬨ホン4
鬩シー4
鬪ドウ4
鬫ハン3
鬬ドウ4
鬭ドウ4
鬮ジゥ1
鬯chang4
鬰ユー4
鬱ユー4
鬲グェ゛ァ2
鬳イェン4
鬴フー3
鬵チン2
鬶グゥェイ1
鬷ゾン1
鬸リィゥ4
鬹グゥェイ1
鬺シャン1
鬻ユー4
鬼グゥェイ3
鬽メイ4
鬾ジー4
鬿チー2
魀チィェ4
魁クゥェイ2
魂フン2
魃バー2
魄ポォ4
魅メイ4
魆シュ1
魇イェン3
魈シァォ1
魉リィァン3
魊ユー4
魋トゥェイ2
魌チー1
魍ワン3
魎リィァン3
魏ウェイ4
魐ガン1
魑チー1
魒ピィァオ1
魓ビー4
魔ムー2
魕ジー3
魖シュ1
魗チョウ3
魘イェン3
魙ヂャン1
魚ユー2
魛ダオ1
魜レン2
魝ジェ2
魞バー1
魟ホン2
魠トゥォ1
魡ディァォ4
魢ジー3
魣シュ4
魤ェ゛ァ2
魥ェ゛ァ4
魦シャ1
魧ハン2
魨トゥン2
魩ムー4
魪ジェ4
魫シェン3
魬バン3
魭ユェン2
魮ピー2
魯ルー3
魰ウェン2
魱フー2
魲ルー2
魳ザー1
魴ファン2
魵フェン2
魶ナー4
魷ヨウ2
魸ピィェン4
魹ムー2
魺フェ゛ァ2
魻シァ2
魼チュ1
魽ハン2
魾ピー1
魿リン2
鮀トゥォ2
鮁ブォ1
鮂チィゥ2
鮃ピン2
鮄フー2
鮅ビー4
鮆ツー3
鮇ウェイ4
鮈ジュ1
鮉ディァォ1
鮊バー4
鮋ヨウ2
鮌ガン3
鮍ピー1
鮎ニィェン2
鮏シン1
鮐タイ2
鮑バオ4
鮒フー4
鮓ヂャ3
鮔ジュ4
鮕グー1
鮖シー2
鮗ドン1
鮘ダイ
鮙ター4
鮚ジェ2
鮛シュ1
鮜ホウ4
鮝シィァン3
鮞ェ゛ァー2
鮟アン4
鮠ウェイ2
鮡ヂャオ4
鮢ヂュ1
鮣イン4
鮤リィェ4
鮥ルゥォ4
鮦トン2
鮧ティ3
鮨イー4
鮩ビン4
鮪ウェイ3
鮫ジャオ1
鮬クー1
鮭グゥェイ1
鮮シィェン1
鮯グェ゛ァ2
鮰フゥェイ2
鮱ラオ3
鮲フー2
鮳カオ4
鮴シゥ1
鮵ドゥォ2
鮶ジュン1
鮷ティ2
鮸ミィェン3
鮹シャオ1
鮺ヂャ3
鮻スォ1
鮼チン1
鮽ユー2
鮾ネイ3
鮿ヂェ゛ァ2
鯀ガン3
鯁ゴン3
鯂スー1
鯃ウー2
鯄チィゥ2
鯅シャン1
鯆プー1
鯇ファン4
鯈ティァォ2
鯉リー3
鯊シャ1
鯋シャ1
鯌カオ4
鯍モン2
鯎チォン2
鯏リー2
鯐ゾウ3
鯑シー1
鯒ヨン3
鯓シェン1
鯔ズー1
鯕チー2
鯖ヂォン1
鯗シィァン3
鯘ネイ3
鯙チュン2
鯚ジー4
鯛ディァォ1
鯜チィェ4
鯝グー4
鯞ヂョウ3
鯟ドン1
鯠ライ2
鯡フェイ4
鯢ニー2
鯣イー4
鯤クン1
鯥ルー4
鯦ジゥ4
鯧chang1
鯨ジン1
鯩ルゥン2
鯪リン2
鯫ゾウ1
鯬リー2
鯭モン3
鯮ゾン1
鯯ヂー4
鯰ニィェン2
鯱フー3
鯲ユー2
鯳デ3
鯴シー1
鯵シェン1
鯶ファン4
鯷ティ2
鯸ホウ2
鯹シン1
鯺ヂュ1
鯻ラー4
鯼ゾン1
鯽ゼイ2
鯾ビィェン1
鯿ビィェン1
鰀ファン4
鰁チュェン2
鰂ゼイ2
鰃ウェイ1
鰄ウェイ1
鰅ユー2
鰆チュン1
鰇ロウ2
鰈ディェ2
鰉フゥァン2
鰊リィェン4
鰋イェン3
鰌チィゥ1
鰍チィゥ1
鰎ジィェン3
鰏ビー1
鰐ェ゛ァ4
鰑ヤン2
鰒フー4
鰓サイ1
鰔ガン3
鰕シァ1
鰖トゥォ3
鰗フー2
鰘シー4
鰙ルォ4
鰚シュェン1
鰛ウェン1
鰜チィェン4
鰝ハオ4
鰞ウー1
鰟ファン2
鰠サオ1
鰡リィゥ2
鰢マー3
鰣シー2
鰤シー1
鰥グァン1
鰦ズー1
鰧トン2
鰨ター3
鰩イャォ2
鰪ェ゛ァ2
鰫ヨン2
鰬チィェン2
鰭チー2
鰮ウェン1
鰯ルォ4
鰰シェン2
鰱リィェン2
鰲アオ2
鰳ラ4
鰴フゥェイ1
鰵ミン3
鰶ジー4
鰷ティァォ2
鰸チュ1
鰹ジィェン1
鰺シェン1
鰻マン2
鰼シー2
鰽チィゥ2
鰾ビィャォ4
鰿ジー4
鱀ジー4
鱁ヂュ2
鱂ジィァン1
鱃シゥ1
鱄ヂュァン1
鱅ヨン1
鱆ヂャン1
鱇カン1
鱈シュェ3
鱉ビィェ1
鱊ユー4
鱋チュ1
鱌シィァン4
鱍ブォ1
鱎ジャオ3
鱏シュン2
鱐スー4
鱑フゥァン2
鱒ズン1
鱓シャン4
鱔シャン4
鱕ファン1
鱖グゥェイ4
鱗リン2
鱘シュン2
鱙ミィァォ2
鱚シー3
鱛ゾン1
鱜シィァン1
鱝フェン4
鱞グァン1
鱟ホウ4
鱠クァィ4
鱡ゼイ2
鱢サオ1
鱣ヂャン1
鱤ガン3
鱥グゥェイ4
鱦イン4
鱧リー3
鱨chang2
鱩レイ2
鱪シュ3
鱫ェ゛ァ4
鱬ルー2
鱭ジー4
鱮シュ4
鱯フー4
鱰シュ3
鱱リー4
鱲リィェ4
鱳リー4
鱴ミィェ4
鱵ヂェン1
鱶シィァン3
鱷ェ゛ァ4
鱸ルー2
鱹グァン4
鱺リー2
鱻シィェン1
鱼ユー2
鱽ダオ1
鱾ジー3
鱿ヨウ2
鲀トゥン2
鲁ルー3
鲂ファン2
鲃バー1
鲄フェ゛ァ2
鲅バー4
鲆ピン2
鲇ニィェン2
鲈ルー2
鲉ヨウ2
鲊ヂャ3
鲋フー4
鲌バー4
鲍バオ4
鲎ホウ4
鲏ピー2
鲐タイ2
鲑グゥェイ1
鲒ジェ2
鲓カオ4
鲔ウェイ3
鲕ェ゛ァー2
鲖トン2
鲗ゼイ2
鲘ホウ4
鲙クァィ4
鲚ジー4
鲛ジャオ1
鲜シィェン1
鲝ヂャ3
鲞シィァン3
鲟シュン2
鲠ゴン3
鲡リー2
鲢リィェン2
鲣ジィェン1
鲤リー3
鲥シー2
鲦ティァォ2
鲧ガン3
鲨シャ1
鲩ファン4
鲪ジュン1
鲫ジー4
鲬ヨン3
鲭チン1
鲮リン2
鲯チー2
鲰ゾウ1
鲱フェイ1
鲲クン1
鲳chang1
鲴グー4
鲵ニー2
鲶ニィェン2
鲷ディァォ1
鲸ジン1
鲹シェン1
鲺シー1
鲻ズー1
鲼フェン4
鲽ディェ2
鲾ビー1
鲿chang2
鳀ティ2
鳁ウェン1
鳂ウェイ1
鳃サイ1
鳄ェ゛ァ4
鳅チィゥ1
鳆フー4
鳇フゥァン2
鳈チュェン2
鳉ジィァン1
鳊ビィェン1
鳋サオ1
鳌アオ2
鳍チー2
鳎ター3
鳏グァン1
鳐イャォ2
鳑パン2
鳒ジィェン1
鳓ラ4
鳔ビィャォ4
鳕シュェ3
鳖ビィェ1
鳗マン2
鳘ミン3
鳙ヨン1
鳚ウェイ4
鳛シー2
鳜グゥェイ4
鳝シャン4
鳞リン2
鳟ズン1
鳠フー4
鳡ガン3
鳢リー3
鳣ヂャン1
鳤グァン3
鳥ニィァォ3
鳦イー3
鳧フー2
鳨リー4
鳩ジゥ1
鳪ブー2
鳫イェン4
鳬フー3
鳭ディァォ1
鳮ジー1
鳯フォン4
鳰ルー4
鳱ガン1
鳲シー1
鳳フォン4
鳴ミン2
鳵バオ3
鳶ユェン1
鳷ヂー1
鳸フー4
鳹チン2
鳺フー1
鳻バン1
鳼ウェン2
鳽ジィェン1
鳾シー1
鳿ユー4
鴀フォウ3
鴁イャォ1
鴂ジュェ2
鴃ジュェ2
鴄ピー3
鴅ファン1
鴆ヂェン4
鴇バオ3
鴈イェン4
鴉ヤー1
鴊ヂォン4
鴋ファン1
鴌フォン4
鴍ウェン2
鴎オウ1
鴏ダイ4
鴐グェ゛ァ1
鴑ルー2
鴒リン2
鴓ミィェ4
鴔フー2
鴕トゥォ2
鴖ミン2
鴗リー4
鴘ビィェン3
鴙ヂー4
鴚グェ゛ァ1
鴛ユェン1
鴜ツー2
鴝チュ2
鴞シァォ1
鴟チー1
鴠ダン4
鴡ジュ1
鴢イャォ3
鴣グー1
鴤ヂョン1
鴥ユー4
鴦ヤン1
鴧ユー4
鴨ヤー1
鴩ティェ3
鴪ユー4
鴫ティェン2
鴬イン1
鴭ドゥェイ1
鴮ウー1
鴯ェ゛ァー2
鴰グァ1
鴱ェ゛ァ4
鴲ヂー1
鴳イェン4
鴴ホン2
鴵シァォ1
鴶ジャ2
鴷リィェ4
鴸ヂュ1
鴹ヤン2
鴺ティ2
鴻ホン2
鴼ルゥォ4
鴽ルー2
鴾モウ2
鴿グェ゛ァ1
鵀レン2
鵁ジャオ1
鵂シゥ1
鵃ヂョウ1
鵄チー1
鵅ルゥォ4
鵆ホン2
鵇ニィェン2
鵈ェ゛ァ3
鵉ルゥァン2
鵊ジャ2
鵋ジー4
鵌トゥ2
鵍ファン1
鵎トゥォ3
鵏ブー3
鵐ウー2
鵑ジュェン1
鵒ユー4
鵓ブォ2
鵔ジュン4
鵕ジュン4
鵖ビー1
鵗シー1
鵘ジュン4
鵙ジュ2
鵚トゥ1
鵛ジン1
鵜ティ2
鵝ェ゛ァ2
鵞ェ゛ァ2
鵟クゥァン2
鵠フー2
鵡ウー3
鵢シェン1
鵣ライ4
鵤ジャオ
鵥パン4
鵦ルー4
鵧ピー2
鵨シュ1
鵩フー2
鵪アン1
鵫ヂュオ2
鵬ポン2
鵭チン2
鵮チィェン1
鵯ベイ1
鵰ディァォ1
鵱ルー4
鵲チュェ4
鵳ジィェン1
鵴ジュ2
鵵トゥ4
鵶ヤー1
鵷ユェン1
鵸チー2
鵹リー2
鵺イェ4
鵻ヂュェイ1
鵼コン1
鵽ドゥォ4
鵾クン1
鵿シォン1
鶀チー2
鶁ジン1
鶂イー4
鶃イー4
鶄ジン1
鶅ズー1
鶆ライ2
鶇ドン1
鶈チー1
鶉チュン2
鶊ゴン1
鶋ジュ1
鶌ジュェ2
鶍イー4
鶎ズン1
鶏ジー1
鶐シュ4
鶑イン1
鶒チー4
鶓ミィァォ2
鶔ロウ2
鶕アン1
鶖チィゥ1
鶗ティ2
鶘フー2
鶙ティ2
鶚ェ゛ァ4
鶛ジェ1
鶜マオ2
鶝フー2
鶞チュン1
鶟トゥ2
鶠イェン3
鶡フェ゛ァ2
鶢ユェン2
鶣ピィェン1
鶤クン1
鶥メイ2
鶦フー2
鶧イン1
鶨チュァン4
鶩ウー4
鶪ジュ2
鶫ドン1
鶬ツァン1
鶭ファン3
鶮フェ゛ァ4
鶯イン1
鶰ユェン2
鶱シィェン1
鶲ウォン1
鶳シー1
鶴フェ゛ァ4
鶵チュ2
鶶タン2
鶷シァ2
鶸ルォ4
鶹リィゥ2
鶺ジー2
鶻グー2
鶼ジィェン1
鶽スン3
鶾ハン4
鶿ツー2
鷀ツー2
鷁イー4
鷂イャォ4
鷃イェン4
鷄ジー1
鷅リー4
鷆ティェン2
鷇コウ4
鷈ティ1
鷉ティ1
鷊イー4
鷋トゥ2
鷌マー3
鷍シァォ1
鷎ガオ1
鷏ティェン2
鷐チェン2
鷑ジー2
鷒トゥァン2
鷓ヂェ゛ァ4
鷔アオ2
鷕イャォ3
鷖イー1
鷗オウ1
鷘チー4
鷙ヂー4
鷚リィゥ4
鷛ヨン1
鷜リュ2
鷝ビー4
鷞シュゥァン1
鷟ヂュオ2
鷠ユー2
鷡ウー2
鷢ジュェ2
鷣イン2
鷤ティ2
鷥スー1
鷦ジャオ1
鷧イー4
鷨ファ2
鷩ビー4
鷪イン1
鷫スー4
鷬フゥァン2
鷭ファン2
鷮ジャオ1
鷯リィァォ2
鷰イェン4
鷱ガオ1
鷲ジゥ4
鷳シィェン2
鷴シィェン2
鷵トゥ2
鷶マイ3
鷷ズン1
鷸ユー4
鷹イン1
鷺ルー4
鷻トゥァン2
鷼シィェン2
鷽シュェ2
鷾イー4
鷿ピー4
鸀チュ3
鸁ルゥォ2
鸂シー1
鸃イー2
鸄ジー1
鸅ゼェ゛ァ2
鸆ユー2
鸇ヂャン1
鸈イェ4
鸉ヤン2
鸊ピー4
鸋ニン2
鸌フー4
鸍ミィ2
鸎イン1
鸏モン2
鸐デ2
鸑ユェ4
鸒ユー4
鸓レイ3
鸔ブー3
鸕ルー2
鸖フェ゛ァ4
鸗ロン2
鸘シュゥァン1
鸙ユェ4
鸚イン1
鸛グァン4
鸜チュ2
鸝リー2
鸞ルゥァン2
鸟ニィァォ3
鸠ジゥ1
鸡ジー1
鸢ユェン1
鸣ミン2
鸤シー1
鸥オウ1
鸦ヤー1
鸧ツァン1
鸨バオ3
鸩ヂェン4
鸪グー1
鸫ドン1
鸬ルー2
鸭ヤー1
鸮シァォ1
鸯ヤン1
鸰リン2
鸱チー1
鸲チュ2
鸳ユェン1
鸴シュェ2
鸵トゥォ2
鸶スー1
鸷ヂー4
鸸ェ゛ァー2
鸹グァ1
鸺シゥ1
鸻ホン2
鸼ヂョウ1
鸽グェ゛ァ1
鸾ルゥァン2
鸿ホン2
鹀ウー2
鹁ブォ2
鹂リー2
鹃ジュェン1
鹄グー3
鹅ェ゛ァ2
鹆ユー4
鹇シィェン2
鹈ティ2
鹉ウー3
鹊チュェ4
鹋ミィァォ2
鹌アン1
鹍クン1
鹎ベイ1
鹏ポン2
鹐チィェン1
鹑チュン2
鹒ゴン1
鹓ユェン1
鹔スー4
鹕フー2
鹖フェ゛ァ2
鹗ェ゛ァ4
鹘グー3
鹙チィゥ1
鹚ツー2
鹛メイ2
鹜ウー4
鹝イー4
鹞イャォ4
鹟ウォン1
鹠リィゥ2
鹡ジー2
鹢イー4
鹣ジィェン1
鹤フェ゛ァ4
鹥イー1
鹦イン1
鹧ヂェ゛ァ4
鹨リィゥ4
鹩リィァォ2
鹪ジャオ1
鹫ジゥ4
鹬ユー4
鹭ルー4
鹮ファン2
鹯ヂャン1
鹰イン1
鹱フー4
鹲モン2
鹳グァン4
鹴シュゥァン1
鹵ルー3
鹶ジン1
鹷リン2
鹸ジィェン3
鹹シィェン2
鹺ツゥォ2
鹻ジィェン3
鹼ジィェン3
鹽イェン2
鹾ツゥォ2
鹿ルー4
麀ヨウ1
麁ツゥ1
麂ジー3
麃パオ2
麄ツゥ1
麅パオ2
麆ヂュ4
麇ジュン1
麈ヂュ3
麉ジィェン1
麊ミィ2
麋ミィ2
麌ユー3
麍リィゥ2
麎チェン2
麏ジュン1
麐リン2
麑ニー2
麒チー2
麓ルー4
麔ジゥ4
麕ジュン1
麖ジン1
麗リー4
麘シィァン1
麙シィェン2
麚ジャ1
麛ミィ2
麜リー4
麝シェ゛ァ4
麞ヂャン1
麟リン2
麠ジン1
麡チー2
麢リン2
麣イェン2
麤ツゥ1
麥マイ4
麦マイ4
麧フェ゛ァ2
麨チャオ3
麩フー1
麪ミィェン4
麫ミィェン4
麬フー1
麭パオ4
麮チュ4
麯チュ1
麰モウ2
麱フー1
麲シィェン4
麳ライ2
麴チュ1
麵ミィェン4
麶チー
麷フォン1
麸フー1
麹チュ1
麺ミィェン4
麻マー2
麼ムェ゛ァ
麽ムー2
麾フゥェイ1
麿ミィ2
黀ゾウ1
黁nun2
黂フェン2
黃フゥァン2
黄フゥァン2
黅ジン1
黆グゥァン1
黇ティェン1
黈トウ3
黉ホン2
黊ファ4
黋クゥァン4
黌ホン2
黍シュ3
黎リー2
黏ニィェン2
黐チー1
黑ヘイ1
黒ヘイ1
黓イー4
黔チィェン2
黕ダン3
黖シー4
黗トゥン1
默ムー4
黙ムー4
黚チィェン2
黛ダイ4
黜チュ4
黝ヨウ3
點ディェン3
黟イー1
黠シァ2
黡イェン3
黢チュ1
黣メイ3
黤イェン3
黥チン2
黦ユェ4
黧リー2
黨ダン3
黩ドゥ2
黪ツァン3
黫イェン1
黬イェン2
黭イェン3
黮ダン3
黯アン4
黰ヂェン3
黱ダイ4
黲ツァン3
黳イー1
黴メイ2
黵ヂャン3
黶イェン3
黷ドゥ2
黸ルー2
黹ヂー3
黺フェン3
黻フー2
黼フー3
黽ミィェン3
黾ミン3
黿ユェン2
鼀ツゥ4
鼁チュ4
鼂チャオ2
鼃ウァ1
鼄ヂュ1
鼅ヂー1
鼆モン2
鼇アオ2
鼈ビィェ1
鼉トゥォ2
鼊ビー4
鼋ユェン2
鼌チャオ2
鼍トゥォ2
鼎ディン3
鼏ミィ4
鼐ナイ4
鼑ディン3
鼒ズー1
鼓グー3
鼔グー3
鼕ドン1
鼖フェン2
鼗タオ2
鼘ユェン1
鼙ピー2
鼚chang1
鼛ガオ1
鼜チー4
鼝ユェン1
鼞タン1
鼟トン1
鼠シュ3
鼡シュ3
鼢フェン2
鼣フェイ4
鼤ウェン2
鼥バー2
鼦ディァォ1
鼧トゥォ2
鼨ヂョン1
鼩チュ2
鼪シォン1
鼫シー2
鼬ヨウ4
鼭シー2
鼮ティン2
鼯ウー2
鼰ジュ2
鼱ジン1
鼲フン2
鼳ジュ2
鼴イェン3
鼵トゥ1
鼶スー1
鼷シー1
鼸シィェン4
鼹イェン3
鼺レイ2
鼻ビー2
鼼イャォ4
鼽チィゥ2
鼾ハン1
鼿ウー4
齀ウー4
齁ホウ1
齂シェ4
齃ェ゛ァ4
齄ヂャ1
齅シゥ4
齆ウォン4
齇ヂャ1
齈ノン4
齉ナン4
齊チー2
齋ヂャイ1
齌ジー4
齍ズー1
齎ジー1
齏ジー1
齐チー2
齑ジー1
齒チー3
齓チェン4
齔チェン4
齕フェ゛ァ2
齖ヤー2
齗イン2
齘シェ4
齙バオ1
齚ゼェ゛ァ2
齛シェ4
齜チャイ2
齝チー1
齞イェン3
齟ジュ3
齠ティァォ2
齡リン2
齢リン2
齣チュ1
齤チュェン2
齥シェ4
齦ケン3
齧ニィェ4
齨ジゥ4
齩イャォ3
齪チュォ4
齫ユン3
齬ユー3
齭チュ3
齮イー3
齯ニー2
齰ゼェ゛ァ2
齱ゾウ1
齲チュ3
齳ユン3
齴イェン3
齵オウ2
齶ェ゛ァ4
齷ウォ4
齸イー4
齹ツー1
齺ゾウ1
齻ディェン1
齼チュ3
齽ジン4
齾ヤー4
齿チー3
龀チェン4
龁フェ゛ァ2
龂イン2
龃ジュ3
龄リン2
龅バオ1
龆ティァォ2
龇ズー1
龈ケン3
龉ユー3
龊チュォ4
龋チュ3
龌ウォ4
龍ロン2
龎パン2
龏ゴン1
龐パン2
龑イェン3
龒ロン2
龓ロン3
龔ゴン1
龕カン1
龖ダー2
龗リン2
龘ダー2
龙ロン2
龚ゴン1
龛カン1
龜グゥェイ1
龝チィゥ1
龞ビィェ1
龟グゥェイ1
龠ユェ4
龡チュェイ1
龢フェ゛ァ2
龣ジュェ2
龤シェ2
龥ユー4
鿃シャン3
鿍ガン4
鿎ター3
鿏マイ4
鿔グェ゛ァ1
鿕ダン1
鿫アオ4
鿬ティェン2
鿭ニー3
`
